/* eslint-disable no-console */
/** @jsxImportSource @emotion/react */

import useIndex from '@lib/query/setupAccountBank/useIndex'
import { Fragment, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import PageTitle from '@components/PageTitle'
import Layout from '@layout/Main'
import DataTable from '@components/DataTable'
import Card from '@components/card/Base'
import { css } from '@emotion/react'
import Tambah from '@components/button/Tambah'

import useCreate from '@lib/query/setupAccountBank/useCreate'
import useUpdate from '@lib/query/setupAccountBank/useUpdate'

import action from './action'
import Form from './Form'

const titleCSS = css`
  display: flex;
`

const addButtonCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > *:first-of-type {
    margin-left: 10px;
  }
`

function SetupBankAccount() {
  const create = useCreate()
  const update = useUpdate()

  const result = useIndex()

  const onTableChange = () => null

  const {
    tableData,
    tableColumns,
    hiddenColumns,
    tablePagination,
  } = result

  return (
    <Fragment>
      <Helmet>
        <title>
          SQUIDS :: Setup Akun Bank
        </title>
      </Helmet>
      <PageTitle>
        Setup Akun Bank
      </PageTitle>
      <Layout>
        <Card>
          <div css={titleCSS}>
            <h3>Setup Akun Bank</h3>
            <div css={addButtonCSS}>
              <Tambah type="create" />
            </div>
          </div>
          <DataTable
            data={tableData}
            columns={useMemo(() => tableColumns, [tableColumns])}
            hooks={action}
            hiddenColumns={hiddenColumns}
            pagination={tablePagination}
            onChange={onTableChange}
          />
        </Card>
      </Layout>
      <Form
        title="Tambah Akun Bank"
        type="create"
        defaultValues={{
          personal_token: '',
          name: '',
          bank_name: '',
          account_number: '',
        }}
        onSave={create}
      />
      <Form
        title="Ubah Akun Bank"
        type="edit"
        defaultValues={{
          personal_token: '',
          name: '',
          bank_name: '',
          account_number: '',
        }}
        onSave={update}
      />
    </Fragment>
  )
}

export default SetupBankAccount
