import { useMutation, useQueryClient } from 'react-query'
import { deleteSyncPages } from '@lib/axios/syncPages'

const useDelete = () => {
  const queryClient = useQueryClient()
  return useMutation((id) => deleteSyncPages(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('saas-sync-pages')
    },
  })
}

export default useDelete
