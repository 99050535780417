/** @jsxImportSource @emotion/react */
/* eslint-disable no-unused-vars */

import { css } from '@emotion/react'
import useQueryString from '@hooks/useQueryString'
import { useState } from 'react'
import Terapkan from '@components/button/Terapkan'
import Reset from '@components/button/Reset'

import PageSize from '@components/filter/PageSize'
import Text from '@components/filter/Text'
import Status from './Status'

const tableToolCSS = css`
  display: flex;
  margin-bottom: 14px;
  margin-right: 30px;
  margin-left: 30px;
  button, input {
    margin-right: 5px;
  }
  & > div {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
`

function Tools() {
  const {
    clearable, setQueries, clearQueries,
  } = useQueryString()
  const [state, setState] = useState(clearable)

  function applyFilter() {
    setQueries(state)
  }

  return (
    <div css={tableToolCSS}>
      <div>
        <PageSize />
      </div>
      <div style={{ justifyContent: 'end' }}>
        <Text
          placeholder="Nama Toko"
          onChange={(name) => setState({ ...state, store_name: name || undefined })}
          defaultValue={state.store_name}
        />
        <Text
          placeholder="Domain"
          onChange={(name) => setState({ ...state, store_domain: name || undefined })}
          defaultValue={state.store_domain}
        />
        <Text
          placeholder="Kode Voucher"
          onChange={(name) => setState({ ...state, voucher_code: name || undefined })}
          defaultValue={state.voucher_code}
        />
        <Status
          onSelected={(status) => setState({ ...state, invoice_status: status || undefined })}
          defaultValue={state.invoice_status}
        />
        <Terapkan onClick={applyFilter} />
        <Reset onClick={() => {
          clearQueries()
          setState({})
        }}
        />
      </div>
    </div>
  )
}

export default Tools
