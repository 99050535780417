/* eslint-disable react/prop-types */
import React from 'react'
import InputBase from '@material-ui/core/InputBase'
import { makeStyles } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

export const useStyles = makeStyles({
  formControl: {
    paddingTop: 20,
  },
  inputBase: {
    border: '1px solid rgba(0,0,0,.01)',
    backgroundColor: '#F6F9FB',
    borderRadius: '5px',
    padding: 0,
    width: '100%',
    '& textarea': {
      borderRadius: 5,
      padding: 10,
      height: '100%',
      width: '100%',
      minHeight: 150,
      fontSize: 13,
      lineHeight: '22px',
      overflowY: 'auto !important',
      maxHeight: 150,
      '&::-webkit-scrollbar': {
        width: 6,
        height: 6,
        backgroundColor: 'rgba(0, 0, 0, .1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0, 0, 0, .2)',
      },
      '*': {
        scrollbarColor: 'rgba(0, 0, 0, .2) rgba(0, 0, 0, .1)',
        scrollbarWidth: 'thin',
      },
    },
  },
})

function TextArea(props) {
  const {
    label, onChange: handleChange, value,
  } = props
  const styles = useStyles()
  return (
    <FormControl fullWidth className={styles.formControl}>
      {label && (
      <InputLabel shrink>
        {label}
      </InputLabel>
      )}
      <InputBase
        className={styles.inputBase}
        multiline
        fullWidth
        spellCheck={false}
        autoComplete="off"
        value={value}
        onChange={(e) => handleChange(e.target.value)}
      />
    </FormControl>
  )
}

export default TextArea
