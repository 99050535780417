/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import { Fragment, useMemo } from 'react'
import { css } from '@emotion/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { askChangePasswordSaasAdmins } from '@lib/axios/admin'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import { schema, rules } from '@lib/validation'
import Button from './common/Button'
import Background from './common/Background'
import Box from './common/Box'
import Logo from './common/Logo'
import Email from './common/ForgotPasswordEmail'

const logoCSS = css`
  position: absolute;
  top: 57px;
  left: 270px;
`

const titleCSS = css`
  position: absolute;
  top: 200px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 170%;
  margin: 0;
  padding: 0;
`

const subTitleCSS = css`
  position: absolute;

  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  top: 251px;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 196.4%;

  margin: 0;
  padding: 0;
`

const sendButtonCSS = css`
  position: absolute;
  width: 123px;
  height: 45px;
  left: 472px;
  top: 305px;
`

const titleText = 'Lupa Kata Sandi?'
const subTitleText = 'Untuk mengatur ulang kata sandi, silahkan masukkan alamat email Anda.'
const sendButtonText = 'Kirim'

function ForgotPassword() {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const {
    register, errors, handleSubmit, formState,
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver((schema({
      email: rules.email,
    }))),
  })

  const touchedEmail = useMemo(() => {
    const { touched } = formState
    return touched.email || false
  }, [formState])

  // eslint-disable-next-line no-console
  const sendEmail = ({ email }) => askChangePasswordSaasAdmins(email)
    .then((response) => {
      if (response?.data?.success) {
        history.push('/link-sent')
      } else {
        enqueueSnackbar('Email Tidak Terdaftar', { variant: 'error' })
      }
    })
    .catch((e) => {
      enqueueSnackbar('Terjadi Kesalahan', { variant: 'error' })
    })

  return (
    <Fragment>
      <Background>
        <Box width={675} height={412}>
          <Logo css={logoCSS} />
          <p css={titleCSS}>
            { titleText }
          </p>
          <p css={subTitleCSS}>
            { subTitleText }
          </p>
          <form onSubmit={handleSubmit(sendEmail)}>
            <Email
              ref={register}
              name="email"
              error={errors?.email?.message}
              placeholder="email"
              touched={touchedEmail}
            />
            <Button css={sendButtonCSS}>
              { sendButtonText }
            </Button>
          </form>
        </Box>
      </Background>
    </Fragment>
  )
}

export default ForgotPassword
