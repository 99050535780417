import { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import PageTitle from '@components/PageTitle'
import Layout from '@layout/Account'

import Form from './Form'

function AccountPassword() {
  return (
    <Fragment>
      <Helmet>
        <title>
          SQUIDS :: Account - Change Password
        </title>
      </Helmet>
      <PageTitle>
        Akun Saya &gt; Ubah Kata Sandi
      </PageTitle>
      <Layout>
        <Form />
      </Layout>
    </Fragment>
  )
}

export default AccountPassword
