import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: false,
    accessToken: '',
    userData: null,
  },
  reducers: {
    login: ({ ...state }, { payload }) => ({
      ...state,
      isLoggedIn: true,
      accessToken: payload.accessToken,
      userData: payload.userData,
    }),
    logout: ({ ...state }) => ({
      ...state,
      isLoggedIn: false,
      accessToken: '',
      userData: null,
    }),
  },
})

export const {
  login,
  logout,
} = authSlice.actions

export default authSlice.reducer
