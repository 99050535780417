/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

import {
  Button,
  makeStyles, MenuItem, Select, Toolbar, Typography,
} from '@material-ui/core'
import React, { useContext, useState } from 'react'
import TuneIcon from '@material-ui/icons/Tune'
import Terapkan from '@components/button/Terapkan'
import Reset from '@components/button/Reset'
import Text from '@components/filter/Text'
import { KeyboardDatePicker } from '@material-ui/pickers'
import dayjs from 'dayjs'
import qs from 'qs'
import { useHistory, useLocation } from 'react-router'
import FilterDialog from './FilterDialog'
import { TableContext } from './TableProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  showPageWrapper: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  selectPage: {
    marginLeft: theme.spacing(1),
    width: 100,
    height: 40,
    fontSize: 12,
    backgroundColor: '#F6F9FB',
    outline: 'none',
    '&.MuiSelect-outlined.MuiSelect-outlined': {
      border: 'none',
      outline: 'none',
    },
  },
  btn: {
    marginLeft: 5,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  textField: {
    height: 30,
    fontSize: 13,
    backgroundColor: '#F6F9FB',
    border: 'none',
    borderRadius: 5,
    width: '100%',
    color: '#676767',
  },
}))

const TableToolbar = () => {
  const classes = useStyles()
  const pages = [10, 20, 50]
  const {
    perPage, setPerPage, queryPush, queryRemove,
  } = useContext(TableContext)
  const [openFilter, setOpenFilter] = useState(false)

  // filter
  const [name, setName] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const location = useLocation()
  const history = useHistory()

  const onPageSelect = (e) => {
    const { value } = e.target
    const queryString = qs.parse(location.search, { ignoreQueryPrefix: true })
    const newQs = { ...queryString, per_page: value, page: 1 }
    setPerPage(value)

    history.push(`${location.pathname}?${qs.stringify(newQs)}`)
  }

  const executeFilter = () => {
    const filterField = {}
    if (name) filterField.affiliate_name = name
    if (startDate) filterField.start_date = startDate
    if (endDate) filterField.end_date = endDate
    queryPush(filterField)
  }

  const resetFilter = () => {
    setName('')
    setStartDate(null)
    setEndDate(null)
    queryRemove(['affiliate_name', 'start_date', 'end_date'])
  }

  return (
    <Toolbar className={classes.root}>
      <div className={classes.showPageWrapper}>
        <Typography>Tampilkan : </Typography>
        <Select
          name="perPage"
          variant="outlined"
          value={perPage}
          onChange={onPageSelect}
          className={classes.selectPage}
        >
          {
            pages.map((page) => (
              <MenuItem
                value={page}
                key={page}
              >
                {page}
              </MenuItem>
            ))
          }
        </Select>
      </div>
      <div className={classes.btn}>
        <KeyboardDatePicker
          autoOk
          clearable
          value={startDate}
          onChange={(date) => setStartDate(date)}
          maxDate={endDate || new Date()}
          format="MM/dd/yyyy"
          className={classes.textField}
          InputProps={{
            classes: { root: classes.textField },
          }}
          variant="inline"
        />
      </div>
      <div className={classes.btn}>
        <KeyboardDatePicker
          autoOk
          clearable
          value={endDate}
          onChange={(date) => setEndDate(date)}
          minDate={startDate || new Date()}
          format="MM/dd/yyyy"
          className={classes.textField}
          InputProps={{
            classes: { root: classes.textField },
          }}
          variant="inline"
        />
      </div>
      <div className={classes.btn}>
        <Text
          placeholder="Nama"
          onChange={(e) => setName(e)}
          defaultValue={name}
        />
      </div>
      <div className={classes.btn}>
        <Terapkan onClick={executeFilter} />
      </div>
      <div className={classes.btn}>
        <Reset onClick={resetFilter} />
      </div>
      <FilterDialog
        open={openFilter}
        onClose={() => setOpenFilter(false)}
      />
    </Toolbar>
  )
}
export default TableToolbar
