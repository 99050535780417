/** @jsxImportSource @emotion/react */

import useIndex from '@lib/query/user/useIndex'
import { Fragment, useMemo } from 'react'
import { css } from '@emotion/react'
import DataTable from '@components/DataTable'
import PageTitle from '@components/PageTitle'
import { Helmet } from 'react-helmet'
import Layout from '@layout/Main'
import Card from '@components/card/Base'
import Toolbar from './Toolbar'

import action from './action'
import Detail from './Detail'

const CSS = css`
   thead tr > *:last-of-type {
    width: 200px;
    div {
      text-align: center;
    }
  }
  & tbody tr > *:last-of-type div {
    display: flex;
    justify-content: space-around;
  }
`

const titleCSS = css`
  display: flex;
`
function Admin() {
  const {
    tableData,
    tableColumns,
    hiddenColumns,
    tablePagination,
  } = useIndex()

  function setParams() {
    //
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          SQUIDS :: MANAJEMEN USER
        </title>
      </Helmet>
      <PageTitle>
        Manajemen User
      </PageTitle>
      <Layout>
        <Card>
          <div css={titleCSS}>
            <h3>Manajemen User</h3>
          </div>
          <Toolbar />
          <DataTable
            css={CSS}
            data={tableData}
            columns={useMemo(() => tableColumns, [tableColumns])}
            hooks={action}
            hiddenColumns={hiddenColumns}
            pagination={tablePagination}
            onChange={setParams}
          />
        </Card>
      </Layout>
      <Detail />
    </Fragment>
  )
}

export default Admin
