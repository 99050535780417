/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useLocation, useHistory } from 'react-router-dom'
import { changePasswordSaasAdmins } from '@lib/axios/admin'
import { useSnackbar } from 'notistack'

import { schema, rules } from '@lib/validation'
import Background from './common/Background'
import Box from './common/Box'
import Password from './common/Password'
import Logo from './common/Logo'
import Button from './common/Button'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const logoCSS = css`
  position: absolute;
  top: 56px;
  left: 269px;
`

const titleCSS = css`
  position: absolute;
  top: 175px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 170%;
`

const subTitleCSS = css`
  position: absolute;

  width: 473px;

  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;

  top: 237px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 196.4%;
  color: #1A253B;
`

const passwordCSS = css`
  left: 147px;
  top: 328px;
  position: absolute;
`

const passwordConfirmCSS = css`
  left: 147px;
  top: 397px;
  position: absolute;
`

const buttonCSS = css`
  width: 380px;
  height: 45px;
  position: absolute;
  top: 465px;
  left: 147px;
`

const titleText = 'Buat kata sandi baru'
const subTitleText = 'Buat kata sandi baru minimal 8 karakter '
                      + 'yang terdiri dari kombinasi huruf besar,'
                      + 'huruf kecil, angka, dan simbol.'
const buttonText = 'Kirim'

function ResetPassword() {
  const history = useHistory()
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver((schema({
      password: rules.password,
      passwordConfirm: rules.passwordConfirmation,
    }))),
  })

  const query = useQuery()
  const { enqueueSnackbar } = useSnackbar()
  const token = query.get('reset_password_token')

  const sendForm = ({ password, passwordConfirm }) => changePasswordSaasAdmins(token, password, passwordConfirm)
    .then((response) => {
      if (response?.data?.success) {
        history.push('/login')
      } else {
        enqueueSnackbar('User Tidak Ada', { variant: 'error' })
      }
    })

  return (
    <Background>
      <Box width={675} height={578}>
        <Logo css={logoCSS} />
        <p css={titleCSS}>
          { titleText }
        </p>
        <p css={subTitleCSS}>
          { subTitleText }
        </p>
        <form onSubmit={handleSubmit(sendForm)}>
          <Password
            css={passwordCSS}
            name="password"
            ref={register}
            error={errors?.password?.message}
            toggle
          />
          <Password
            css={passwordConfirmCSS}
            name="passwordConfirm"
            ref={register}
            error={errors?.passwordConfirm?.message}
          />
          <Button
            css={buttonCSS}
          >
            { buttonText }
          </Button>
        </form>
      </Box>
    </Background>
  )
}

export default ResetPassword
