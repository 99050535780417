import {
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom'
import Dashboard from '@screens/secured/Dashboard'
import AdminManagement from '@screens/secured/AdminManagement'
import UserManagement from '@screens/secured/UserManagement'
import Integration from '@screens/secured/Integration'
import PackageManagement from '@screens/secured/PackageManagement'
import VoucherManagement from '@screens/secured/VoucherManagement'
import NewVoucherManagement from '@screens/secured/VoucherManagement/New'
import SubscribeManagement from '@screens/secured/SubscribeManagement'
import BillingManagement from '@screens/secured/BillingManagement'
import StoreManagement from '@screens/secured/StoreManagement'
import TicketManagement from '@screens/secured/TicketManagement'
import Account from '@screens/secured/Account'
import AccountPassword from '@screens/secured/AccountPassword'
import Dev from '@components/Dev'
import AffiliateComission from '@screens/secured/AffiliateComission'
import AffiliateWithdraw from '@screens/secured/AffiliateWithdraw'
import ThemeOnlineStore from '@screens/secured/ThemeOnlineStore'
import ManageBank from '@screens/secured/ManageBanks'
import SetupBankAccount from '@screens/secured/SetupBankAccount'
import Notification from '@screens/secured/Notification'
import SetupStoreDashboardPage from '@screens/secured/SetupStoreDashboardPage'
import VoucherUsage from '@screens/secured/VoucherUsage'
import CourierManagement from '@screens/secured/CourierManagement'
import XenplatformAccounts from '@screens/secured/XenplatformAccounts'

function SecuredRoutes() {
  const { path } = useRouteMatch()

  // eslint-disable-next-line global-require
  // require('@hooks/useRenderCount').default('secured routes')

  return (
    <Switch>
      <Route path={`${path}`} exact component={Dashboard} />
      <Route path={`${path}/admin`} component={AdminManagement} />
      <Route path={`${path}/user`} component={UserManagement} />
      <Route path={`${path}/paket`} component={PackageManagement} />
      <Route exact path={`${path}/voucher`} component={VoucherManagement} />
      <Route path={`${path}/voucher/new`} component={NewVoucherManagement} />
      <Route path={`${path}/subscribe`} component={SubscribeManagement} />
      <Route path={`${path}/toko`} component={StoreManagement} />
      <Route path={`${path}/integrasi`} component={Integration} />
      <Route path={`${path}/setup-bank-account`} component={SetupBankAccount} />
      <Route path={`${path}/akun/password`} component={AccountPassword} />
      <Route path={`${path}/akun`} component={Account} />
      <Route path={`${path}/bank`} component={ManageBank} />
      <Route path={`${path}/voucher`} component={VoucherManagement} />
      <Route path={`${path}/vouchèr-usage`} component={VoucherUsage} />
      <Route path={`${path}/tiket`} component={TicketManagement} />
      <Route path={`${path}/billing`} component={BillingManagement} />
      <Route path={`${path}/affiliate-comission`} component={AffiliateComission} />
      <Route path={`${path}/affiliate-withdraw`} component={AffiliateWithdraw} />
      <Route path={`${path}/tema`} component={ThemeOnlineStore} />
      <Route path={`${path}/setup-store-dashboard-page`} component={SetupStoreDashboardPage} />
      <Route path={`${path}/notifikasi`} component={Notification} />
      <Route path={`${path}/dev`} component={() => <Dev redirect={path} />} />
      <Route path={`${path}/courier-management`} component={CourierManagement} />
      <Route path={`${path}/setup-xenplatform`} component={XenplatformAccounts} />
    </Switch>
  )
}

export default SecuredRoutes
