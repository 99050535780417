import { useMutation, useQueryClient } from 'react-query'
import { deleteCourierManagement } from '@lib/axios/courier'

const useDelete = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (id) => deleteCourierManagement(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('courier')
      },
    }
  )
}

export default useDelete
