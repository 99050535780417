/* eslint-disable max-len */
/** @jsxImportSource @emotion/react */

import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Grow from '@material-ui/core/Grow'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import { css } from '@emotion/react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import {
  useEffect, useRef, useState,
  useMemo,
} from 'react'
import dropdown from '@assets/dropdown/dropdown.svg'

const pageSizeButtonCSS = css`
  &.MuiButton-root {
    border-radius: 5px;
    padding: 3px 8px;
    border: 1px solid rgba(0, 0, 0, .02);
    position: relative;
  }
  &.MuiButton-textPrimary {
    background-color: #F6F9FB;
  }
  .MuiButton-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    text-transform: none;
    color: #676767;
    min-width: 150px;
    text-align: left;
    display: inline;
  }
  & img {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
  & img.open {
    transform: translateY(-50%) rotate(180deg);
  }
`

const menuListCSS = css`
  &.MuiList-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
  .MuiListItem-root {
    border: 0 none;
    height: 37px;
    width: 150px;
    box-sizing: border-box;
    margin: 0;
    padding: 8px 11px;
    background: #ffffff;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 170%;
    text-align: left;
    color: #676767;
    z-index: 10;
    position: relative;
  }
`

const poperCSS = css`
  border-radius: 5px;
  & .MuiPaper-rounded {
    border-radius: 5px;
    > ul {
      border-radius: 5px;
      > li:first-of-type {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
      }
      > li:last-of-type {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
  }
`

function Dropdown(props) {
  const {
    items,
    className,
    id,
    onSelected: handleSelect,
    defaultValue,
  } = props

  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const [selected, setSelected] = useState(defaultValue)

  const itemsObject = useMemo(() => items.reduce((prev, [title, value]) => ({
    [value]: title,
    ...prev,
  }), {}), [items])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  useEffect(() => {
    setSelected(defaultValue)
  }, [defaultValue])

  const handleClick = (event, value) => {
    setSelected(value)
    handleSelect(value)
    handleClose(event)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <div className={className} style={{ textAlign: 'center' }}>
      <Button
        css={pageSizeButtonCSS}
        ref={anchorRef}
        aria-controls={open ? id : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        { itemsObject[selected] || itemsObject[items[0][1]] }
        <img className={open ? 'open' : 'close'} src={dropdown} alt="" />
      </Button>
      <Popper css={poperCSS} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList css={menuListCSS} autoFocusItem={open} id={id} onKeyDown={handleListKeyDown}>
                  {items.map(([title, value]) => (
                    <MenuItem key={value} button onClick={(e) => handleClick(e, value)}>
                      { title }
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default Dropdown
