/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import Text from '@components/field/Text'
import ImageUpload from '@components/field/ImageUpload'

import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Select2 from '@components/field/Select2'
import { TableContext } from './TableProvider'
import {
  updateApi, postApi, integrationBanks, banksIluma,
} from '../api'

const Form = ({ open, onClose }) => {
  const {
    bank, showLoading, getIndex, edit,
  } = useContext(TableContext)
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [banks, setBanks] = useState([])
  const [iluma, setIluma] = useState([])

  const getBanks = async () => {
    try {
      const {
        status,
        data: { data },
      } = await integrationBanks({ per_page: 100 })

      if (status === 'ok') {
        setBanks(data)
      } else {
        setBanks()
      }
    } catch (error) {
      setBanks()
    }
  }

  const getIlumas = async () => {
    try {
      const {
        status,
        data: { data },
      } = await banksIluma({ per_page: 100 })

      if (status === 'ok') {
        setIluma(data)
      } else {
        setIluma()
      }
    } catch (error) {
      setIluma()
    }
  }

  useEffect(() => {
    getBanks()
    getIlumas()
  }, [])

  const {
    handleSubmit, register, errors, getValues, setValue, control,
  } = useForm({
    defaultValues: {
      name: '',
      icon: null,
      bank_integration: '',
      iluma_integration: '',
      bank_code: '',
      iluma_code: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required('tidak boleh kosong'),
        icon: yup.mixed().when('isEdit', {
          is: (value) => value === 'false' || value === false,
          then: yup
            .mixed()
            .required('Silahkan pilih icon')
            .test(
              'fileSize',
              'Icon maksmial 2mb',
              (value) => value && value[0].size <= 2000000
            )
            .test(
              'type',
              'Icon hanya berupa jpg/jpeg/png',
              (value) => value && ['image/jpeg', 'image/png'].includes(value[0].type)
            ),
        }),
      })
    ),
  })
  const createData = async (params) => {
    const formData = new FormData()

    formData.append('bank[name]', params?.name)
    params.icon && formData.append('bank[icon]', params?.icon[0])
    if (params?.bank_integration) {
      const findBank = banks.find(
        (item) => item.name === params?.bank_integration
      )
      formData.append(
        'bank[bank_integrations_attributes][0][third_party_service]',
        'Xendit'
      )
      formData.append(
        'bank[bank_integrations_attributes][0][transaction_type]',
        'Disbursement'
      )
      formData.append(
        'bank[bank_integrations_attributes][0][bank_name]',
        params?.bank_integration
      )
      formData.append(
        'bank[bank_integrations_attributes][0][bank_code]',
        findBank?.code
      )
    }
    if (params?.iluma_integration) {
      const findBank = iluma.find(
        (item) => item.name === params?.iluma_integration
      )
      formData.append(
        'bank[bank_integrations_attributes][1][third_party_service]',
        'iluma'
      )
      formData.append(
        'bank[bank_integrations_attributes][1][bank_name]',
        params?.iluma_integration
      )
      formData.append(
        'bank[bank_integrations_attributes][1][bank_code]',
        findBank?.code
      )
    }

    try {
      setLoading(true)
      const { status } = await postApi(formData)

      if (status === 'ok') {
        setLoading(false)
        onClose()
        getIndex()
        enqueueSnackbar('Berhasil menambahkan bank', { variant: 'success' })
      } else {
        setLoading(false)
        getIndex()
        enqueueSnackbar('Gagal menambahkan bank', { variant: 'error' })
      }
    } catch (error) {
      setLoading(false)
      enqueueSnackbar('Gagal menambahkan bank', { variant: 'error' })
    }
  }

  const editApi = async (params) => {
    const formData = new FormData()
    formData.append('bank[name]', params?.name)
    if (params?.bank_integration) {
      const findBank = banks.find(
        (item) => item.name === params?.bank_integration
      )
      const xendit = bank?.bank_integrations.find(
        (item) => item.third_party_service === 'Xendit'
      )
      formData.append(
        'bank[bank_integrations_attributes][0][third_party_service]',
        'Xendit'
      )
      formData.append(
        'bank[bank_integrations_attributes][0][transaction_type]',
        'Disbursement'
      )
      formData.append(
        'bank[bank_integrations_attributes][0][bank_name]',
        params?.bank_integration
      )
      formData.append(
        'bank[bank_integrations_attributes][0][bank_code]',
        findBank?.code
      )
      if (xendit) {
        formData.append('bank[bank_integrations_attributes][0][id]', xendit?.id)
      }
    }
    if (params?.iluma_integration) {
      const findBank = iluma.find(
        (item) => item.name === params?.iluma_integration
      )
      const ilumas = bank?.bank_integrations.find(
        (item) => item.third_party_service === 'iluma'
      )
      formData.append(
        'bank[bank_integrations_attributes][1][third_party_service]',
        'iluma'
      )
      formData.append(
        'bank[bank_integrations_attributes][1][bank_name]',
        params?.iluma_integration
      )
      formData.append(
        'bank[bank_integrations_attributes][1][bank_code]',
        findBank?.code
      )
      if (ilumas) {
        formData.append(
          'bank[bank_integrations_attributes][1][id]',
          ilumas?.id
        )
      }
    }
    params.icon.length > 0 && formData.append('bank[icon]', params?.icon[0])

    try {
      setLoading(true)
      const { status } = await updateApi(bank?.id, formData)

      if (status === 'ok') {
        setLoading(false)
        onClose()
        getIndex()
        enqueueSnackbar('Berhasil merubah bank', { variant: 'success' })
      } else {
        setLoading(false)
        getIndex()
        enqueueSnackbar('Gagal merubah bank', { variant: 'error' })
      }
    } catch (error) {
      setLoading(false)
      enqueueSnackbar('Gagal merubah bank', { variant: 'error' })
    }
  }

  const onSubmit = async (value) => {
    if (edit) {
      editApi(value)
    } else {
      createData(value)
    }
  }

  useEffect(() => {
    if (edit && bank) {
      setValue('name', bank?.name, { shouldValidate: true })
      if (bank?.bank_integrations?.length > 0) {
        const iluma = bank?.bank_integrations.find(
          (item) => item.third_party_service === 'iluma'
        )
        const xendit = bank?.bank_integrations.find(
          (item) => item.third_party_service === 'Xendit'
        )
        setValue('bank_integration', xendit?.bank_name, {
          shouldValidate: true,
        })
        setValue('iluma_integration', iluma?.bank_name, {
          shouldValidate: true,
        })
        setValue('bank_code', xendit?.bank_code, {
          shouldValidate: true,
        })
        setValue('iluma_code', iluma?.bank_code, {
          shouldValidate: true,
        })
      }
    }
  }, [edit, bank])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Detail Bank</DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          {edit && showLoading && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <CircularProgress size={24} />
              <span> Sedang mengambil data... </span>
            </Box>
          )}

          <Text
            label="Nama"
            name="name"
            ref={register}
            error={errors?.name?.message}
            disabled={showLoading || loading}
          />
          <Select2
            label="Pilih Bank Integrasi - Xendit Disbursement"
            name="bank_integration"
            control={control}
            selects={banks}
          />
          <Select2
            label="Pilih Bank Integrasi - Iluma"
            name="iluma_integration"
            control={control}
            selects={iluma}
          />
          <input type="text" ref={register} name="isEdit" value={edit} hidden />
          <input type="text" ref={register} name="xendit_id" hidden />
          <input type="text" ref={register} name="iluma_id" hidden />

          <ImageUpload
            errors={errors}
            name="icon"
            label="Bank Logo"
            src={bank?.icon}
            getValues={getValues}
            setValue={setValue}
            register={register}
            disabled={showLoading || loading}
          />
          <FormHelperText style={{ color: 'red' }}>
            {errors?.undefined && 'Silahkan pilih gambar'}
          </FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" disabled={showLoading || loading}>
            {loading ? <CircularProgress size={24} /> : 'Simpan'}
          </Button>
          <Button variant="outlined" onClick={onClose} disabled={loading}>
            Tutup
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
export default Form
