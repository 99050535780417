/** @jsxImportSource @emotion/react */

import { Fragment, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import PageTitle from '@components/PageTitle'
import Layout from '@layout/Main'
import useIndex from '@lib/query/ticket/useIndex'
import useUpdate from '@lib/query/ticket/useUpdate'
import DataTable from '@components/DataTable'
import Card from '@components/card/Base'
// import { useSelector } from 'react-redux'
// import dayjs from 'dayjs'
import { css } from '@emotion/react'
import FormTitle from './FormTitle'
import Form from './Form'
import hooks from './hooks'

const titleCSS = css`
  display: flex;
`

// const formTitleCSS = css`
//   p {
//     margin: 0;
//   }
//   .num {
//     font-family: Poppins;
//     font-size: 20px;
//     font-style: normal;
//     font-weight: 600;
//     line-height: 34px;
//     letter-spacing: 0em;
//     text-align: left;
//     span {
//       color: #3630C9;
//     }
//   }
//   .date {
//     font-family: Poppins;
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 600;
//     line-height: 24px;
//     letter-spacing: 0em;
//     text-align: left;
//   }
// `

// const FormTitle = () => {
//   const previewData = useSelector(({ ui: { dialog } }) => dialog.data)
//   return (
//     <div css={formTitleCSS}>
//       <p className="num">
//         No
//         {' '}
//         <span>{previewData?.number}</span>
//       </p>
//       <p className="date">
//         {dayjs(previewData?.created_at).locale('id').format('DD MMMM YYYY')}
//       </p>
//     </div>
//   )
// }

function TicketManagement() {
  const {
    tableData,
    tableColumns,
    hiddenColumns,
    tablePagination,
  } = useIndex()
  const update = useUpdate()
  return (
    <Fragment>
      <Helmet>
        <title>
          SQUIDS :: Kelola Ticketing
        </title>
      </Helmet>
      <PageTitle>
        Kelola Ticketing
      </PageTitle>
      <Layout>
        <Card>
          <div css={titleCSS}>
            <h3>Kelola Tiket</h3>
          </div>
          <DataTable
            css={CSS}
            data={tableData}
            columns={useMemo(() => tableColumns, [tableColumns])}
            hooks={hooks}
            hiddenColumns={hiddenColumns}
            pagination={tablePagination}
            onChange={() => null}
          />
        </Card>
      </Layout>
      <Form
        type="edit"
        title={<FormTitle />}
        defaultValues={{}}
        onSave={update}
      />
    </Fragment>
  )
}

export default TicketManagement
