import { adminApiBaseURL } from '@lib/env'
import Axios from 'axios'
import qs from 'qs'
import { setBearerToken, handleUnauthorized, normalizeResponse } from './interceptor'

const api = Axios.create({
  baseURL: adminApiBaseURL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
})
api.defaults.headers.common.Accept = 'application/json'
api.interceptors.request.use(setBearerToken)
api.interceptors.response.use(normalizeResponse, handleUnauthorized)

export const indexSaasPaymentSetups = () => api.get('/saas_payment_setups')

export const createSaasPaymentSetups = (secret_api_key, verification_token) => api.post('/saas_payment_setups', {
  saas_payment_setup: {
    secret_api_key,
    verification_token,
  },
})

export const showSaasPaymentSetups = (id) => api.get(`/saas_payment_setups/${id}`)

export const updateSaasPaymentSetups = (id, secret_api_key, verification_token) => api.put(`/saas_payment_setups/${id}`, {
  saas_payment_setup: {
    secret_api_key,
    verification_token,
  },
})
