import { useQuery } from 'react-query'
import { listTickets } from '@lib/axios/ticket'
import { useMemo } from 'react'

function useIndex() {
  const { status, data } = useQuery(
    ['saas-ticket', 'last-5'],
    () => listTickets({
      per_page: 5,
      page: 1,
      sort_by: 'desc',
      order_by: 'created_at',
    }),
    {
      retry: false,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  )
  const tableData = useMemo(
    () => (
      (status === 'success' && Array.isArray(data?.data?.data))
        ? data?.data?.data.map((item, index) => ({ table_number: index + 1, ...item }))
        : []
    ),
    [status, data]
  )

  const columns = [
    ['table_number', 'No.', true],
    ['id', 'ID', true],
    ['created_at', 'created_at', true],
    ['number', 'No. Ticketing', true],
    ['saas_user_name', 'Nama User', true],
    ['store_name', 'store_name', true],
    ['type_support', 'Jenis Support', true],
    ['subject', 'Subjek', true],
    ['status', 'status', true],
  ]

  const tableColumns = columns
    .map(([column, title]) => ([column, title]))
    .map(([accessor, Header]) => ({ accessor, Header }))

  const hiddenColumns = columns
    .filter(([, , hidden]) => hidden)
    .map(([column]) => column)

  return {
    tableData,
    tableColumns,
    hiddenColumns,
  }
}

export default useIndex
