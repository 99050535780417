/** @jsxImportSource @emotion/react */

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { ClassNames, css } from '@emotion/react'
import Icon from './Icon'
import Input from './Input'

const CSS = css`
  .MuiList-padding {
    padding: 0;
    border-radius: 5px;
  }
  .MuiMenuItem-root:first-of-type {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .MuiMenuItem-root:last-of-type {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`

const menuProps = {
  PaperProps: { elevation: 1 },
  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
  transformOrigin: { vertical: 'top', horizontal: 'left' },
  getContentAnchorEl: null,
}

function Field(props) {
  const {
    items,
    onChange: handleChange,
    onOpen: handleOpen,
    value: fieldValue,
  } = props
  return (
    <ClassNames>
      {({ css: cssToClassName }) => {
        menuProps.PaperProps.className = cssToClassName`${CSS}`
        return (
          <Select
            onChange={handleChange}
            onOpen={handleOpen}
            value={fieldValue}
            MenuProps={menuProps}
            IconComponent={Icon}
            input={<Input />}
          >
            <MenuItem value="">
              &nbsp;
            </MenuItem>
            {
            items
            && Array.isArray(items)
            && items.map((item) => {
              let title = item
              let value = item
              if (Array.isArray(item)) {
                [value, title] = item
              }
              return (
                <MenuItem
                  value={value}
                  key={value}
                >
                  {title}
                </MenuItem>
              )
            })
          }
          </Select>
        )
      }}
    </ClassNames>
  )
}

export default Field
