/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import IconButton from '@material-ui/core/IconButton'
import editSVG from '@assets/button/edit.svg'
import { openDialog } from '@lib/redux/slice/ui'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { showVoucher } from '@lib/axios/voucher'

const CSS = css`
  width: 30px;
  height: 30px;
`

const useOpenDialog = (type, data) => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  return () => dispatch(openDialog({ type: `${pathname}.${type}`, data }))
}

function Button(props) {
  const { type, data, onClick } = props
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const handleClick = useOpenDialog(type, data)

  const handleClickButton = async () => {
    handleClick()
    if (onClick) {
      const { data: editData, status } = await showVoucher(data.id)
      if (status === 'ok') {
        dispatch(openDialog({ type: `${pathname}.${type}`, data: editData?.data }))
        onClick()
      }
    }
  }

  return (
    <IconButton css={CSS} size="small" onClick={handleClickButton}>
      <img src={editSVG} alt="" />
    </IconButton>
  )
}

export default Button
