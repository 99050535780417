import { adminApiBaseURL } from '@lib/env'
import Axios from 'axios'
import qs from 'qs'
import {
  setBearerToken,
  handleUnauthorized,
  normalizeResponse,
} from './interceptor'

const api = Axios.create({
  baseURL: `${adminApiBaseURL}/xenplatform_accounts`,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
})
api.defaults.headers.common.Accept = 'application/json'
api.interceptors.request.use(setBearerToken)
api.interceptors.response.use(normalizeResponse, handleUnauthorized)

export const indexXenPlatforms = (params) => api.get('/', { params })

export const createXenPlatforms = (
  email,
  public_profile,
  account_type,
  store_id
) => api.post('/', {
  xenplatform_account: {
    email,
    public_profile,
    account_type,
    store_id,
  },
})
export const connectXenPlatforms = (xenplatform_id) => api.post('/connect', {
  xenplatform_id,
})

export const syncAllXenPlatforms = () => api.post('/sync_all', {
  filter: {
    limit: 3,
  },
})

export const updateXenplatform = ({ id, ...restData }) => api.put(`/${id}`, {
  xenplatform_account: {
    ...restData,
  },
})

export const deleteXenplatform = (id) => api.delete(`/${id}`)
