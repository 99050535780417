/* eslint-disable no-unused-vars */

import { connect } from 'react-redux'
import { Fragment, useEffect, useRef } from 'react'
import { remove } from '@lib/redux/slice/notification'
import { useSnackbar } from 'notistack'

const Notifier = (props) => {
  const displayed = useRef([])
  const { notifications, removeSnackbar } = props
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const storeDisplayed = (id) => {
    displayed.current = [...displayed.current, id]
  }

  const removeDisplayed = (id) => {
    displayed.current = [...displayed.current.filter((key) => id !== key)]
  }

  useEffect(() => {
    notifications.forEach(({
      key, message, options = {}, dismissed = false,
    }) => {
      if (dismissed) {
        closeSnackbar(key)
        return
      }

      if (displayed.current.includes(key)) return

      enqueueSnackbar(message, {
        key,
        ...options,
        action: (actionKey) => (
          <Fragment>
            {
              options?.action
              && Array.isArray(options?.action)
              && options.action.map(({ title }) => (
                <button key={title} type="button">{ title }</button>
              ))
            }
          </Fragment>
        ),
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey)
          }
        },
        onExited: (event, myKey) => {
          removeSnackbar(myKey)
          removeDisplayed(myKey)
        },
      })

      storeDisplayed(key)
    })
  }, [notifications, enqueueSnackbar, closeSnackbar, removeSnackbar])

  return null
}

const mapState = ({ notification: { notifications } }) => ({ notifications: notifications || [] })
const mapDispatch = {
  removeSnackbar: remove,
}
const connector = connect(mapState, mapDispatch)
const ConnectedNotifier = connector(Notifier)

export default ConnectedNotifier
