/* eslint-disable radix */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */

import useCreate from '@lib/query/voucher/useCreate'
import useUpdate from '@lib/query/voucher/useUpdate'
import { Fragment, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import PageTitle from '@components/PageTitle'
import Layout from '@layout/Main'
import Card from '@components/card/Base'
import { useForm, Controller } from 'react-hook-form'
import Text from '@components/field/Text'
import RangeDateField from '@components/field/RangeDateField'
import VoucherField from '@components/field/VoucherField'
import loadable from '@loadable/component'
import { css } from '@emotion/react'
import {
  Box,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputLabel,
  InputAdornment,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Simpan from '@components/button/Simpan'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { listPackages } from '@lib/axios/package'
import { FormatNumber, UnFormatRp } from '@lib/formatter'

import { useSelector } from 'react-redux'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import defaultValues from '../defaultValues'

const TextEditor = loadable(() => import('@components/field/DraftEditor'))

const schema = yup.object().shape({
  name: yup.string().required('Wajib Di Isi'),
  code: yup.string().required('Wajib Di Isi'),
})

const formCSS = css`
  display: flex;
  flex-direction: row;
  margin: 30px;
  justify-content: space-between;
`

const CSS = css`
  margin-bottom: 20px;
  margin-left: 0;
  min-width: 300px;
  .MuiTypography-root {
    font-size: 12px;
    margin-left: 5px;
  }
  .MuiFormLabel-root {
    font-size: 12px;
  }
  .MuiFormControlLabel-root {
    margin-left: -9px;
    margin-top: 10px;
  }
`

const NewVoucher = () => {
  const history = useHistory()
  const create = useCreate()
  const update = useUpdate()
  const editData = useSelector(({ ui: { dialog } }) => dialog.data)
  const edit = (useSelector(({ ui: { dialog } }) => dialog.type) === '/s/voucher.edit')
  const [selectedPackage, setSelectedPackage] = useState([])
  const { data: packageList, isLoading } = useQuery(
    'package',
    () => listPackages({ per_page: 100 }),
    { retry: false, keepPreviousData: true }
  )

  const initialValueEdit = {
    name: editData?.name,
    code: editData?.code,
    start_date: new Date(editData?.start_date),
    end_date: new Date(editData?.end_date),
    description: editData?.description,
    annual: (editData?.annual_amount),
    annual_amount: editData?.annual_amount,
    annual_period: editData?.annual_period,
    annual_type_cd: (editData?.annual_type_cd === 'annual_percentage') ? 0 : 1,
    monthly: (editData?.monthly_amount),
    monthly_amount: editData?.monthly_amount,
    monthly_period: editData?.monthly_period,
    monthly_type_cd: (editData?.monthly_type_cd === 'percentage') ? 0 : 1,
    marketing_budget: editData?.marketing_budget > 0 ? editData?.marketing_budget : '',
    limit: editData?.limit > 0 ? editData?.limit : '',
  }

  const [budgetForm, setBudgetForm] = useState(!!editData?.marketing_budget)
  const [quantityForm, setQuantityForm] = useState(!!editData?.limit)

  const {
    handleSubmit,
    register,
    control,
    errors,
    setValue,
    getValues,
  } = useForm({
    defaultValues: edit ? initialValueEdit : defaultValues,
    resolver: yupResolver(schema),
    shouldUnregister: false,
  })

  const handleChange = (data) => {
    const isExist = selectedPackage.find((val) => val.package_id === data.id)
    if (isExist) {
      const packageState = [...selectedPackage]
      const index = selectedPackage.findIndex((val) => val.package_id === data.id)
      packageState[index]._destroy = !isExist?._destroy ?? true
      setSelectedPackage(packageState)
    } else {
      setSelectedPackage([...selectedPackage, { package_id: data.id }])
    }
  }

  const submitData = (data) => {
    const { annual, monthly, ...rest } = data

    const voucher_package = {
      ...rest,
      voucher_package_details_attributes: selectedPackage,
    }

    if (edit) update({ id: editData.id, voucher_package })
    else create(voucher_package)
  }

  useEffect(() => {
    if (edit) {
      const paket = []
      if (editData.packages) {
        editData.packages.forEach(({ package_id, id }) => {
          paket.push({ package_id, id })
          setSelectedPackage([...paket])
        })
      }
    }
  }, [])

  return (
    <Fragment>
      <Helmet>
        <title>SQUIDS :: {edit ? 'Edit' : 'Tambah'} Voucher</title>
      </Helmet>
      <PageTitle>{edit ? 'Edit' : 'Tambah'} Voucher</PageTitle>
      <Layout>
        <Card>
          <h3>{edit ? 'Edit' : 'Tambah'} Voucher</h3>
          <form
            title="Tambah Voucher"
            onSubmit={handleSubmit((data) => submitData(data))}
          >
            <div css={formCSS}>
              <Box sx={{ width: '50%', paddingRight: '50px' }}>
                <div>
                  <Text
                    label="Nama Voucher"
                    name="name"
                    ref={register}
                    error={errors?.name?.message}
                    placeholder="Nama Voucher"
                  />
                </div>
                <div>
                  <Text
                    label="Kode Voucher"
                    name="code"
                    ref={register}
                    error={errors?.code?.message}
                    placeholder="Kode Voucher"
                  />
                </div>
                <div>
                  <RangeDateField
                    control={control}
                    errors={errors}
                    label="Tanggal Aktif"
                    startDate={getValues('start_date')}
                    setValue={setValue}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <InputLabel shrink>Ketentuan Voucher</InputLabel>
                  <TextEditor
                    label="Ketentuan Voucher"
                    name="description"
                    control={control}
                    error={errors?.description?.message}
                    placeholder="Ketentuan Voucher"
                  />
                </div>
              </Box>
              <Box sx={{ width: '50%', paddingRight: '50px' }}>
                <div>
                  <VoucherField
                    control={control}
                    editData={editData}
                    setValue={setValue}
                  />
                </div>
                <div css={CSS}>
                  <FormLabel>Daftar Paket</FormLabel>
                  <FormGroup>
                    <div style={{ display: 'grid', gridTemplateColumns: 'auto auto', width: '100%' }}>
                      {!isLoading
                        && packageList?.data?.data.map((val) => (
                          <FormControlLabel
                            key={val.id + val.name}
                            control={(
                              <Checkbox
                                size="small"
                                checked={
                                  !!selectedPackage.find((v) => (
                                    v.package_id === val.id && !v?._destroy
                                  ))
                                }
                                onChange={() => handleChange(val)}
                              />
                            )}
                            label={val.name}
                          />
                        ))}
                      {isLoading && <Fragment>loading...</Fragment>}
                    </div>
                  </FormGroup>
                </div>
                <div css={CSS}>
                  <FormLabel>Peraturan Tambahan</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox size="small" checked={budgetForm} />}
                      label="Setup Budget Marketing"
                      onChange={() => {
                        setBudgetForm(!budgetForm)
                        if (budgetForm) setValue('marketing_budget', editData?.marketing_budget > 0 ? 'unlimited' : '')
                      }}
                    />
                    <Controller
                      control={control}
                      name="marketing_budget"
                      render={({ onChange, value }) => (
                        <div>
                          {budgetForm && (
                          <Text
                            label=""
                            placeholder="Budget Marketing"
                            startAdornment={<InputAdornment position="start">Rp</InputAdornment>}
                            onChange={(evt) => onChange(UnFormatRp(evt.target.value))}
                            value={FormatNumber(value)}
                          />
                          )}
                        </div>
                      )}
                    />
                    <FormControlLabel
                      control={<Checkbox size="small" checked={quantityForm} />}
                      label="Setup Quantity"
                      onChange={() => {
                        setQuantityForm(!quantityForm)
                        if (quantityForm) setValue('limit', editData?.limit > 0 ? 'unlimited' : '')
                      }}
                    />
                    <Controller
                      control={control}
                      name="limit"
                      render={({ onChange, value }) => (
                        <div>
                          {quantityForm && (
                          <Text
                            label=""
                            placeholder="Limit Quantity"
                            onChange={(evt) => onChange(evt.target.value)}
                            value={value}
                          />
                          )}
                        </div>
                      )}
                    />
                  </FormGroup>
                </div>
              </Box>
            </div>
            <div style={{ margin: 30 }}>
              <Simpan handleClick />
              <Button
                variant="outlined"
                type="button"
                onClick={() => history.goBack()}
                style={{ marginLeft: 10, height: 45, width: 120 }}
              >
                Batal
              </Button>
            </div>
          </form>
        </Card>
      </Layout>
    </Fragment>
  )
}

export default NewVoucher
