/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'

const cardCSS = css`
  align-self: flex-start !important;
  border-radius: 5px;
  margin: 30px;
  background: #FFFFFF;
  border: 1px solid #ECF2F5;
  box-sizing: border-box;
  h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    margin-bottom: 23px;
    /* identical to box height, or 34px */
    margin-left: 33px;
    color: #1A253B;
  }

  height: auto;
  width: 684px;

`

function Card(props) {
  const { children } = props
  return (
    <div css={cardCSS}>
      { children }
    </div>
  )
}

export default Card
