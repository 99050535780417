/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import ButtonBase from '@material-ui/core/ButtonBase'

const buttonCSS = css`
  background: #556FB5;
  border-radius: 5px;
  border-width: 0;

  /** */

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 170%;
  /* or 27px */

  text-align: center;

  color: #FFFFFF;
  &:focus {
    outline: none;
  }
`

function Button(props) {
  const { className, children } = props
  return (
    <ButtonBase
      type="submit"
      css={buttonCSS}
      className={className}
    >
      {
        children || 'Button'
      }
    </ButtonBase>
  )
}

export default Button
