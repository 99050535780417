const FormatNumber = (x) => {
  let result = null
  if (x || x === 0) {
    const parts = x
      .toString()
      .split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    result = parts.join('.')
  }
  return result
}

const UnFormatRp = (x) => {
  let result = null
  if (x && typeof x === 'string') {
    const val = x
    const parts = val.replace(/,/g, '')
    result = parts
  }
  return result
}

export {
  FormatNumber,
  UnFormatRp,
}
