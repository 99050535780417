/** @jsxImportSource @emotion/react */
/* eslint-disable no-unused-vars */

import { css } from '@emotion/react'
import useQueryString from '@hooks/useQueryString'
import { useState, useMemo } from 'react'
import Terapkan from '@components/button/Terapkan'
import Reset from '@components/button/Reset'

import PageSize from '@components/filter/PageSize'
import Text from '@components/filter/Text'
import Number from '@components/filter/Number'
import Status from './Status'

const tableToolCSS = css`
  display: flex;
  margin-bottom: 14px;
  margin-right: 30px;
  margin-left: 30px;
  button, input {
    margin-right: 5px;
  }
  & > div {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  & > div > div {
    flex: 1;
    width: 49%;
  }
  & > div > div:last-of-type {
    flex: 1;
    display: flex;
  }
`

function Tools() {
  const {
    clearable, setQueries, clearQueries,
  } = useQueryString()
  const [state, setState] = useState(clearable)

  function applyFilter() {
    setQueries({ ...state, page: 1 })
  }

  return (
    <div css={tableToolCSS}>
      <div>
        <div>
          <PageSize />
        </div>
        <div>
          <Text
            placeholder="Cari nama..."
            onChange={(name) => setState({ ...state, name: name || undefined })}
            defaultValue={state.name}
          />
          <Status
            onSelected={(status) => setState({ ...state, status: status || undefined })}
            defaultValue={state.status}
          />
          <Terapkan onClick={applyFilter} />
          <Reset onClick={() => {
            clearQueries()
            setState({})
          }}
          />
        </div>
      </div>
    </div>
  )
}

export default Tools
