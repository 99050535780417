/* eslint-disable no-console */
import { logout } from '@lib/redux/slice/auth'
import { store } from '@lib/redux'
import { isDev } from '@lib/env'

export const setBearerToken = (request) => {
  const { getState } = store
  const { auth } = getState()
  const { accessToken } = auth
  request.headers.common.Accept = 'application/json'
  request.headers.common.Authorization = `Bearer ${accessToken}`
  return request
}

export const handleUnauthorized = async (error) => {
  if (error?.response?.status === 401) {
    store.dispatch(logout())
  }
  return error
}

export const logError = async (error) => {
  if (isDev) console.log(error?.response)
  return error
}

export class Error400 extends Error {
  constructor(data) {
    super('Error 400')
    this.name = 'Error 400'
    this.response = data
  }
}

export const normalizeResponse = async (response) => {
  const { data: { status, ...data } } = response
  const result = { data, status }
  if (status === 'unprocessable_entity') throw new Error400(data)
  else return result
}
