import { useSelector } from 'react-redux'
import { useMemo } from 'react'

const useValue = (defaultValues) => {
  const open = useSelector(({ ui: { dialog } }) => dialog.open)
  const data = useSelector(({ ui: { dialog } }) => dialog.data)

  const next = useMemo(
    () => {
      let value = defaultValues
      if (data) {
        value = Object.entries(value).reduce((prev, [k, v]) => ({
          ...prev,
          [k]: data?.[k] ?? v,
        }), {})
      }
      value = data?.id ? { id: data?.id, ...value } : value
      return value
    },
    [data, defaultValues]
  )

  return { value: next, open }
}

export default useValue
