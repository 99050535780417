import { TableCell, TableHead, TableRow } from '@material-ui/core'
import React, { useContext } from 'react'
import { TableContext } from './TableProvider'
import './tableHead.css'

const TableHeader = () => {
  const { column } = useContext(TableContext)
  return (
    <TableHead>
      <TableRow>
        {
          column.map(({ name, label }) => (
            <TableCell
              // variant="head"
              key={name}
              className="thead"
            >
              {label}
            </TableCell>
          ))
        }
        <TableCell
          variant="head"
          className="thead"
        >
          Aksi
        </TableCell>
      </TableRow>
    </TableHead>
  )
}
export default TableHeader
