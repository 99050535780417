/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import CssBaseline from '@material-ui/core/CssBaseline'
import { connect } from 'react-redux'
import { Fragment } from 'react'

import Confirm from '@components/dialog/Confirm'
import Success from '@components/dialog/Success'
import Uncompleted from '@components/dialog/Uncompleted'

import Sidebar from '@components/Sidebar'
import Account from '@components/button/Account'
import Notification from '@components/button/Notification'
import Search from '@components/Search'

const layoutCSS = css`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  .title {
    flex: 1;
    color: black;
  }
  .notification-button.MuiIconButton-root {
    padding: 0;
    height: 37px;
    width: 37px;
    margin-left: 11px;
  }
`

const mainContentCSS = css`
  width: calc(100vw - 278px) !important;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  margin-top: 88px;
  overflow: auto;
`

const appBarCSS = css`
  width: calc(100% - 278px) !important;
  margin-left: 278px;
  .MuiToolbar-root {
    min-height: 88px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #F6F9FB;
  }
`

function Main(props) {
  const { children, pageTitle } = props
  return (
    <Fragment>
      <div css={layoutCSS}>
        <CssBaseline />
        <Sidebar />
        <AppBar position="fixed" css={appBarCSS} elevation={0}>
          <Toolbar>
            <span className="title">{ pageTitle }</span>
            <Search />
            <Notification />
            <Account />
          </Toolbar>
        </AppBar>
        <main css={mainContentCSS}>
          { children }
        </main>
      </div>
      <Confirm />
      <Success />
      <Uncompleted />
    </Fragment>
  )
}

const mapState = (state) => ({ pageTitle: state.ui.pageTitle })

const connector = connect(mapState)

const ConnectedMain = connector(Main)

export default ConnectedMain
