/** @jsxImportSource @emotion/react */

import { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import PageTitle from '@components/PageTitle'
import { useRouteMatch, useLocation } from 'react-router-dom'

import { css } from '@emotion/react'
import { openDialog } from '@lib/redux/slice/ui'
import { useDispatch } from 'react-redux'

import Layout from '@layout/Main'
import Integrasi from '@components/button/Integrasi'

import mootaSVG from './svg/moota.svg'
import paymentGatewaySVG from './svg/paymentGateway.svg'

const containerCSS = css`
  flex: 1;
  padding: 30px;
  display: flex;
  background-color: #F6F9FB;
`

const useOpenPg = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  return () => dispatch(openDialog({
    type: `${pathname}.edit.pg`,
  }))
}

function Integration() {
  const { url } = useRouteMatch()
  const openPG = useOpenPg()
  return (
    <Fragment>
      <Helmet>
        <title>
          SQUIDS :: INTEGRASI
        </title>
      </Helmet>
      <PageTitle>
        Integrasi
      </PageTitle>
      <Layout>
        <div css={containerCSS}>
          <Integrasi onClick={openPG} title="Payment Gateway" imgSrc={paymentGatewaySVG} />
          <Integrasi to={`${url}/moota`} title="Moota" imgSrc={mootaSVG} />
        </div>
      </Layout>
    </Fragment>
  )
}

export default Integration
