import { useQuery } from 'react-query'
import { indexApi } from '@lib/axios/notification'
import { useMemo } from 'react'

function useIndex() {
  const { status, data } = useQuery(
    ['saas-notif', 'last-5'],
    () => indexApi({
      per_page: 5,
      page: 1,
      sort_by: 'desc',
      order_by: 'created_at',
    }),
    {
      retry: false,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  )
  const tableData = useMemo(
    () => (status === 'success' ? (data?.data?.data?.map?.((item, index) => ({ table_number: index + 1, ...item })) ?? []) : []),
    [status, data]
  )

  const columns = [
    ['id', 'ID', true],
    ['created_at', 'created_at', true],
    ['message', 'Notifikasi', true],
  ]

  const tableColumns = columns
    .map(([column, title]) => ([column, title]))
    .map(([accessor, Header]) => ({ accessor, Header }))

  const hiddenColumns = columns
    .filter(([, , hidden]) => hidden)
    .map(([column]) => column)

  const emptyPagination = useMemo(() => ({
    current_page: 1,
    first_page: true,
    last_page: false,
    next_page: null,
    prev_page: null,
    total_pages: 1,
  }), [])

  const tablePagination = useMemo(
    () => ((status === 'success' && data?.data?.pagination) ? data?.data?.pagination : emptyPagination),
    [status, data, emptyPagination]
  )

  return {
    tableData,
    tableColumns,
    hiddenColumns,
    tablePagination,
  }
}

export default useIndex
