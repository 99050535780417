/** @jsxImportSource @emotion/react */

import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { connect } from 'react-redux'
import { logout } from '@lib/redux/slice/auth'

import logoutSVG from './svg/logOut.svg'

const LogoutButton = (props) => {
  const { onClick: handleClick } = props

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        <img src={logoutSVG} alt="" />
      </ListItemIcon>
      Logout
    </MenuItem>
  )
}

const connector = connect(null, {
  onClick: () => logout(),
})

const ConnectedLogoutButton = connector(LogoutButton)

export default ConnectedLogoutButton
