import {
  Switch, Route, BrowserRouter, Redirect,
} from 'react-router-dom'
import { connect } from 'react-redux'

import { securedRoutePath } from '@lib/constant'
import ForgotPassword from '@screens/auth/ForgotPassword'
import Login from '@screens/auth/Login'
import ResetPassword from '@screens/auth/ResetPassword'
import LinkSent from '@screens/auth/LinkSent'
import SecuredRoutes from './SecuredRoutes'

function Routes(props) {
  const { isLoggedIn } = props

  // eslint-disable-next-line global-require
  // require('@hooks/useRenderCount').default('routes')

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route path="/login">
          {
            isLoggedIn ? <Redirect to={securedRoutePath} /> : <Login />
          }
        </Route>
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/api/v1/saas_admins/password/edit" component={ResetPassword} />
        <Route path="/link-sent" component={LinkSent} />
        <Route path={securedRoutePath}>
          {
            !isLoggedIn ? <Redirect to="/login" /> : <SecuredRoutes />
          }
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

const mapState = ({ auth }) => ({ isLoggedIn: auth.isLoggedIn })
const connector = connect(mapState)
const ConnectedRouted = connector(Routes)
export default ConnectedRouted
