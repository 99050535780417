/* eslint-disable no-console */
import { Fragment } from 'react'
import Ubah from '@components/button/Ubah'

const Cell = ({ row: { original: data } }) => (
  <Fragment>
    <Ubah data={data} type="edit" />
  </Fragment>
)

const action = (hooks) => {
  hooks.visibleColumns.push((columns) => [
    ...columns,
    {
      id: 'action',
      Header: () => <div>Aksi</div>,
      Cell,
    },
  ])
}

export default action
