/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react'
import {
  TableBody as MUITableBody,
  TableCell,
  TableRow,
} from '@material-ui/core'
import { TableContext } from './TableProvider'
import TableAction from './TableAction'
import currencyFormatter from './currencyFormat'

const statusMapper = {
  pending: {
    text: 'Pending',
    color: '#3630C9',
  },
  processed: {
    text: 'Diproses',
    color: '#4BAEA0',
  },
  canceled: {
    text: 'Batal',
    color: '#EF6C57',
  },
  verification_failed: {
    text: 'Verifikasi Gagal',
    color: '#EF6C57',
  },
}

const TableBody = () => {
  const { data, column } = useContext(TableContext)
  return (
    <MUITableBody>
      {data.map((row) => (
        <TableRow key={row.id}>
          {column.map((col) => (
            <TableCell variant="body" key={col.name}>
              {col.name === 'amount' ? (
                currencyFormatter(row[col.name])
              ) : col.name === 'status' ? (
                <p
                  style={{
                    color: statusMapper[row[col.name]]?.color || '#000',
                  }}
                >
                  {statusMapper[row[col.name]]?.text || ''}
                </p>
              ) : (
                row[col.name]
              )}
            </TableCell>
          ))}
          <TableCell>
            <TableAction rowData={row} />
          </TableCell>
        </TableRow>
      ))}
    </MUITableBody>
  )
}
export default TableBody
