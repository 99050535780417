import { useQuery } from 'react-query'
import { getSaasUser } from '@lib/axios/user'
import { useMemo } from 'react'

function useIndex10() {
  const params = {
    sort_by: 'desc',
    order_by: 'created_at',
    page: 1,
  }
  const { status, data } = useQuery(
    ['saas-user', '10-latest'],
    () => getSaasUser(params),
    {
      retry: false,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  )
  const tableData = useMemo(
    () => (status === 'success' && Array.isArray(data?.data?.data)
      ? data?.data?.data.map((item, index) => ({
        number: index + 1,
        ...item,
      }))
      : []),
    [status, data]
  )

  const columns = [
    ['number', 'No.', false],
    ['name', 'Nama', false],
    ['email', 'Email', false],
    ['created_at', 'created_at ', true],
  ]

  const tableColumns = columns
    .map(([column, title]) => [column, title])
    .map(([accessor, Header]) => ({ accessor, Header }))

  const hiddenColumns = columns
    .filter(([, , hidden]) => hidden)
    .map(([column]) => column)

  return {
    tableData,
    tableColumns,
    hiddenColumns,
  }
}

export default useIndex10
