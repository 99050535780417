/* eslint-disable prefer-template */
/** @jsxImportSource @emotion/react */

import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import { closeDialog, openDialog } from '@lib/redux/slice/ui'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { css } from '@emotion/react'
import DialogContent from '@material-ui/core/DialogContent'

import closeSVG from './svg/close.svg'
import linkSVG from './svg/link.svg'

const TYPE = 'form.dialog.show'

const closeCSS = css`
  &.MuiIconButton-root {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`

const CSS = css`
  .MuiDialog-paper {
    width: 829px;
    height: 514px;
    max-width: 829px;
  }
`

const contentCSS = css`
  display: flex;
  flex-direction: column;
  span {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    position: absolute;
    color: #676767;
    top: 0;
    left: 0;
  }
  & > div {
    display: flex;

  }
  & > div > div {
    height: 58px;
    padding-top: 20px;
    position: relative;
    flex: 1;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
`

const tableCSS = css`
  flex-direction: column;
  h3 {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  table {
    width: 80%;
    border-spacing: 0;
  }
  thead tr th {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-align: left;
    border-bottom: 1px solid #E5E5E5;
    padding: 5px;
    color: #676767;
  }
  tbody tr td {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    padding: 6px;
  }
  tbody tr td:nth-of-type(2) {
    color: blue;
  }
  tbody tr td:last-of-type {
    color: red;
  }
`

function FormDialog(props) {
  const {
    open,
    type,
    data,
    onClose: handleClose,
    // onSuccess: handleSuccess,
  } = props
  return (
    <Dialog css={CSS} open={open && type === TYPE}>
      <DialogTitle>
        Detail User
        <IconButton
          size="small"
          onClick={handleClose}
          css={closeCSS}
        >
          <img src={closeSVG} alt="" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div css={contentCSS}>
          <div>
            <div>
              <span>Name User</span>
              { data?.name }
            </div>
            <div>
              <span>Email</span>
              { data?.email }
            </div>
            <div>
              <span>Status</span>
              { data?.activation_status }
            </div>
          </div>
          <div>
            <div>
              <span>No. HP/Whatsapp</span>
              { data?.phone_number }
            </div>
            <div>
              <span>Alamat</span>
              { data?.address }
            </div>
            <div />
          </div>
          <div css={tableCSS}>
            <h3>Daftar Toko</h3>
            <table>
              <thead>
                <tr>
                  <th>Nama</th>
                  <th>Domain</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {
                  data?.stores
                  && Array.isArray(data?.stores)
                  && data?.stores.map(({
                    id, name, domain, is_active,
                  }) => (
                    <tr key={id}>
                      <td>{name}</td>
                      <td>
                        {
                          domain
                          && (
                            <a
                              href={'https://' + domain}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {domain}
                              {' '}
                              <img src={linkSVG} alt="" />
                            </a>
                          )
                        }
                      </td>
                      <td>
                        <div style={{ color: is_active ? '#4BAEA0' : '#E82A63' }}>
                          { is_active ? 'Aktif' : 'Suspended' }
                        </div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          {/* <img css={css`width: 507px; padding-top: 30px;`} src={dummySVG} alt="" /> */}
        </div>
      </DialogContent>
    </Dialog>
  )
}

const mapState = ({ ui: { dialog: { type, open, data } } }) => ({
  type,
  open,
  data,
})

const mapDispatch = {
  onClose: () => closeDialog(),
  onSuccess: () => openDialog({
    type: 'success.dialog',
    data: {
      title: 'Sukses',
      message: 'Sukses Mengubah Data',
    },
  }),
}

const connector = connect(mapState, mapDispatch)
const ConnectedFormDialog = connector(FormDialog)
export default ConnectedFormDialog
