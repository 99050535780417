/* eslint-disable no-unused-vars */
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { isDev } from '@lib/env'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'localforage'
import logger from './logger'

import rootReducer from './reducer'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const middleware = [...getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
})]

if (process.env.NODE_ENV === 'development') {
  // middleware.push(logger)
}

const store = configureStore({
  reducer: persistedReducer,
  middleware,
})

if (isDev && module.hot) {
  module.hot.accept('./reducer', async () => {
    const nextRootReducer = (await import('./reducer')).default
    const persistedNextReducer = persistReducer(persistConfig, nextRootReducer)
    store.replaceReducer(persistedNextReducer)
  })
}

const persistor = persistStore(store)

export { store, persistor }
