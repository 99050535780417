/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
import { useEffect, useMemo, useState } from 'react'
import qs from 'qs'
import { useHistory, useLocation } from 'react-router'
import { indexApi, showApi } from '../api'

const bankColumn = [
  // {
  //   name: 'id',
  //   label: 'id',
  // },
  {
    name: 'name',
    label: 'Nama Bank',
  },
  {
    name: 'icon',
    label: 'Logo',
  },
]

const useTableState = () => {
  const location = useLocation()
  const history = useHistory()
  const [queryString, setQueryString] = useState()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [total, setTotal] = useState(10)
  const [data, setData] = useState([])
  const column = bankColumn
  const [loading, setLoading] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [bank, setbank] = useState()
  const [openForm, setOpenForm] = useState(false)
  const [edit, setEdit] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [id, setId] = useState()
  const collection = useMemo(() => data, [data])

  const queryPush = (query) => {
    const newQuery = { ...queryString, ...query }
    setQueryString(newQuery)
    history.push({ path: location.pathname, search: qs.stringify(newQuery) })
  }

  const queryRemove = (key) => {
    const newQuery = { ...queryString }

    if (typeof key === 'string') {
      delete newQuery[key]
    } else if (Array.isArray(key)) {
      key.map((item) => delete newQuery[item])
    }

    setQueryString(newQuery)
    history.push({ path: location.pathname, search: qs.stringify(newQuery) })
  }

  const getIndex = async () => {
    setData([])
    try {
      setLoading(true)
      const { status, data } = await indexApi(qs.parse(location.search, { ignoreQueryPrefix: true }))
      if (status === 'ok') {
        setLoading(false)
        setData(data?.data)
        setTotal(data?.pagination?.total_pages)
      } else {
        setLoading(false)
      }
    } catch {
      setLoading(false)
    }
  }

  const getShow = async (id) => {
    setbank()
    try {
      setShowLoading(true)
      const { status, data } = await showApi(id)
      if (status === 'ok') {
        setShowLoading(false)
        setbank(data?.data)
      }
    } catch {
      setShowLoading(false)
    }
  }

  return {
    queryString,
    setQueryString,
    page,
    setPage,
    perPage,
    setPerPage,
    queryPush,
    column,
    data,
    getIndex,
    queryRemove,
    loading,
    total,
    setTotal,
    collection,
    getShow,
    bank,
    setbank,
    showLoading,
    setOpenForm,
    openForm,
    edit,
    setEdit,
    deleteOpen,
    setDeleteOpen,
    id,
    setId,
  }
}

export default useTableState
