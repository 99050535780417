/* eslint-disable no-console */
import dayjs from 'dayjs'

const status = {
  paid: {
    color: '#66BB6A',
    display: 'Dibayar',
  },
  open: {
    color: '#FFA726',
    display: 'Belum Dibayar',
  },
  closed: {
    color: '#F44336',
    display: 'Expired',
  },
  expired: {
    color: '#F44336',
    display: 'Expired',
  },
}

const Action = (hooks) => {
  hooks.visibleColumns.push((columns) => [
    ...columns,
    {
      id: 'registerDate',
      Header: () => <div>Tanggal Register</div>,
      Cell: ({ row: { original: data } }) => (
        <span>
          {dayjs(new Date(data.created_at)).format(
            'DD MMM YYYY'
          )}
        </span>
      ),
    },
    {
      id: 'invoice_status',
      Header: () => (<div>Status</div>),
      Cell: ({ row: { original: data } }) => (
        <span style={{ color: status[data?.invoice_status]?.color }}>
          { status[data?.invoice_status]?.display }
        </span>
      ),
    },
  ])
}

export default Action
