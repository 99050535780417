/* eslint-disable react/jsx-filename-extension */
import React from 'react'

function ChevronIcon(props) {
  const { color } = props
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 14.6063L10 9.73755L6 4.86877"
        stroke={color || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ChevronIcon
