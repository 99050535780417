import Axios from 'axios'
import { themeApiBaseURL } from '@lib/env'
import qs from 'qs'
import { setBearerToken, handleUnauthorized, normalizeResponse } from './interceptor'

const config = {
  headers: {
    'content-type': 'multipart/form-data',
  },
}
const api = Axios.create({
  baseURL: themeApiBaseURL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
})
api.defaults.headers.common.Accept = 'application/json'
api.interceptors.request.use(setBearerToken)
api.interceptors.response.use(normalizeResponse, handleUnauthorized)

export const listThemes = (params) => api.get('/', { params })

export const createTheme = ({ package_details, ...data }) => {
  // eslint-disable-next-line prefer-const
  let formData = new FormData()
  formData.append('theme[image]', data.image)
  formData.append('theme[name]', data.name)
  formData.append('theme[status]', data.status)
  formData.append('theme[thumbnail]', data.thumbnail)
  return api.post('/', formData, config)
}

export const updateTheme = ({ id, ...restData }) => {
  // eslint-disable-next-line prefer-const
  let formData = new FormData()
  formData.append('theme[image]', restData.image)
  formData.append('theme[name]', restData.name)
  formData.append('theme[status]', restData.status)
  formData.append('theme[thumbnail]', restData.thumbnail)
  return api.put(`/${id}`, formData, config)
}

export const showTheme = (id) => api.get(`/${id}`)

export const deleteTheme = (id) => api.delete(`/${id}`)
