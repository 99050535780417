import { createMuiTheme } from '@material-ui/core'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#556FB5',
    },
    secondary: {
      main: '#24E09B',
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: {
            variant: 'reset',
          },
          defaultProps: {
            color: '#556FB5',
          },
          style: {
            borderWidth: '1px',
            borderColor: '#556FB5',
            borderStyle: 'solid',
            width: '80px',
            minWidth: '80px',
            height: '30px',
            '& .MuiButton-label': {
              color: '#556FB5',
            },
          },
        },
        {
          props: {
            variant: 'reset',
          },
          defaultProps: {
            color: '#556FB5',
          },
          style: {
            borderWidth: '1px',
            borderColor: '#556FB5',
            borderStyle: 'solid',
            width: '80px',
            minWidth: '80px',
            height: '30px',
            '& .MuiButton-label': {
              color: '#556FB5',
            },
          },
        },
      ],
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 5,
        },
        label: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 5,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 5,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '32px',
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        PaperProps: {
          elevation: 0,
        },
      },
    },
  },
})

export default theme
