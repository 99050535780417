/* eslint-disable no-unused-expressions */

import * as yup from 'yup'

const validateImageSize = (value) => {
  value && value[0].size > 1024 * 16
}

const validateImageType = (value) => {
  const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png']
  value && value[0].type.includes(acceptedImageTypes)
}

const validateDomain = (value) => /^[a-zA-Z0-9-]+$/.test(value)
const validateCharacter = (value) => /^[a-zA-Z0-9 ]+$/.test(value)
const validatePhoneNumber = (value) => /^[0-9]+$/.test(value)

export const rules = {
  stringRequired: yup.string('berupa string').required('tidak boleh kosong')
    .test('pattern', 'simbol tidak diperbolehkan', validateCharacter),
  phoneNumber: yup.string().max(12, 'maksimal 12 digit').required('tidak boleh kosong')
    .test('pattern', 'hanya berupa angka', validatePhoneNumber),
  email: yup.string('berupa string').required('tidak boleh kosong').email('tidak valid'),
  password: yup.string().required('tidak boleh kosong').min(8, 'minimal 7 karakter 1 angka'),
  passwordConfirmation: yup.string()
    .oneOf([yup.ref('password'), null], 'password tidak sama'),
  imageUploader: yup.mixed()
    .test('fileSize', 'maksimal 2mb', validateImageSize)
    .test('fileType', 'file hanya berupa jpeg, png, gif', validateImageType),
  domain: yup.string().required('tidak boleh kosong')
    .test('pattern', "simbol yang di izinkan hanya '-'", validateDomain),
  number: yup.number().required('tidak boleh kosong'),
}

export const schema = (object) => yup.object().shape(object)
