/* eslint-disable no-console */

import { Fragment } from 'react'
import Ubah from '@components/button/Ubah'
import imgBlank from '@assets/field/image-blank.svg'
import Hapus from './Hapus'

const Cell = ({ row: { original: data } }) => (
  <Fragment>
    <Ubah data={data} type="edit" />
    <Hapus id={data.id} />
  </Fragment>
)

const action = (hooks) => {
  hooks.visibleColumns.push((columns) => [
    {
      id: 'NO. TEMA',
      Header: () => (<div>NO. TEMA</div>),
      Cell: ({ row: { original: data } }) => (
        <span>
          {data.theme_number || '-'}
        </span>
      ),
    },
    ...columns,
    {
      id: 'THUMBNAIL',
      Header: () => (<div>THUMBNAIL</div>),
      Cell: ({ row: { original: data } }) => (
        <span>
          {
            data.thumbnail?.url ? (
              <Fragment>
                <img src={data.thumbnail?.url} alt="" height="40" width="70" />
              </Fragment>
            ) : (
              <Fragment>
                <img src={imgBlank} alt="" height="40" width="70" />
              </Fragment>
            )
          }
        </span>
      ),
    },
    {
      id: 'Status',
      Header: () => (<div>Status</div>),
      Cell: ({ row: { original: data } }) => (
        <span style={{ color: data.status ? '#4BAEA0' : '#E82A63' }}>
          { data.status === 1 ? 'Aktif' : 'Tidak Aktif' }
        </span>
      ),
    },
    {
      id: 'action',
      Header: () => (<div>Aksi</div>),
      Cell,
    },
  ])
}

export default action
