/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useRef, useEffect, useCallback } from 'react'
import IMask from 'imask/esm/imask'
import 'imask/esm/masked/number'

const CSS = css`
    padding: 4px;
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    background-color: #F6F9FB;
    padding: 4px 10px;
    border: 1px solid rgba(0, 0, 0, .02);
    border-radius: 5px;
    width: 100%;
    color: #676767;
`

function Text(props) {
  const {
    placeholder,
    className,
    onChange: handleChange,
    defaultValue,
  } = props
  const ref = useRef()
  const maskRef = useRef()

  const handleAccept = useCallback(
    () => handleChange(maskRef.current.unmaskedValue),
    [handleChange]
  )

  const handleComplete = useCallback(
    () => handleChange(maskRef.current.unmaskedValue),
    [handleChange]
  )

  const initMask = useCallback(
    () => {
      maskRef.current = IMask(ref.current, {
        mask: Number,
        scale: 0,
        signed: false,
        thousandsSeparator: '.',
      })
        .on('accept', handleAccept)
        .on('complete', handleComplete)
    },
    [ref, handleAccept, handleComplete]
  )

  const destroyMask = () => {
    if (maskRef.current) {
      maskRef.current.destroy()
      maskRef.current = undefined
    }
  }

  useEffect(() => {
    initMask()
    return () => destroyMask()
  }, [initMask])

  return (
    <input
      value={defaultValue || ''}
      className={className}
      css={CSS}
      type="text"
      ref={ref}
      placeholder={placeholder}
      onChange={(e) => handleChange(e?.target?.value)}
      autoComplete="off"
      spellCheck={false}
    />
  )
}

export default Text
