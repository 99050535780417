import { connect } from 'react-redux'
import { setPageTitle } from '@lib/redux/slice/ui'
import { useEffect } from 'react'

function PageTitle(props) {
  const { setTitle, children: title } = props
  useEffect(() => {
    if (title) {
      setTitle(title)
    }
  }, [title, setTitle])
  return null
}

const mapDispatch = { setTitle: setPageTitle }
const connector = connect(null, mapDispatch)
const ConnectedPageTitle = connector(PageTitle)
export default ConnectedPageTitle
