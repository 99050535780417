import { suspendSaasUser } from '@lib/axios/user'
import { useMutation, useQueryClient } from 'react-query'

const useUserMutationSuspend = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (id) => suspendSaasUser(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('saas-user')
      },
    }
  )
}

export default useUserMutationSuspend
