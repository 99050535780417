/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */

/** @jsxImportSource @emotion/react */
import Down from './svg/down-up-down.svg'
import Up from './svg/down-up-up.svg'

function THead(props) {
  const { headerGroups } = props
  return (
    <thead>
      {headerGroups.map((headerGroup, groupKey) => (
        <tr key={groupKey}>
          {headerGroup.headers.map((column, headerKey) => (
            <th key={headerKey} {...column.getHeaderProps(column.getSortByToggleProps())}>
              {
                  column.render('Header')
                }
              {
                  !['selection', 'action'].includes(column.id)
                  && (
                    <span>
                      {
                        column.isSorted
                          ? column.isSortedDesc
                            ? <img src={Down} alt="" />
                            : <img src={Up} alt="" />
                          : ''
                      }
                    </span>
                  )
                }
            </th>
          ))}
        </tr>
      ))}
    </thead>
  )
}

export default THead
