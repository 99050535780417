import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.only('sm')]: {
      margin: theme.spacing(3),
    },
    [theme.breakpoints.only('xs')]: {
      margin: theme.spacing(2),
    },
  },
  header: {
    display: 'flex',
    margin: theme.spacing(3),
  },
  txHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  txHead: {
    color: theme.palette.primary.main,
    fontSize: 14,
    paddingBottom: theme.spacing(1),
  },
  tFooter: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.only('xs')]: {
      padding: 0,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
}))

export default useStyles
