/** @jsxImportSource @emotion/react */

/* eslint-disable no-unused-vars */

import { Fragment } from 'react'
import { Controller } from 'react-hook-form'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import { css } from '@emotion/react'
import Field from './Field'
import FieldDisabled from './FieldDisabled'

const formControlCSS = css`
  &.MuiFormControl-root {
    padding-top: 18px;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #676767;
  }
  & .MuiFormLabel-root.Mui-focused {
    color: #676767;
  }
  & .MuiFormHelperText-root {
    margin: 5px 5px 0 5px;
    font-size: 10px;
    line-height: 1;
  }
  & .MuiInputAdornment-positionStart {
    margin-right: 0;
  }
  & .MuiInputAdornment-positionEnd {
    margin-left: 0;
    height: 45px;
    max-height: 45px;
    justify-content: center;
    display: flex;
    flex-direction: row;
    button {
      padding: 0px;
      margin: 0 5px;
    }
  }
  & .MuiInputAdornment-positionEnd > span {
    margin-right: 10px;
    margin-left: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(0,0,0,.5);
  }
  & .MuiInputAdornment-positionEnd > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
`

function TextNumber(props) {
  const {
    label, name, className, error, control, step, placeholder,
    endText,
  } = props

  return (
    <FormControl css={formControlCSS} className={className}>
      <Controller
        control={control}
        name={name}
        render={({ onChange: handleChange, value, ref }) => (
          <Fragment>
            <InputLabel disableAnimation shrink>
              {label || (
              <Fragment>
                &nbsp;
              </Fragment>
              )}
            </InputLabel>
            {
              (`${value}` !== '-1')
                ? (
                  <Field
                    placeholder={placeholder}
                    onChange={handleChange}
                    value={value}
                    step={step}
                    inputRef={ref}
                    endText={endText}
                  />
                )
                : (
                  <FieldDisabled step={step} />
                )
            }
            <FormHelperText error={!!error}>
              {error || (
              <Fragment>
              &nbsp;
              </Fragment>
              )}
            </FormHelperText>
          </Fragment>
        )}
      />
    </FormControl>
  )
}

export default TextNumber
