import { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import PageTitle from '@components/PageTitle'
import Layout from '@layout/Main'

function SubscribeManagement() {
  return (
    <Fragment>
      <Helmet>
        <title>
          SQUIDS :: Manajemen Subscribe
        </title>
      </Helmet>
      <PageTitle>
        Manajemen Subscribe
      </PageTitle>
      <Layout>
        &nbsp;
      </Layout>
    </Fragment>
  )
}

export default SubscribeManagement
