/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Helmet } from 'react-helmet'
import React, { Fragment, useEffect, useState } from 'react'
import PageTitle from '@components/PageTitle'
import Layout from '@layout/Main'
import { Box, Typography } from '@material-ui/core'

import Line from '@components/chart/Line'
import { index } from '@lib/axios/dashboard'
import { useHistory } from 'react-router-dom'
import Form from '@screens/secured/TicketManagement/Form'
import FormTitle from '@screens/secured/TicketManagement/FormTitle'
import useUpdate from '@lib/query/ticket/useUpdate'
import useWidth from '@hooks/useWidth'
import DateRangePicker from '@material-ui/lab/DateRangePicker'
import AdapterDateFns from '@material-ui/lab/AdapterDateFns'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import Bar from '@components/chart/Bar'
import dayjs from 'dayjs'

import Table10User from './Table10User'
import Table5Ticket from './Table5Ticket'

import tagihanDibayar from './svg/tagihan-dibayar.svg'
import tagihanOverdue from './svg/tagihan-overdue.svg'
import tagihanDibayarOverdue from './svg/tagihan-dibayar-overdue.svg'
import totalPemasukan from './svg/total-pemasukan.svg'
import chevronSVG from './svg/chevron.svg'
import calendarSVG from './svg/calendar.svg'
import downSVG from './svg/down.svg'
import leadSVG from './svg/lead.svg'
import paidSVG from './svg/paid.svg'
import newUserSVG from './svg/new_user.svg'
import ChevronIcon from './svg/ChevronIcon'
import pelangganSVG from './svg/pelanggan.svg'
import userExpiredSVG from './svg/user_expired.svg'
import userNonActiveSVG from './svg/user_nonactive.svg'

const CSS = css`
  width: 100%;
  padding: 30px;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  .four {
    flex: 1;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    width: 100%;
    div {
      flex: 1;
    }
    div:not(:last-of-type) {
      margin-right: 15px;
    }
  }
  & .full {
    width: 100%;
    flex: 1;
    margin-bottom: 15px;
  }
  & .half {
    display: flex;
    margin-bottom: 15px;
  }
  & .half > div {
    flex: 1;
  }
  & .half > div:first-of-type {
    margin-right: 15px;
  }
  & .half > div:last-of-type {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    div {
      margin-bottom: 10px;
    }
  }
  .parent-chart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    .chart-item {
      padding: 24px;
      width: 32.5%;
      background: #ffffff;
      border-radius: 10px;
      display: flex;
      height: 156px;
      .omset-chart {
        p {
          font-size: 24px;
        }
      }
      div {
        width: 50%;
        span {
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 27px;
          letter-spacing: 0.1em;
          text-align: left;
          img {
            margin-right: 6px;
            width: 24px;
            height: 24px;
            float: left;
          }
        }
        p {
          font-family: Poppins;
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: 38px;
          letter-spacing: 0em;
          text-align: left;
          margin: 16px 0px;
        }
        a {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          display: inline;
          position: relative;
          padding-right: 24px;
          display: flex;
          align-items: center;
          &:hover {
            cursor: pointer;
          }
          img {
            position: absolute;
            width: 16px;
            height: 20px;
            right: 0;
            padding-top: 4px;
          }
        }
      }
    }
  }
  .section-three {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    .parent-table {
      width: 66.25%;
    }
    .parent-side {
      width: 32.5%;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      .chart-item {
        padding: 24px;
        margin-bottom: 20px;
        width: 100%%;
        background: #ffffff;
        border-radius: 10px;
        display: flex;
        height: 156px;
        .omset-chart {
          p {
            font-size: 24px;
          }
        }
        div {
          width: 50%;
          span {
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 27px;
            letter-spacing: 0.1em;
            text-align: left;
            img {
              margin-right: 6px;
              width: 24px;
              height: 24px;
              float: left;
            }
          }
          p {
            font-family: Poppins;
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: 38px;
            letter-spacing: 0em;
            text-align: left;
            margin: 16px 0px;
          }
          a {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            display: inline;
            position: relative;
            padding-right: 24px;
            display: flex;
            align-items: center;
            &:hover {
              cursor: pointer;
            }
            img {
              position: absolute;
              width: 16px;
              height: 20px;
              right: 0;
              padding-top: 4px;
            }
          }
        }
      }
    }
  }
`

const topCardCSS = css`
  min-height: 143px;
  width: 264px;
  border-radius: 5px;
  background: #ef6c57;
  color: white;
  padding: 17px;
  span {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0.1em;
    text-align: left;
    img {
      margin-right: 6px;
      width: 24px;
      height: 24px;
      float: left;
    }
  }
  p {
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
  }
  a {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    display: inline;
    position: relative;
    padding-right: 24px;
    &:hover {
      cursor: pointer;
    }
    img {
      position: absolute;
      width: 16px;
      height: 20px;
      right: 0;
      padding-top: 4px;
    }
  }
`

export const thousandFormat = (value) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')

function Dashboard() {
  const update = useUpdate()
  const [ongoingIncome, setOngoingIncome] = useState()
  const [ongoingUsers, setOngoingUsers] = useState()
  const [leadsData, setLeads] = useState()
  const [userInvoices, setUserInvoices] = useState()
  const [billsAndPayments, setBillsAndPayments] = useState()
  const [openDate, setOpenDate] = useState()
  const [usersData, setUsersData] = useState()
  const history = useHistory()
  const width = useWidth()
  const [dates, setDates] = useState([
    new Date()?.toISOString(),
    new Date()?.toISOString(),
  ])

  useEffect(() => {
    index(dates[0], dates[1]).then((response) => {
      if (response.status === 200) {
        const {
          ongoing_income,
          ongoing_users,
          bills_and_payments,
          users,
          leads,
          user_invoices,
        } = response?.data?.data
        setUsersData(users)
        setUserInvoices(user_invoices)
        setLeads(leads)
        setBillsAndPayments(bills_and_payments)
        if (ongoing_income) {
          const ongoing_income_map = ongoing_income.map(({ name, data }) => ({
            id: name,
            data: data
              .map((value, idx) => ({
                x: idx * 5,
                y: value,
              }))
              .map(({ x, y }) => ({ x: x.toString(), y })),
          }))
          setOngoingIncome(ongoing_income_map)
        }
        if (ongoing_users) {
          const ongoing_users_map = ongoing_users.map((d) => ({
            id: d.name.replace('_', ' '),
            data: d.data
              .map((value, idx) => ({ x: idx * 5, y: value }))
              .map(({ x, y }) => ({ x: x.toString(), y })),
          }))
          setOngoingUsers(ongoing_users_map.reverse())
        }
      }
    })
  }, [dates])

  return (
    <Fragment>
      <Helmet>
        <title>SQUIDS :: DASHBOARD</title>
      </Helmet>
      <PageTitle>Dashboard</PageTitle>
      <Layout>
        <div css={CSS}>
          <div
            className="four"
            style={{ flexDirection: ['xs'].includes(width) && 'column' }}
          >
            {[
              [
                'Tagihan Dibayar',
                billsAndPayments?.payments,
                tagihanDibayar,
                '#9873B9',
                '/s/billing?status=paid_or_open',
              ],
              [
                'Tagihan Overdue',
                billsAndPayments?.overdue,
                tagihanOverdue,
                '#FFB4AC',
                '/s/billing?status=expired',
              ],
              [
                'Total Tagihan',
                billsAndPayments?.bills_total,
                tagihanDibayarOverdue,
                '#4BAEA0',
                '/s/billing?status=paid_or_open',
              ],
              [
                'Total Pemasukan',
                billsAndPayments?.income_total,
                totalPemasukan,
                '#EF6C57',
              ],
            ].map(([title, value, img, bg, link], k) => (
              <div
                css={css`
                  ${topCardCSS} background-color: ${bg}
                `}
                key={`${k + 1}`}
                style={{
                  width: ['xs'].includes(width) && '100%',
                  marginBottom: ['xs'].includes(width) && 8,
                }}
              >
                <span>
                  <img src={img} alt="" />
                  {title}
                </span>
                <p>{value}</p>
                {title !== 'Total Pemasukan' && (
                  <a onClick={() => history.push(link)}>
                    Lihat Detail
                    <img src={chevronSVG} alt="" />
                  </a>
                )}
              </div>
            ))}
          </div>
          <div className="full">
            <Table5Ticket />
          </div>
          <Box
            style={{
              background: '#FFFFFF',
              borderRadius: '10px',
              height: '53px',
              width: '100%',
              padding: '0px 18px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 20,
            }}
          >
            <Typography>Penjualan Per-Periode</Typography>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  label="Advanced keyboard"
                  value={dates}
                  open={openDate}
                  onChange={(newValue) => {
                    setDates([
                      newValue?.[0]?.toISOString(),
                      newValue?.[1]?.toISOString(),
                    ])
                    setOpenDate(false)
                  }}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <div
                        onClick={() => {
                          setOpenDate(true)
                        }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          background: '#ECF2F5',
                          borderRadius: '10px',
                          padding: '5px 11px',
                        }}
                      >
                        <img src={calendarSVG} alt="calendar" />
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 10,
                            marginRight: 7,
                          }}
                        >
                          <input
                            style={{
                              fontFamily: 'Nunito Sans',
                              fontStyle: 'normal',
                              fontWeight: 'normal',
                              fontSize: '14px',
                              lineHeight: '19px',
                              background: 'unset',
                              outline: 'none',
                              border: 'unset',
                              color: '#7B7B7B',
                              width: 80,
                            }}
                            ref={startProps.inputRef}
                            {...startProps.inputProps}
                          />
                          <Box sx={{ mx: 1 }}> - </Box>
                          <input
                            style={{
                              fontFamily: 'Nunito Sans',
                              fontStyle: 'normal',
                              fontWeight: 'normal',
                              fontSize: '14px',
                              lineHeight: '19px',
                              background: 'unset',
                              outline: 'none',
                              border: 'unset',
                              color: '#7B7B7B',
                              marginRight: 7,
                              width: 80,
                            }}
                            ref={endProps.inputRef}
                            {...endProps.inputProps}
                          />
                        </div>
                        <img src={downSVG} alt="calendar" />
                      </div>
                    </React.Fragment>
                  )}
                />
              </LocalizationProvider>
            </div>
          </Box>
          <div className="parent-chart">
            {[
              [
                'Lead',
                leadsData?.total,
                leadSVG,
                '#9873B9',
                `/s/billing?start_date=${dates[0]}&end_date=${dates[1]}`,
              ],
              [
                'Paid',
                leadsData?.paid,
                paidSVG,
                '#E66A57',
                `/s/billing?start_date=${dates[0]}&end_date=${dates[1]}&status=paid`,
              ],
              [
                'User Baru',
                userInvoices?.new_user,
                newUserSVG,
                '#5DAFA0',
                '/s/user?user_status=new_user',
                userInvoices?.new_user_amount,
              ],
            ].map(([title, value, img, bg, link, omset], k) => (
              <div key={`${k + 1}`} className="chart-item">
                <div className="detail-chart">
                  <span style={{ color: bg }}>
                    <img src={img} alt="" />
                    {title}
                  </span>
                  <p style={{ color: bg }}>{value}</p>
                  <a style={{ color: bg }} onClick={() => history.push(link)}>
                    Lihat Detail
                    <ChevronIcon color={bg} />
                  </a>
                </div>
                {omset ? (
                  <div className="omset-chart">
                    <span style={{ color: bg }}>
                      <img src={img} alt="" />
                      Omset
                    </span>
                    <p style={{ color: bg, fontSize: 20 }}>
                      Rp.{' '}
                      {parseInt(omset, 10).toLocaleString()?.replace(/,/g, '.')}
                    </p>
                  </div>
                ) : ''}
              </div>
            ))}
          </div>
          <div className="section-three">
            <div className="parent-table">
              {ongoingIncome && (
                <Bar
                  data={ongoingIncome
                    ?.filter((i) => i?.id === 'paid')?.[0]
                    ?.data?.map((d) => ({ date: d?.x, omset: d?.y }))}
                  title={`Omset ${dayjs(dates?.[0]).format('MMMM YYYY')}`}
                  subTitle="Total Omset"
                  indexBy="date"
                  keys={['omset']}
                  fn={(value) => `Rp. ${thousandFormat(value)}`}
                />
              )}
            </div>
            <div className="parent-side">
              {[
                [
                  'Pelanggan',
                  userInvoices?.user_customer,
                  pelangganSVG,
                  '#E66A57',
                  `/s/user?user_status=user_customer&start_date=${dates?.[0]}&end_date=${dates?.[1]}`,
                  userInvoices?.user_customer_amount,
                ],
                [
                  'User 5 Hari Lagi Expired',
                  usersData?.expired_soon,
                  userExpiredSVG,
                  '#9873B9',
                  '/s/billing?condition=expired_soon',
                ],
                [
                  'Total User Tidak Lanjut',
                  usersData?.not_continue,
                  userNonActiveSVG,
                  '#5DAFA0',
                  '/s/user?user_status=not_continue',
                ],
              ].map(([title, value, img, bg, link, omset], k) => (
                <div key={`${k + 1}`} className="chart-item">
                  <div
                    className="detail-chart"
                    style={{ width: omset ? '50%' : '100%' }}
                  >
                    <span style={{ color: bg }}>
                      <img src={img} alt="" />
                      {title}
                    </span>
                    <p style={{ color: bg }}>{value}</p>
                    <a style={{ color: bg }} onClick={() => history.push(link)}>
                      Lihat Detail
                      <ChevronIcon color={bg} />
                    </a>
                  </div>
                  {omset ? (
                    <div className="omset-chart">
                      <span style={{ color: bg }}>
                        <img src={img} alt="" />
                        Omset
                      </span>
                      <p style={{ color: bg, fontSize: 20 }}>
                        Rp.{' '}
                        {parseInt(omset, 10)
                          .toLocaleString()
                          ?.replace(/,/g, '.')}
                      </p>
                    </div>
                  ) : ''}
                </div>
              ))}
            </div>
          </div>
          <div style={{ marginBottom: 20 }}>
            <Table10User />
          </div>
          <div style={{ marginBottom: 20 }}>
            {ongoingIncome && (
              <Line
                data={ongoingIncome.filter((i) => i?.id !== 'paid')}
                title={`Grafik Tagihan Bulan ${dayjs(dates?.[0]).format(
                  'MMMM YYYY'
                )}`}
                subTitle="Total Tagihan (Juta)"
                fn={(value) => `Rp. ${thousandFormat(value)}`}
              />
            )}
          </div>
          <div style={{ marginBottom: 20 }}>
            {ongoingUsers && (
              <Line
                data={ongoingUsers}
                title={`Grafik Total User Bulan ${dayjs(dates?.[0]).format(
                  'MMMM YYYY'
                )}`}
                subTitle="Jumlah User"
                fn={(value) => `${value} User`}
              />
            )}
          </div>
        </div>
      </Layout>
      <Form
        type="ticket-edit"
        title={<FormTitle />}
        defaultValues={{}}
        onSave={update}
      />
    </Fragment>
  )
}

export default Dashboard
