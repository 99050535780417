import { connect } from 'react-redux'
import { openDialog } from '@lib/redux/slice/ui'
import BaseShowButton from '@components/DataTable/button/Show'

function ShowButton(props) {
  const { openForm, data } = props
  return (
    <BaseShowButton
      onClick={() => openForm(data)}
    />
  )
}

const mapDispatch = {
  openForm: (data) => openDialog({
    type: 'form.dialog.show',
    data,
  }),
}
const connector = connect(null, mapDispatch)
const ConnectedShowButton = connector(ShowButton)
export default ConnectedShowButton
