/* eslint-disable no-console */
/** @jsxImportSource @emotion/react */

import { forwardRef } from 'react'
import { css } from '@emotion/react'
// import mailSVG from '@assets/auth/mail.svg'
import EmailIcon from '@material-ui/icons/EmailOutlined'
import Input from './Input'

const emailCSS = css`
  position: absolute;
  left: 79px;
  top: 305px;
`

const Email = forwardRef((props, ref) => {
  const {
    name, error, placeholder, after,
    className,
  } = props
  let { before } = props

  before = before || (() => <EmailIcon style={{ color: '#6a6e75' }} />)
  return (
    <Input
      name={name}
      error={error}
      ref={ref}
      before={before}
      after={after}
      css={emailCSS}
      className={className}
      placeholder={placeholder}
    />
  )
})

export default Email
