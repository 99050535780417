/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Link } from 'react-router-dom'
import Background from './common/Background'
import Box from './common/Box'
import Logo from './common/Logo'

const logoCSS = css`
  position: absolute;
  top: 56px;
  left: 269px;
`

const titleCSS = css`
  position: absolute;
  left: 0;
  right: 0;
  top: 175px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 170%;

  /* identical to box height, or 41px */
  text-align: center;

  color: #1A253B;
`

const subTitleCSS = css`
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 465px;
  top: 250px;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 196.4%;

  /* or 27px */
  text-align: center;
`

const linkCSS = css`
  position: absolute;
  left: 0;
  right: 0;
  width: 198px;
  margin: 0 auto;
  bottom: 53px;

  text-decoration: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 196.4%;

  /* or 27px */
  text-align: center;
`

const titleText = 'Email berhasil dikirim!'
const subTitleText = 'Kami telah mengirimkan tautan '
                      + 'untuk mengatur ulang kata sandi. '
                      + 'Mohon periksa email Anda.'
const linkText = 'Kembali ke Halaman Utama'

function LinkSent() {
  return (
    <Background>
      <Box width={675} height={412}>
        <Logo css={logoCSS} />
        <p css={titleCSS}>
          { titleText }
        </p>
        <p css={subTitleCSS}>
          { subTitleText }
        </p>
        <Link
          css={linkCSS}
          to="/login"
        >
          { linkText }
        </Link>
      </Box>
    </Background>
  )
}

export default LinkSent
