import { Fragment } from 'react'
import Delete from '@components/DataTable/button/Delete'
import Suspend from './Suspend'
import Show from './Show'

const action = (hooks) => {
  hooks.visibleColumns.push((columns) => [
    ...columns,
    {
      id: 'activation_status_display',
      Header: () => (<div>Status</div>),
      Cell: ({ row: { original: data } }) => (
        <span style={{ color: data.activation_status === 'blocked' ? '#E82A63' : '#4BAEA0' }}>
          { data.activation_status === 'blocked' ? 'Suspended' : 'Aktif' }
        </span>
      ),
    },
    {
      id: 'action',
      Header: () => (<div>Aksi</div>),
      Cell: ({ row: { original: data } }) => (
        <Fragment>
          <Suspend data={data} />
          <Show data={data} />
          {
            false && (<Delete onClick={() => null} />)
          }
        </Fragment>
      ),
    },
  ])
}

export default action
