import { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import PageTitle from '@components/PageTitle'
import Layout from '@layout/Main'
import TableBilling from './TableBilling'

function AffiliateComission() {
  return (
    <Fragment>
      <Helmet>
        <title>
          SQUIDS :: Affiliate Withdraw
        </title>
      </Helmet>
      <PageTitle>
        Affiliate Withdraw
      </PageTitle>
      <Layout>
        <TableBilling />
      </Layout>
    </Fragment>
  )
}

export default AffiliateComission
