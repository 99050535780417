/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
import { useMemo, useState } from 'react'
import qs from 'qs'
import { useHistory, useLocation } from 'react-router'
import { deleteApi, indexApi, showApi } from '../api'

const billingColumn = [
  {
    name: 'affiliate_user_name',
    label: 'Nama Affiliate',
  },
  {
    name: 'created_at',
    label: 'Tanggal Request',
  },
  {
    name: 'amount',
    label: 'Amount Withdraw',
  },
  {
    name: 'status',
    label: 'Status',
  },
]

const dummy = [
  {
    id: 10,
    name: 'asep',
    store: 'toko asep',
    package: 'paket asep',
    bill: 'Rp. 5.000.000',
    period: 'Maret 2021',
    due_date: '31 Maret 2021',
    status: 'Pending',
  },
  {
    id: 11,
    name: 'eneng',
    store: 'toko eneng',
    package: 'paket eneng',
    bill: 'Rp. 5.000.000',
    period: 'Maret 2021',
    due_date: '31 Maret 2021',
    status: 'Pending',
  },
]

const useTableState = () => {
  const location = useLocation()
  const history = useHistory()
  const [queryString, setQueryString] = useState()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [total, setTotal] = useState(10)
  const [data, setData] = useState([])
  const column = billingColumn
  const [loading, setLoading] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [showData, setShowData] = useState()
  const [openDialog, setOpenDialog] = useState(false)
  const [alertDialog, setAlertDialog] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [deleteId, setDeleteId] = useState()
  const collection = useMemo(() => data, [data])
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const queryPush = (query) => {
    const newQuery = { ...queryString, ...query }
    setQueryString(newQuery)
    history.push({ path: location.pathname, search: qs.stringify(newQuery) })
  }

  const queryRemove = (key) => {
    const newQuery = { ...queryString }

    if (typeof key === 'string') {
      delete newQuery[key]
    } else if (Array.isArray(key)) {
      key.map((item) => delete newQuery[item])
    }

    setQueryString(newQuery)
    history.push({ path: location.pathname, search: qs.stringify(newQuery) })
  }

  const getIndex = async () => {
    setData([])
    try {
      setLoading(true)
      console.log(queryString)
      const { status, data } = await indexApi(query)
      if (status === 'ok') {
        setLoading(false)
        setData(data?.data)
        setTotal(data?.pagination?.total_pages)
      } else {
        setLoading(false)
      }
    } catch {
      setLoading(false)
    }
  }

  const getShow = async (id) => {
    setShowData()
    try {
      setShowLoading(true)
      const { status, data } = await showApi(id)
      if (status === 'ok') {
        setShowLoading(false)
        setShowData(data?.data)
      }
    } catch {
      setShowLoading(false)
    }
  }

  const deleteData = async (id) => {
    try {
      setDeleteLoading(true)
      const { status, data } = await deleteApi(id)
      if (status === 'ok') {
        setDeleteLoading(false)
        getIndex()
        setAlertDialog(false)
      }
    } catch {
      setDeleteLoading(false)
    }
  }

  return {
    queryString,
    setQueryString,
    page,
    setPage,
    perPage,
    setPerPage,
    queryPush,
    column,
    data,
    getIndex,
    queryRemove,
    loading,
    total,
    setTotal,
    collection,
    getShow,
    showData,
    setShowData,
    showLoading,
    openDialog,
    setOpenDialog,
    alertDialog,
    setAlertDialog,
    deleteData,
    deleteLoading,
    setDeleteLoading,
    deleteId,
    setDeleteId,
  }
}

export default useTableState
