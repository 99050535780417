import { useMutation, useQueryClient } from 'react-query'
import { deleteSaasMootaSetups } from '@lib/axios/moota'

const useDelete = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (id) => deleteSaasMootaSetups(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('saas-moota')
      },
    }
  )
}

export default useDelete
