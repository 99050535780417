/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit'

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notifications: [],
  },
  reducers: {
    enqueue: ({ ...state }, { payload }) => ({
      ...state,
      notifications: payload,
    }),
    close: ({ ...state }, { payload }) => ({
      ...state,
      notifications: state.notifications.map((notification) => (
        (!payload?.key || payload?.key === notification.key)
          ? { ...notification, dismissed: true }
          : { ...notification }
      )),
    }),
    remove: ({ ...state }) => ({
      ...state,
    }),
    clear: () => ({ notifications: [] }),
  },
})

export const {
  enqueue,
  close,
  remove,
  clear,
} = notificationSlice.actions

export default notificationSlice.reducer
