/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import ButtonBase from '@material-ui/core/ButtonBase'
import { Link } from 'react-router-dom'

const CSS = css`
  width: 173px;
  height: 160px;
  background: #FFFFFF;
  border: 1px solid #ECF2F5;
  box-sizing: border-box;
  border-radius: 5px;
  margin-right: 13px;
  span:first-of-type {
    position: absolute;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    bottom: 14px;
  }
  & > *:nth-of-type(1) img {
    position: absolute;
    bottom: 40px;
  }
  & > *:nth-of-type(2) img {
    position: absolute;
    bottom: 45px;
  }
  &:hover {
    background: rgba(85, 111, 181, 0.17);
  }
`

function Button(props) {
  const {
    onClick: handleClick, to, imgSrc, title,
  } = props
  return (
    <ButtonBase
      css={CSS}
      to={to}
      onClick={handleClick}
      component={to ? Link : 'button'}
    >
      <img src={imgSrc} alt="" />
      <span>{title}</span>
    </ButtonBase>
  )
}

export default Button
