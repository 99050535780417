import { deleteVoucher } from '@lib/axios/voucher'
import { useMutation, useQueryClient } from 'react-query'

const useDelete = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (id) => deleteVoucher(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('saas-voucher')
      },
    }
  )
}

export default useDelete
