/** @jsxImportSource @emotion/react */

import Dropdown from '@components/filter/Dropdown'

function Status(props) {
  const {
    onSelected: handleSelected,
    className,
    defaultValue,
  } = props
  return (
    <Dropdown
      onSelected={handleSelected}
      items={[
        ['Pilih Status', ''],
        ['aktif', 'false'],
        ['suspended', 'true'],
      ]}
      defaultValue={defaultValue || ''}
      className={className}
    />
  )
}

export default Status
