import { useRef } from 'react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import AdapterDayjs from '@material-ui/lab/AdapterDayjs'
import { ThemeProvider } from '@material-ui/core'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import { SnackbarProvider } from 'notistack'

import Notifier from '@components/Notifier'
import Routes from '@routes'
import { store, persistor } from '@lib/redux'
import theme from '@lib/theme'
import queryClient from '@lib/query/client'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import AuthSubscriber from './AuthSubscriber'

function App() {
  const storeRef = useRef(store)
  return (
    <ReduxProvider store={storeRef.current}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Notifier />
              {/* <CssBaseline /> */}
              <QueryClientProvider client={queryClient}>
                <AuthSubscriber />
                <ReactQueryDevtools initialIsOpen={false} />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Routes />
                </MuiPickersUtilsProvider>
              </QueryClientProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </PersistGate>
    </ReduxProvider>
  )
}

export default App
