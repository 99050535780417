/* eslint-disable radix */
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Pagination,
  PaginationItem,
  Paper,
  Table,
  TableContainer,
  TableFooter,
  Typography,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import qs from 'qs'
import AlertDialog from '@components/AlertDialog'
import useStyles from './tableBilling-jss'
import TableHeader from './TableHeader'
import { TableProvider } from './TableProvider'
import TableToolbar from './TableToolbar'
import useTableState from './useTableState'
import TableBody from './TableBody'
import FormDialog from './FormDialog'
import { getPlatformConfigApi, postPlatformConfigApi } from '../api'

const useQuery = () => new URLSearchParams(useLocation().search)

const TableBilling = () => {
  const classes = useStyles()
  const state = useTableState()
  const location = useLocation()
  const query = useQuery()
  const [autoApprove, setAutoApprove] = React.useState(false)

  useEffect(() => {
    (async () => {
      const res = await getPlatformConfigApi()
      const data = res.data.data?.auto_approve_affiliate_withdraw
      setAutoApprove(data)
    })()
  }, [])

  useEffect(() => {
    state.getIndex()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const onClickPage = (item) => {
    const queryString = qs.parse(location.search, { ignoreQueryPrefix: true })
    const newQs = { ...queryString, page: item.page }
    const page1 = qs.stringify({ ...queryString, page: 1 }, { encode: false })

    return `${location.pathname}?${
      item.page === 1 ? page1 : qs.stringify(newQs, { encode: false })
    }`
  }
  const onChangeAutoApprove = async () => {
    try {
      await postPlatformConfigApi({
        platform_configuration: {
          auto_approve_affiliate_withdraw: !autoApprove,
        },
      })
        .then(() => {
          setAutoApprove(!autoApprove)
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <TableProvider value={state}>
      <TableContainer component={Paper} className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h1" className={classes.txHeader} gutterBottom>
            Data Request Withdraws
          </Typography>
          <FormControlLabel
            control={<Checkbox size="small" checked={autoApprove} />}
            label="Auto Approve Withdraw"
            onChange={onChangeAutoApprove}
          />
        </div>
        <TableToolbar />
        <Table padding="checkbox" style={{ marginLeft: 20, marginRight: 20 }}>
          <TableHeader />
          <TableBody />
        </Table>
        {state.loading && (
          <CircularProgress
            style={{ alignSelf: 'center', margin: 8 }}
            size={24}
          />
        )}
        {state?.data?.length ? (
          <TableFooter className={classes.tFooter}>
            <Pagination
              style={{ alignSelf: 'center', marginTop: 8 }}
              count={state.total}
              color="primary"
              page={
                query.get('page') === null ? 1 : parseInt(query.get('page'))
              }
              renderItem={(item) => (
                <PaginationItem
                  component={Link}
                  to={() => onClickPage(item)}
                  {...item}
                />
              )}
            />
          </TableFooter>
        ) : (
          <Typography className={classes.emptyDataMessage}>
            Data tidak ditemukan
          </Typography>
        )}
      </TableContainer>
      <FormDialog
        open={state.openDialog}
        onClose={() => state.setOpenDialog(false)}
      />
      <AlertDialog
        positive="Ya"
        negative="Tidak"
        title="Hapus Data"
        loading={state.deleteLoading}
        text="Anda yakin akan menghapus data ini?"
        open={state.alertDialog}
        onResponseNegative={() => state.setAlertDialog(false)}
        onResponsePositive={() => state.deleteData(state.deleteId)}
        disabledButton={state.deleteLoading}
      />
    </TableProvider>
  )
}

export default TableBilling
