/** @jsxImportSource @emotion/react */
/* eslint-disable no-unused-vars */

import { forwardRef, Fragment } from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import { css } from '@emotion/react'

const inputCSS = css`
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .02);
    background-color: #F6F9FB;
  &.Mui-focused {
    border: 1px solid rgba(0, 0, 0, .1);
  }
  & .MuiInputBase-input {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 43px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 10px;
    height: 45px;
  }
  & .MuiSelect-select.MuiSelect-select {
    padding-left: 10px;
  }
  & .MuiSelect-select:focus {
    background-color: #F6F9FB;
  }
  & .MuiInputAdornment-root {
    padding: 0 10px;
  }
  .MuiInputAdornment-positionStart {
    margin-right: 0;
  }
  .MuiInputAdornment-positionEnd {
    margin-left: 0;
  }
`
const formControlCSS = css`
  &.MuiFormControl-root {
    width: 100%;
    padding-top: 18px;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #676767;
  }
  & .MuiFormLabel-root.Mui-focused {
    color: #676767;
  }
  & .MuiFormHelperText-root {
    margin: 5px 5px 0 5px;
    font-size: 10px;
    line-height: 1;
  }
`

const Text = forwardRef((props, ref) => {
  const {
    label, name, className, error, type,
  } = props
  return (
    <FormControl fullWidth css={formControlCSS} className={className}>
      <InputLabel disableAnimation shrink>
        {
          `${label} ` || (
            <Fragment>
              &nbsp;
            </Fragment>
          )
        }
      </InputLabel>
      <InputBase inputProps={{ autoComplete: 'off', spellCheck: 'false' }} inputRef={ref} name={name} css={inputCSS} type={type || 'text'} {...props} />
      <FormHelperText error={!!error}>
        {
          error || (
            <Fragment>
              &nbsp;
            </Fragment>
          )
        }
      </FormHelperText>
    </FormControl>
  )
})

export default Text
