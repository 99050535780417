/* eslint-disable import/prefer-default-export */
import { adminApiBaseURL } from '@lib/env'
import Axios from 'axios'
import qs from 'qs'
import { setBearerToken, handleUnauthorized } from './interceptor'

const api = Axios.create({
  baseURL: adminApiBaseURL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
})
api.defaults.headers.common.Accept = 'application/json'
api.interceptors.request.use(setBearerToken)
api.interceptors.response.use((r) => r, handleUnauthorized)

export const index = (start_date, end_date) => api.get(`dashboards?start_date=${start_date}&end_date=${end_date}`)
