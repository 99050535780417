/* eslint-disable import/prefer-default-export */
import axios from 'axios'
import { adminApiBaseURL } from '@lib/env'
import { setBearerToken, handleUnauthorized, normalizeResponse } from '@lib/axios/interceptor'

const api = axios.create({
  baseURL: adminApiBaseURL,
})

api.defaults.headers.common.Accept = 'application/json'
api.interceptors.request.use(setBearerToken)
api.interceptors.response.use(normalizeResponse, handleUnauthorized)

const path = '/affiliate_commissions'

export const indexApi = (params) => api.get(`${path}`, { params })
export const showApi = (id) => api.get(`${path}/${id}`)
export const deleteApi = (id) => api.delete(`${path}/${id}`)
