/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import useWidth from '@hooks/useWidth'

const cardCSS = css`
  width: 100%;
  margin: 30px;
  background: #FFFFFF;
  border: 1px solid #ECF2F5;
  box-sizing: border-box;
  height: fit-content;
  overflow: hidden;
  h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    margin-bottom: 23px;
    /* identical to box height, or 34px */
    margin-left: 33px;
    color: #1A253B;
  }
`

function Card(props) {
  const { children, className } = props
  const width = useWidth()
  return (
    <div css={cardCSS} className={className} style={{ marginLeft: ['xs'].includes(width) && 16, marginRight: ['xs'].includes(width) && 16 }}>
      { children }
      <div style={{ display: 'block', content: '', clear: 'both' }} />
    </div>
  )
}

export default Card
