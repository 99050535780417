import { useQuery } from 'react-query'
import { getSaasRoles } from '@lib/axios/admin'
import { useMemo } from 'react'

function useIndex() {
  const { status, data } = useQuery(
    ['saas-admin-roles'],
    () => getSaasRoles(),
    {
      retry: false,
      staleTime: Infinity,
    }
  )
  return useMemo(() => ((status === 'success') ? data?.data : []), [status, data])
}

export default useIndex
