import { useQuery } from 'react-query'
import { indexSaasPaymentSetups } from '@lib/axios/pg'

function useIndex() {
  const result = useQuery(
    ['saas-pg'],
    () => indexSaasPaymentSetups(),
    {
      retry: false,
      staleTime: Infinity,
    }
  )
  return result
}

export default useIndex
