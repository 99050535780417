/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'

const CSS = css`
    padding: 4px;
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    background-color: #F6F9FB;
    padding: 4px 10px;
    border: 1px solid rgba(0, 0, 0, .02);
    border-radius: 5px;
    width: 100%;
    color: #676767;
    min-width: 110px;
`

function Text(props) {
  const {
    placeholder,
    className,
    onChange: handleChange,
    defaultValue,
    style,
  } = props
  return (
    <input
      value={defaultValue || ''}
      className={className}
      css={CSS}
      type="text"
      placeholder={placeholder}
      onChange={(e) => handleChange(e?.target?.value)}
      autoComplete="off"
      spellCheck={false}
      style={style}
    />
  )
}

export default Text
