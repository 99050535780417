import { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import PageTitle from '@components/PageTitle'
import Layout from '@layout/Account'
import Card from '@components/card/Account'
import Form from './Form'

function Account() {
  return (
    <Fragment>
      <Helmet>
        <title>
          SQUIDS :: Account
        </title>
      </Helmet>
      <PageTitle>
        Akun Saya
      </PageTitle>
      <Layout>
        <Card>
          <Form />
        </Card>
      </Layout>
    </Fragment>
  )
}

export default Account
