import { isDev } from '@lib/env'
import { showDevMenu } from '@lib/constant'

import usersSVG from '@assets/menu/users.svg'
import trendingUpSVG from '@assets/menu/trendingUp.svg'
import userCheckSVG from '@assets/menu/userCheck.svg'
import packageSVG from '@assets/menu/package.svg'
import subscribeSVG from '@assets/menu/subscribe.svg'
import integrationSVG from '@assets/menu/integration.svg'
import storeSVG from '@assets/menu/store.svg'
import billingSVG from '@assets/menu/billing.svg'
import ticketingSVG from '@assets/menu/ticketing.svg'
import withdrawSVG from '@assets/menu/withdraw.svg'
import voucherSVG from '@assets/menu/voucher.svg'
import voucherUsageSVG from '@assets/menu/voucherUsage.svg'

import usersActiveSVG from '@assets/menu/active/users.svg'
import trendingUpActiveSVG from '@assets/menu/active/trendingUp.svg'
import userCheckActiveSVG from '@assets/menu/active/userCheck.svg'
import packageActiveSVG from '@assets/menu/active/package.svg'
import subscribeActiveSVG from '@assets/menu/active/subscribe.svg'
import integrationActiveSVG from '@assets/menu/active/integration.svg'
import storeActiveSVG from '@assets/menu/active/store.svg'
import billingActiveSVG from '@assets/menu/active/billing.svg'
import ticketingActiveSVG from '@assets/menu/active/ticketing.svg'
import withdrawActiveSVG from '@assets/menu/active/withdraw.svg'
import themeActiveSVG from '@assets/menu/active/themeActive.svg'
import themeSVG from '@assets/menu/theme.svg'
import bankSVG from '@assets/menu/bank.svg'
import bankAccountSVG from '@assets/menu/account-bank.svg'
import dashboardPagesSVG from '@assets/menu/dashboardPages.svg'
import dashboardPagesActiveSVG from '@assets/menu/active/dashboardPages.svg'
import voucherActiveSVG from '@assets/menu/active/voucher.svg'
import voucherUsageActiveSVG from '@assets/menu/active/voucherUsage.svg'
import courierSVG from '@assets/menu/courier.svg'
import courierActiveSVG from '@assets/menu/active/courier.svg'
import { securedRoutePath } from './constant'

const rawMenu = [
  ['dashboard', [trendingUpSVG, trendingUpActiveSVG], `${securedRoutePath}`],
  ['manajemen user', [usersSVG, usersActiveSVG], `${securedRoutePath}/user`],
  [
    'manajemen admin',
    [userCheckSVG, userCheckActiveSVG],
    `${securedRoutePath}/admin`,
  ],
  [
    'manajemen paket',
    [packageSVG, packageActiveSVG],
    `${securedRoutePath}/paket`,
  ],
  [
    'kelola voucher',
    [voucherSVG, voucherActiveSVG],
    `${securedRoutePath}/voucher`,
  ],
  [
    'pemakaian voucher',
    [voucherUsageSVG, voucherUsageActiveSVG],
    `${securedRoutePath}/vouchèr-usage`,
  ],
  [
    'manajemen subscribe',
    [subscribeSVG, subscribeActiveSVG],
    `${securedRoutePath}/subscribe`,
  ],
  ['manajemen toko', [storeSVG, storeActiveSVG], `${securedRoutePath}/toko`],
  [
    'manajemen tagihan',
    [billingSVG, billingActiveSVG],
    `${securedRoutePath}/billing`,
  ],
  [
    'manajemen tiket',
    [ticketingSVG, ticketingActiveSVG],
    `${securedRoutePath}/tiket`,
  ],
  ['manajemen bank', [bankSVG, bankSVG], `${securedRoutePath}/bank`],
  ['tema toko online', [themeSVG, themeActiveSVG], `${securedRoutePath}/tema`],
  [
    'affiliate withdraws',
    [withdrawSVG, withdrawActiveSVG],
    `${securedRoutePath}/affiliate-withdraw`,
  ],
  [
    'affiliate commission',
    [withdrawSVG, withdrawActiveSVG],
    `${securedRoutePath}/affiliate-comission`,
  ],
  [
    'integrasi',
    [integrationSVG, integrationActiveSVG],
    `${securedRoutePath}/integrasi`,
  ],
  [
    'setup akun bank',
    [bankAccountSVG, bankAccountSVG],
    `${securedRoutePath}/setup-bank-account`,
  ],
  [
    'setup akun xenplatform',
    [bankAccountSVG, bankAccountSVG],
    `${securedRoutePath}/setup-xenplatform`,
  ],
  [
    'manajemen kurir',
    [courierSVG, courierActiveSVG],
    `${securedRoutePath}/courier-management`,
  ],
  [
    'setup store dashboard page',
    [dashboardPagesSVG, dashboardPagesActiveSVG],
    `${securedRoutePath}/setup-store-dashboard-page`,
  ],
]

const activeMenu = [
  'dashboard',
  'manajemen user',
  'manajemen admin',
  'manajemen paket',
  'kelola voucher',
  'manajemen toko',
  'pemakaian voucher',
  'manajemen tiket',
  'manajemen bank',
  'tema toko online',
  'integrasi',
  'affiliate withdraws',
  'affiliate commission',
  'manajemen tagihan',
  'setup akun bank',
  'setup akun xenplatform',
  'setup store dashboard page',
  'manajemen kurir',
]
const devMenu = [['dev', integrationSVG, `${securedRoutePath}/dev`]]

const menus = rawMenu.filter(([title]) => activeMenu.includes(title))

export default [...menus, ...(isDev && showDevMenu ? devMenu : [])]
