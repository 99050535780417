/** @jsxImportSource @emotion/react */

import { useMemo, Fragment, forwardRef } from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { Controller } from 'react-hook-form'
import Field from './Field'
import css from './css'

const Renderer = forwardRef((props, ref) => {
  const {
    onChange: handleChange,
    radios,
    name,
    value: inputValue,
  } = props
  const value = useMemo(() => {
    let next = inputValue
    if (typeof inputValue === 'boolean') {
      next = inputValue ? 'true' : 'false'
    }
    return next
  }, [inputValue])
  return (
    <Fragment>
      <Field
        onChange={handleChange}
        value={value.toString()}
        inputRef={ref}
        radios={radios}
        name={name}
      />
    </Fragment>
  )
})

function Radio(props) {
  const {
    label, name, className, control, radios, mapper,
  } = props

  return (
    <FormControl css={css} className={className}>
      <FormLabel>{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ ...renderProps }) => (
          <Renderer {...renderProps} radios={radios} mapper={mapper} />
        )}
      />
    </FormControl>
  )
}

export default Radio
