/* eslint-disable camelcase */

import { useMutation, useQueryClient } from 'react-query'
import useSuccess from '@hooks/useSuccess'
import useCloseDialog from '@hooks/useCloseDialog'
import { connectXenPlatforms } from '@lib/axios/xenplatform'

const useConnect = () => {
  const showSuccess = useSuccess('Sukses', 'Sukses Menambah Akun XenPlatform')
  const closeDialog = useCloseDialog()
  const queryClient = useQueryClient()
  const mutation = useMutation(
    (mdata) => connectXenPlatforms(
      mdata.xenplatform_id
    ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('saas-xenplatforms')
      },
    }
  )

  return (data) => mutation.mutate(data, {
    onError: () => closeDialog(),
    onSuccess: () => {
      showSuccess()
    },
  })
}

export default useConnect
