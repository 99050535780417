/** @jsxImportSource @emotion/react */
/* eslint-disable no-console */

import useIndex from '@lib/query/voucher/useIndex'
import { Fragment, useMemo } from 'react'
import { css } from '@emotion/react'
import DataTable from '@components/DataTable'
import PageTitle from '@components/PageTitle'
import { Helmet } from 'react-helmet'
import Layout from '@layout/Main'
import Card from '@components/card/Base'
import { useHistory } from 'react-router-dom'

import Tambah from '@components/button/Tambah'

import action from './action'
import Toolbar from './Toolbar'
import PackageDetail from './PackageDetail'

const CSS = css`
  thead tr > *:last-of-type {
    div {
      text-align: center;
    }
  }
  & tbody tr > *:last-of-type div {
    display: flex;
    justify-content: space-around;
  }
`

const addButtonCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > *:first-of-type {
    margin-left: 10px;
  }
`

const titleCSS = css`
  display: flex;
`
function VoucherManagement() {
  const history = useHistory()
  const onTableChange = () => null

  const {
    tableData, tableColumns, hiddenColumns, tablePagination,
  } = useIndex()

  return (
    <Fragment>
      <Helmet>
        <title>SQUIDS :: Kelola Voucher</title>
      </Helmet>
      <PageTitle>Kelola Voucher</PageTitle>
      <Layout>
        <Card>
          <div css={titleCSS}>
            <h3>Kelola Voucher</h3>
            <div css={addButtonCSS}>
              <Tambah
                type="create"
                title="Voucher"
                onClick={() => history.push('/s/voucher/new')}
              />
            </div>
          </div>
          <Toolbar />
          <DataTable
            css={CSS}
            data={tableData}
            columns={useMemo(() => tableColumns, [tableColumns])}
            hooks={action}
            hiddenColumns={hiddenColumns}
            pagination={tablePagination}
            onChange={onTableChange}
          />
          <PackageDetail />
        </Card>
      </Layout>
    </Fragment>
  )
}

export default VoucherManagement
