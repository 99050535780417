/* eslint-disable camelcase */

import { createSaasMootaSetups } from '@lib/axios/moota'
import { useMutation, useQueryClient } from 'react-query'
import useSuccess from '@hooks/useSuccess'
import useCloseDialog from '@hooks/useCloseDialog'

const useCreateAdmin = () => {
  const showSuccess = useSuccess('Sukses', 'Sukses Menambah Moota')
  const closeDialog = useCloseDialog()
  const queryClient = useQueryClient()
  const mutation = useMutation(
    (mdata) => createSaasMootaSetups(
      mdata.name,
      mdata.bank_name,
      mdata.account_number,
      mdata.personal_token
    ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('saas-moota')
      },
    }
  )

  return (data) => mutation.mutate(data, {
    onError: () => closeDialog(),
    onSuccess: () => {
      showSuccess()
    },
  })
}

export default useCreateAdmin
