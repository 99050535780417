/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable arrow-body-style */
/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react'
import Form from '@components/dialog/Form'
import { useForm } from 'react-hook-form'
import { css } from '@emotion/react'
import Radio from '@components/field/Radio'
import Text from '@components/field/Text'
import imageUpload from '@assets/field/upload-Image-image.svg'
import imageUploadThumnail from '@assets/field/upload-Image-thumnail.svg'
import useValue from './useValue'

const CSS = css`
  min-width: 829px;
  border-radius: 5px;
  .MuiDialogContent-root {
    padding: 32px;
  }
  .field-wrap {
    width: 381px;
    display: flex;
    flex-direction: column;
  }
  .field-wrap > div {
    margin-bottom: 10px;
  }
  & .limit > div {
    display: flex;
    margin-top: 10px;
  }
  & .limit .order {
    display: flex;
    justify-content: space-between;
  }
`

export default function Dialog(props) {
  const {
    type, title, onSave: save, defaultValues,
  } = props

  const { value, open } = useValue(defaultValues)
  const [image, setImage] = useState({ preview: '', raw: '' })
  const [image2, setImage2] = useState({ preview: '', raw: '' })

  const {
    handleSubmit, register, control, reset,
  } = useForm({
    defaultValues: value,
  })
  useEffect(() => {
    const options = {
      isDirty: false,
      touched: false,
      dirtyFields: false,
      errors: false,
    }
    if (open) {
      reset(value, options)
      setImage({ preview: '', raw: '' })
      setImage2({ preview: '', raw: '' })
      if (value['image.url']) {
        setImage2({ preview: value['image.url'], raw: '' })
      }
      if (value['thumbnail.url']) {
        setImage({ preview: value['thumbnail.url'], raw: '' })
      }
    }
  }, [value, reset, open])

  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      })
    }
  }

  const handleChange2 = (e) => {
    if (e.target.files.length) {
      setImage2({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      })
    }
  }

  return (
    <Form
      title={title}
      type={type}
      onSubmit={handleSubmit((data) => {
        // eslint-disable-next-line prefer-const
        let datas = data
        datas.thumbnail = image.raw
        datas.image = image2.raw
        save(datas)
      })}
      css={CSS}
    >
      <div className="limit">
        <div>
          <div className="field-wrap">
            {value?.id && (
              <input name="id" type="hidden" value={value.id} ref={register} />
            )}
            <Text label="Nama Tema" name="name" ref={register} />
            <Radio
              label="Status"
              name="status"
              control={control}
              ref={register}
              radios={[
                ['1', 'Aktif'],
                ['0', 'Tidak Aktif'],
              ]}
            />
            <label htmlFor="upload-button">
              {image.preview ? (
                <img src={image.preview} alt="" width="230px" />
              ) : (
                <img src={imageUploadThumnail} alt="" width="230px" />
              )}
            </label>
            <input
              type="file"
              id="upload-button"
              name="thumbnail"
              control={control}
              style={{ display: 'none' }}
              onChange={handleChange}
            />
          </div>
          <label htmlFor="upload-button-2">
            {image2.preview ? (
              <img
                src={image2.preview}
                alt=""
                style={{ marginLeft: 25 }}
                width="350px"
              />
            ) : (
              <img
                src={imageUpload}
                alt=""
                style={{ marginLeft: 25 }}
                width="350px"
              />
            )}
          </label>
          <input
            type="file"
            id="upload-button-2"
            name="image"
            control={control}
            style={{ display: 'none' }}
            onChange={handleChange2}
          />
        </div>
      </div>
    </Form>
  )
}
