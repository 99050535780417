/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
import { useMemo, useState } from 'react'
import qs from 'qs'
import { useHistory, useLocation } from 'react-router'
import { listStores } from '@lib/axios/store'

const useTableState = () => {
  const location = useLocation()
  const history = useHistory()
  const [queryString, setQueryString] = useState()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [total, setTotal] = useState(10)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const collection = useMemo(() => data, [data])

  const queryPush = (query) => {
    const newQuery = { ...queryString, ...query }
    setQueryString(newQuery)
    history.push({ path: location.pathname, search: qs.stringify(newQuery) })
  }

  const queryRemove = (key) => {
    const newQuery = { ...queryString }

    if (typeof key === 'string') {
      delete newQuery[key]
    } else if (Array.isArray(key)) {
      key.map((item) => delete newQuery[item])
    }

    setQueryString(newQuery)
    history.push({ path: location.pathname, search: qs.stringify(newQuery) })
  }

  const getIndex = async () => {
    setData([])
    try {
      setLoading(true)
      const querySearch = qs.parse(location.search, { ignoreQueryPrefix: true })
      const { status, data } = await listStores(querySearch)
      if (status === 'ok') {
        setLoading(false)
        setData(data?.data)
        setTotal(data?.pagination?.total_pages)
        setPage(data?.pagination?.current_page)
      } else {
        setLoading(false)
        if (querySearch?.csv || querySearch?.xlsx) {
          const downloadUrl = data?.data?.url || null
          delete querySearch.csv
          delete querySearch?.xlsx
          if (downloadUrl) window.open(`${downloadUrl}`, '_blank')
          history.push(`${location.pathname}?${qs.stringify(querySearch)}`)
        }
      }
    } catch {
      setLoading(false)
    }
  }

  return {
    queryString,
    setQueryString,
    page,
    setPage,
    perPage,
    setPerPage,
    queryPush,
    data,
    getIndex,
    queryRemove,
    loading,
    total,
    setTotal,
    collection,
  }
}

export default useTableState
