/* eslint-disable no-console */

import { Fragment } from 'react'
import Ubah from '@components/button/Ubah'
import Hapus from './Hapus'

const Cell = ({ row: { original: data } }) => (
  <Fragment>
    <Ubah data={data} type="edit" />
    <Hapus id={data.id} />
  </Fragment>
)

const action = (hooks) => {
  hooks.visibleColumns.push((columns) => [
    ...columns,
    {
      id: 'monthly_price_display',
      Header: () => <div>Harga Bulanan</div>,
      Cell: ({ row: { original: data } }) => (
        <span>
          {data?.omzet_based_billing ? (
            <Fragment>{data?.omzet_percentage} % dari omzet</Fragment>
          ) : (
            data.monthly_price
            && typeof data.monthly_price === 'string' && (
              <Fragment>
                Rp.{' '}
                {data.monthly_price
                  .split('.')[0]
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </Fragment>
            )
          )}
        </span>
      ),
    },
    {
      id: 'annual_price_display',
      Header: () => <div>Harga Tahunan</div>,
      Cell: ({ row: { original: data } }) => (
        <span>
          {data?.omzet_based_billing ? (
            <Fragment>{data?.omzet_percentage} % dari omzet</Fragment>
          ) : data.annual_price && typeof data.annual_price === 'string' && (
          <Fragment>
            Rp.{' '}
            {data.annual_price
              .split('.')[0]
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
          </Fragment>
          )}
        </span>
      ),
    },
    {
      id: 'affiliate_commission_display',
      Header: () => <div>Komisi Affiliate</div>,
      Cell: ({ row: { original: data } }) => (
        <span>
          {data?.affiliate_commission && (
            <Fragment>{data?.affiliate_commission} %</Fragment>
          )}
        </span>
      ),
    },
    {
      id: 'status_display',
      Header: () => <div>Status</div>,
      Cell: ({ row: { original: data } }) => (
        <span style={{ color: data.status ? '#4BAEA0' : '#E82A63' }}>
          {data.status ? 'Aktif' : 'Tidak Aktif'}
        </span>
      ),
    },
    {
      id: 'action',
      Header: () => <div>Aksi</div>,
      Cell,
    },
  ])
}

export default action
