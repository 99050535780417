/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import CssBaseline from '@material-ui/core/CssBaseline'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { connect, useSelector } from 'react-redux'
import { Fragment, useState } from 'react'
import { toggleSidebarOpen } from '@lib/redux/slice/ui'
import { Helmet } from 'react-helmet'
import useWidth from '@hooks/useWidth'

import Confirm from '@components/dialog/Confirm'
import Success from '@components/dialog/Success'
import Uncompleted from '@components/dialog/Uncompleted'

import Sidebar from '@components/Sidebar'
import Account from '@components/button/Account'
import Notification from '@components/button/Notification'
// import Search from '@components/Search'
import SearchIcon from '@material-ui/icons/Search'
import {
  Autocomplete, InputAdornment, TextField, CircularProgress,
} from '@material-ui/core'
import { useHistory } from 'react-router'
import { listStores } from '@lib/axios/store'

const layoutCSS = css`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  .title {
    flex: 1;
    color: black;
  }
  .notification-button.MuiIconButton-root {
    padding: 0;
    height: 37px;
    width: 37px;
    margin-right: 11px;
  }

  .search {

  }
`

const mainContentCSS = css`
  // width: calc(100vw - 278px) !important;
  flex: 1;
  display: flex;
  justify-content: center;
  // overflow: hidden;
  margin-top: 88px;
  overflow: auto;
`

const appBarCSS = css`
  // width: calc(100% - 278px) !important;
  margin-left: 278px;
  .MuiToolbar-root {
    min-height: 88px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #F6F9FB;
  }
`

function Main(props) {
  const { children, pageTitle } = props
  const history = useHistory()
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const mapDispatch = { toggleSidebarOpen }
  const mapState = ({ ui }) => ({ sidebarOpen: ui.sidebarOpen })
  const connector = connect(mapState, mapDispatch)
  const sidebarSelector = useSelector((state) => state?.ui?.sidebarOpen)
  const width = useWidth()

  const ToggleSidebarButton = connector(({ sidebarOpen, toggleSidebarOpen: handleToggle }) => (
    <Fragment>
      <Helmet>
        <body className={sidebarOpen ? 'sidebar-open' : 'sidebar-closed'} />
      </Helmet>
      <IconButton
        color="primary"
        onClick={() => handleToggle()}
        style={{ display: sidebarOpen && 'none' }}
      >
        <MenuIcon />
      </IconButton>
    </Fragment>
  ))

  const searchStore = async (e) => {
    try {
      setLoading(true)
      const { status, data: { data } } = await listStores({ email_or_name: e.target.value })
      if (status === 'ok') {
        setLoading(false)
        setOptions(data)
      } else {
        setOptions([])
      }
    } catch (error) {
      setLoading(false)
      setOptions([])
    }
  }

  return (
    <Fragment>
      <div css={layoutCSS} style={{ marginLeft: !sidebarSelector && '0' }}>
        <CssBaseline />
        <Sidebar />
        <AppBar
          position="fixed"
          css={appBarCSS}
          elevation={0}
          style={{
            width: sidebarSelector ? 'calc(100vw - 278px)' : '',
          }}
        >
          <Toolbar style={{ marginLeft: !sidebarSelector && '0', paddingLeft: !sidebarSelector && '0', paddingRight: !sidebarSelector && '0' }}>
            <ToggleSidebarButton />
            { !['xs'].includes(width) && <span className="title">{ pageTitle }</span> }
            <Autocomplete
              name="searchStore"
              options={options}
              loading={loading}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              onChange={(e, value, reason) => {
                if (reason === 'select-option') {
                  history.push(`/s/toko?email_or_name=${value.name}`)
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="search"
                  onChange={searchStore}
                  variant="standard"
                  placeholder="Cari..."
                  style={{
                    backgroundColor: '#ECF2F5',
                    borderRadius: 50,
                    width: ['xs'].includes(width) ? 200 : 300,
                    padding: 8,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>),
                    endAdornment: (
                      <Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </Fragment>
                    ),
                  }}
                />
              )}
            />
            <Notification />
            <Account />
          </Toolbar>
        </AppBar>
        <main css={mainContentCSS} style={{ width: sidebarSelector ? 'calc(100vw - 278px)' : '' }}>
          { children }
        </main>
      </div>
      <Confirm />
      <Success />
      <Uncompleted />
    </Fragment>
  )
}

const mapState = (state) => ({ pageTitle: state.ui.pageTitle })

const connector = connect(mapState)

const ConnectedMain = connector(Main)

export default ConnectedMain
