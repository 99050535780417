/* eslint-disable max-len */
/* eslint-disable no-shadow */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
  IconButton,
} from '@material-ui/core'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import React, {
  useContext, Fragment, useState, useEffect,
} from 'react'
import CloudUpload from '@material-ui/icons/Close'
import * as dayjs from 'dayjs'
import 'dayjs/locale/id'

import { api } from '@lib/axios/admin'

import { useSnackbar } from 'notistack'
import { TableContext } from './TableProvider'

const useStyles = makeStyles((theme) => ({
  fieldWrapper: {
    // flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    backgroundColor: '#F6F9FB',
    width: '100%',
  },
  col: {
    flex: 1,
    margin: theme.spacing(1),
  },
  container: {
    display: 'flex',
  },
}))

const BillingDetail = ({ open, onClose }) => {
  const classes = useStyles()
  const {
    invoice, showLoading, getIndex,
  } = useContext(TableContext)
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [fileHandler, setFileHandler] = useState(null)

  const onChangeStatus = (e) => {
    const { value } = e.target
    setStatus(value)
  }

  const updateStatus = async () => {
    // const params = { invoice: { status } }
    const params = new FormData()
    if (fileHandler) {
      params.append('invoice[attachment]', fileHandler[0])
    }
    params.append('invoice[status]', status)
    try {
      setLoading(true)
      const { status } = await api.post(`/invoices/${invoice.id}/update_status`, params)

      if (status === 'ok') {
        setLoading(false)
        enqueueSnackbar('Berhasil merubah status', { variant: 'success' })
        getIndex()
      }
    } catch (error) {
      setLoading(false)
      enqueueSnackbar('Maaf, terjadi kesalahan saat merubah status', { variant: 'error' })
    }
  }

  useEffect(() => {
    if (invoice) setStatus(invoice.status)
  }, [invoice])

  let phone

  if (invoice?.phone_number?.charAt(0) === '0' || invoice?.phone_number?.charAt(0) === '+') {
    phone = `62${invoice?.phone_number?.substr(1)}`
  } else if (invoice?.phone_number?.startsWith(62)) {
    phone = invoice?.phone_number
  } else {
    phone = `62${invoice?.phone_number}`
  }

  const message = `
Halo Kak ${invoice?.user_name},  saya dari tim squids mau mengkonfirmasi perihal registrasi Squids dengan rincian sebagai berikut :
  
Nama : ${invoice?.user_name}
Nama Toko : ${invoice?.store_name}
Domain : ${invoice?.domain}
Paket : ${invoice?.package_name}
Periode : ${invoice?.period}
Total Pembayaran : ${invoice?.formated_total}
Metode Pembayaran : ${invoice?.payment_method}
${invoice?.payment_detail}

Apakah sudah melakukan pembayaran perihal registrasi tersebut? terimakasih :)
  `

  const waMessage = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(message)}`

  const renderData = () => (
    <Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{
          display: 'flex', flexDirection: 'column', flex: 1, order: 2, ml: 3,
        }}
        >
          <Box sx={{ display: 'flex', flex: 1, position: 'relative' }}>
            <IconButton
              color="primary"
              variant="contained"
              size="small"
              onClick={() => setFileHandler(null)}
              sx={{
                '&.MuiButtonBase-root': {
                  position: 'absolute',
                  zIndex: 10,
                  backgroundColor: 'grey !important',
                  top: '-15px',
                  right: '-15px',
                  color: 'white',
                },
              }}
            >
              <CloudUpload />
            </IconButton>
            <Box sx={{
              position: 'absolute',
              right: 0,
              left: 0,
              top: 0,
              bottom: 0,
              '& input': {
                height: '100%',
                width: '100%',
                opacity: 0,
                padding: 0,
                border: 'none',
                margin: 0,
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 0,
              },
            }}
            >
              <input
                onChange={(e) => setFileHandler(e.target.files)}
                name="approved_attachment"
                type="file"
                accept=".jpg, .jpeg, .png, .pdf"
                multiple={false}
              />
            </Box>
            <Box
              sx={{
                borderRadius: '5px',
                border: '2px dashed rgba(0, 0, 0, .1)',
                width: '100%',
                height: '100%',
                padding: 2,
              }}
            >
              <Box sx={{
                width: '100%',
                height: '100%',
                '& img': {
                  width: '100%',
                  objectFit: 'cover',
                  objectPosition: '50% 50%',
                  maxWidth: '100%',
                  maxHeigth: '100%',
                },
              }}
              >
                {
                  (fileHandler?.length || invoice?.attachment?.url) && (
                    fileHandler?.length
                      ? (<img src={URL.createObjectURL(fileHandler[0])} alt="payment" />)
                      : (<img src={invoice?.attachment?.url} alt="payment" />)
                  )

                }
              </Box>
              {!(fileHandler?.length) && (
              <Typography
                component="span"
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 0,
                  pointerEvents: 'none',
                  color: 'rgba(0, 0, 0,.5)',
                }}
              >
                Click Di Sini Untuk Upload
              </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{
          display: 'flex', flexDirection: 'column', flex: 1, order: 1,
        }}
        >
          <div className={classes.fieldWrapper}>
            <Typography color="textSecondary">Status</Typography>
            <TextField
              select
              name="status"
              className={classes.textField}
              onChange={onChangeStatus}
              value={status}
              disabled={loading}
            >
              <MenuItem value=""><em /></MenuItem>
              <MenuItem value="Open">Menunggu Pembayaran</MenuItem>
              <MenuItem value="Expired">Kedaluarsa</MenuItem>
              <MenuItem value="Paid">Dibayar</MenuItem>
            </TextField>
          </div>
          <div className={classes.container}>
            <div className={classes.col}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr style={{ textAlign: 'left', height: '30px' }}>
                    <th><Typography color="textSecondary">Nama Toko</Typography></th>
                    <td>: {invoice?.store_name}</td>
                  </tr>
                  <tr style={{ textAlign: 'left', height: '30px' }}>
                    <th><Typography color="textSecondary">Nama Paket</Typography></th>
                    <td>: {invoice?.package_name}</td>
                  </tr>
                  <tr style={{ textAlign: 'left', height: '30px' }}>
                    <th><Typography color="textSecondary">Periode</Typography></th>
                    <td>: {invoice?.period}</td>
                  </tr>
                  <tr style={{ textAlign: 'left', height: '30px' }}>
                    <th><Typography color="textSecondary">Status</Typography></th>
                    <td>: {invoice?.status}</td>
                  </tr>
                  <tr style={{ textAlign: 'left', height: '30px' }}>
                    <th><Typography color="textSecondary">Tanggal Invoice</Typography></th>
                    <td>: {dayjs(invoice?.created_at).locale('id').format('DD MMMM YYYY, HH:mm')}</td>
                  </tr>
                  <tr style={{ textAlign: 'left', height: '30px' }}>
                    <th><Typography color="textSecondary">Jatuh Tempo</Typography></th>
                    <td>: {invoice?.due_date}</td>
                  </tr>
                  <tr style={{ textAlign: 'left', height: '30px' }}>
                    <th><Typography color="textSecondary">Total Tagihan</Typography></th>
                    <td style={{ fontSize: 24, color: '#556FB6', fontWeight: 'bold' }}>: {invoice?.formated_total}</td>
                  </tr>
                </tbody>
              </table>
              {
                invoice?.status === 'Open' && invoice?.phone_number && (
                  <Button
                    color="primary"
                    variant="contained"
                    href={waMessage}
                    target="_blank"
                    style={{ backgroundColor: '#25D366', marginTop: 8 }}
                  >
                    <WhatsAppIcon />
                    &nbsp; Follow up
                  </Button>
                )
              }
            </div>
            {/* <div className={classes.col}>
              <table style={{ width: '100%' }}>
                <tr>
                  <th><Typography color="textSecondary" align="left">Nama Affiliate</Typography></th>
                  <td>Affiliate</td>
                </tr>
                <tr>
                  <th><Typography color="textSecondary" align="left">Komisi Affiliate</Typography><hr /></th>
                  <td>Rp. 10.000 <hr /></td>
                </tr>
                <tr>
                  <th><Typography color="textSecondary" align="left">Pendapatan Bersih</Typography></th>
                  <td>Rp. 500.000</td>
                </tr>
              </table>
            </div> */}
          </div>
        </Box>
      </Box>
    </Fragment>
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Detail Tagihan</DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
        {
          showLoading
            ? <Box style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></Box>
            : renderData()
        }
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={updateStatus} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Simpan'}
        </Button>
        <Button variant="outlined" onClick={onClose} disabled={loading}>Tutup</Button>
      </DialogActions>
    </Dialog>
  )
}
export default BillingDetail
