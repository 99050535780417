import Axios from 'axios'
import { adminApiBaseURL } from '@lib/env'
import qs from 'qs'
import { setBearerToken, handleUnauthorized, normalizeResponse } from './interceptor'

const api = Axios.create({
  baseURL: `${adminApiBaseURL}/voucher_usages`,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
})

api.defaults.headers.common.Accept = 'application/json'
api.interceptors.request.use(setBearerToken)
api.interceptors.response.use(normalizeResponse, handleUnauthorized)

// eslint-disable-next-line import/prefer-default-export
export const listVoucherUsage = (params) => api.get('/', { params })
