import Axios from 'axios'
import { adminApiBaseURL } from '@lib/env'
import qs from 'qs'
import { setBearerToken, handleUnauthorized, normalizeResponse } from './interceptor'

const api = Axios.create({
  baseURL: `${adminApiBaseURL}/voucher_packages`,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
})

api.defaults.headers.common.Accept = 'application/json'
api.interceptors.request.use(setBearerToken)
api.interceptors.response.use(normalizeResponse, handleUnauthorized)

export const listVoucher = (params) => api.get('/', { params })

export const createVoucher = ({ ...data }) => api.post('/', { ...data })

export const updateVoucher = (id, data) => api.put(`/${id}`, data)

export const showVoucher = (id) => api.get(`/${id}`)

export const deleteVoucher = (id) => api.delete(`/${id}`)
