/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

const backgroundCSS = css`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #4BAEA0 0%, #556FB5 100%);
  display: flex;
  justify-content: center;
  align-items: center;
`

function Background(props) {
  const { children } = props
  return (
    <div css={backgroundCSS}>
      { children }
    </div>
  )
}

export default Background
