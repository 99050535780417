/** @jsxImportSource @emotion/react */

import Button from '@material-ui/core/Button'
import MenuList from '@material-ui/core/MenuList'
import Grow from '@material-ui/core/Grow'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import {
  Fragment, useEffect, useRef, useState,
} from 'react'
import { css } from '@emotion/react'
import Logout from '@components/button/Logout'
import MyAccount from '@components/button/MyAccount'
import ChangePassword from '@components/button/ChangePassword'

import superAdminButtonSVG from './svg/superAdminButton.svg'

function AccountButton() {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const CSS = css`
    &.account-button.MuiButton-root {
      padding: 0;
      border-radius: 60px;
      margin-left: 11px;
    }
  `

  return (
    <Fragment>
      <Button
        css={CSS}
        className="account-button"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <img src={superAdminButtonSVG} alt="" />
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                  <MyAccount onClick={handleClose} />
                  <ChangePassword onClick={handleClose} />
                  <Logout />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  )
}

export default AccountButton
