/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/** @jsxImportSource @emotion/react */

import Form from '@components/dialog/Form'
import { useForm } from 'react-hook-form'
import { css } from '@emotion/react'
import { Fragment, useEffect, useState } from 'react'
import Select from '@components/field/Select'
import TextArea from '@components/field/TextArea'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { showSaasAdmins } from '@lib/axios/admin'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import useValue from './useValue'

const CSS = css`
  width: 829px;
  height: 568px;
  max-width: 829px !important;
  table {
    margin: 0;
    padding: 0;
    width: 100%;
    th, td {
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      padding-top: 7px;
      padding-bottom: 7px;
      vertical-align: baseline;
    }
    th {
      color: #676767;
      width: 163px;
    }
    td {
      color: #1A253B;
    }
  }
  .top {
    display: flex;
  }
  .top > * {
    flex: 1;
  }
  .top > *:last-of-type {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 18px;
    margin-left: 15px;
    button {
      border: 0 none;
      background-color: transparent;
      margin: 0;
      padding: 0;
      color: #3630C9;
      cursor: pointer;
    }
    button:hover {
      text-decoration: underline;
    }
  }
`

export default function Dialog(props) {
  const {
    type, title, onSave: save,
  } = props
  const previewData = useSelector(({ ui: { dialog } }) => dialog.data)
  const [saas_admin_name, setSaas_admin_name] = useState('')

  useEffect(() => {
    if (previewData?.saas_admin_id) {
      showSaasAdmins(previewData?.saas_admin_id)
        .then((response) => {
          const { status, data } = response
          if (status === 'ok') {
            setSaas_admin_name(data?.data?.name)
          }
        })
    }
  }, [previewData?.saas_admin_id])

  const { value, open } = useValue()

  const {
    handleSubmit, register, control, reset, watch,
    errors,
  } = useForm({
    defaultValues: value,
  })

  useEffect(() => {
    if (open) {
      reset(
        value,
        {
          isDirty: false,
          touched: false,
          dirtyFields: false,
          errors: false,
        }
      )
    }
  }, [value, reset, open])

  return open && (
    <Form
      title={title}
      type={type}
      onSubmit={handleSubmit((data) => save(data))}
      css={CSS}
      dividers
    >
      <div className="top">
        <Select
          label="Status"
          name="status"
          control={control}
          selects={['open', 'on progress', 'completed', 'closed', 're-open']}
        />
        <input type="hidden" ref={register} name="id" />
        <input type="hidden" ref={register} name="type_support" />
        <div>
          {
            previewData?.saas_admin_id
              ? (
                <Fragment>
                  <div>
                    Terakhir diubah oleh {saas_admin_name} pada
                    {' '}
                    {`${dayjs(previewData?.updated_at).format('DD-MM-YYYY HH:mm')} WIB`}
                  </div>
                  <button
                    type="button"
                  >
                    Lihat riwayat perubahan
                  </button>
                </Fragment>
              )
              : (
                <Fragment>
                &nbsp;
                </Fragment>
              )
          }
        </div>
      </div>
      <table>
        <tbody>
          <tr>
            <th>Nama User</th>
            <td>{previewData?.saas_user_name}</td>
          </tr>
          <tr>
            <th>Nama Toko</th>
            <td>{previewData?.store_name}</td>
          </tr>
          <tr>
            <th>Jenis Support</th>
            <td>{previewData?.type_support}</td>
          </tr>
          <tr>
            <th>Deskripsi</th>
            <td>{previewData?.description}</td>
          </tr>
          <tr>
            <th>Keterangan</th>
            <td>
              <TextArea
                control={control}
                label="Keterangan"
                name="conclusion"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </Form>
  )
}
