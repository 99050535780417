/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import MuiButton from '@material-ui/core/Button'

const CSS = css`
  &.MuiButton-root {
    border-radius: 5px;
    width: 96px;
    height: 45px;
    position: relative;
  }
  &.MuiButton-textPrimary {
    background-color: #556FB5;
  }
  .MuiButton-label {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    position: absolute;
  }
`
function Button(props) {
  const { children, onClick: handleClick } = props
  return (
    <MuiButton
      disableElevation
      variant="contained"
      css={CSS}
      onClick={handleClick}
    >
      { children || 'Simpan' }
    </MuiButton>
  )
}

export default Button
