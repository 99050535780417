/* eslint-disable import/prefer-default-export */
import { adminApiBaseURL } from '@lib/env'
import Axios from 'axios'
import qs from 'qs'
import {
  setBearerToken,
  handleUnauthorized,
  normalizeResponse,
} from './interceptor'

const api = Axios.create({
  baseURL: adminApiBaseURL,
  // prettier-ignore
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
})
api.defaults.headers.common.Accept = 'application/json'
api.interceptors.request.use(setBearerToken)
api.interceptors.response.use(normalizeResponse, handleUnauthorized)

export const indexProfile = (params) => api.get('/profiles', { params })

// prettier-ignore
export const updateSaasAdminProfile = ({ name, password }) => api.post('/profiles', {
  saas_admin: {
    name,
    password,
  },
})
