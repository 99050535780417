/* eslint-disable prefer-const */
/* eslint-disable import/prefer-default-export */
import axios from 'axios'
import { adminApiBaseURL } from '@lib/env'
import {
  setBearerToken,
  handleUnauthorized,
  normalizeResponse,
} from '@lib/axios/interceptor'

const api = axios.create({
  baseURL: adminApiBaseURL,
})

api.defaults.headers.common.Accept = 'application/json'
api.interceptors.request.use(setBearerToken)
api.interceptors.response.use(normalizeResponse, handleUnauthorized)

const path = '/banks'

export const indexApi = (params) => api.get(`${path}`, { params })
export const showApi = (id) => api.get(`${path}/${id}`)
export const updateApi = (id, params) => api.put(`${path}/${id}`, params)
export const postApi = (params) => api.post(`${path}`, params)
export const deleteApi = (id) => api.delete(`${path}/${id}`)
export const integrationBanks = (params) => api.get(`${path}/available_third_party_banks`, {
  params: {
    ...params,
    transaction_type: 'Disbursement',
    service: 'xendit',
  },
})

export const banksIluma = (params) => api.get(`${path}/available_third_party_banks`, {
  params: {
    ...params,
    service: 'iluma',
  },
})
