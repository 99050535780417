import { css } from '@emotion/react'

export default css`
  &.MuiFormControl-root {
    padding-top: 22px;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #676767;
  }
  & .MuiFormLabel-root {
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
    top: 0;
    left: 0;
    position: absolute;
  }
  & .MuiFormLabel-root.Mui-focused {
    color: #676767;
  }
  & .MuiFormGroup-root {
    flex-direction: row;
  }
  & .MuiFormControlLabel-root {
    height: 45px;
  }
  & .MuiFormControlLabel-label {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
  }
  & .MuiSvgIcon-root,
  & .Mui-checked {
    color: #556FB5;
  }
`
