/** @jsxImportSource @emotion/react */

import IMask from 'imask/esm/imask'
import 'imask/esm/masked/number'
import { useCallback, useEffect, useRef } from 'react'
import InputBase from '@material-ui/core/InputBase'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import upSVG from '@assets/field/up.svg'
import downSVG from '@assets/field/down.svg'
import { css } from '@emotion/react'

const inputCSS = css`
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .02);
    background-color: #F6F9FB;
  &.Mui-focused {
    border: 1px solid rgba(0, 0, 0, .1);
  }
  & .MuiInputBase-input {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 43px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 10px;
    height: 45px;
  }
  & .MuiSelect-select.MuiSelect-select {
    padding-left: 10px;
  }
  & .MuiSelect-select:focus {
    background-color: #F6F9FB;
  }
`

function Field(props) {
  const {
    name, onChange, value, step, inputRef,
  } = props

  const maskRef = useRef()

  const destroyMask = () => {
    if (maskRef.current) {
      maskRef.current.destroy()
      maskRef.current = undefined
    }
  }

  const handleIncrement = useCallback(
    () => {
      const current = parseInt(maskRef.current.unmaskedValue, 10)
      const next = value ? current + step : step
      maskRef.current.unmaskedValue = `${next}`
    },
    [value, step]
  )

  const handleDecrement = useCallback(
    () => {
      const current = parseInt(maskRef.current.unmaskedValue, 10)
      const next = (value) ? current - step : 0
      maskRef.current.unmaskedValue = `${next}`
    },
    [value, step]
  )

  const handleAccept = useCallback(
    () => onChange(maskRef.current.unmaskedValue),
    [onChange]
  )

  const handleComplete = useCallback(
    () => onChange(maskRef.current.unmaskedValue),
    [onChange]
  )

  const initMask = useCallback(
    () => {
      maskRef.current = IMask(inputRef.current, {
        mask: Number,
        scale: 0,
        signed: false,
        thousandsSeparator: '.',
        // padFractionalZeros: true,
        // radix: ',',
        // mapToRadix: ['.'],
      })
        .on('accept', handleAccept)
        .on('complete', handleComplete)
    },
    [inputRef, handleAccept, handleComplete]
  )

  useEffect(() => {
    initMask()
    return () => destroyMask()
  }, [initMask])

  return (
    <InputBase
      inputProps={{ autoComplete: 'off', spellCheck: 'false' }}
      inputRef={inputRef}
      name={name}
      defaultValue={value}
      css={inputCSS}
      startAdornment={(
        <InputAdornment position="start">
          &nbsp;Rp.&nbsp;
        </InputAdornment>
      )}
      endAdornment={step && (
        <InputAdornment position="end">
          <IconButton size="small" onClick={handleIncrement}>
            <img src={upSVG} alt="" />
          </IconButton>
          <IconButton
            size="small"
            onClick={handleDecrement}
            disabled={!value || value === '0'}
          >
            <img src={downSVG} alt="" />
          </IconButton>
        </InputAdornment>
      )}
    />
  )
}

export default Field
