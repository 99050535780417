/* eslint-disable import/no-extraneous-dependencies */
/** @jsxImportSource @emotion/react */

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { css } from '@emotion/react'
import { Controller } from 'react-hook-form'
import { Fragment } from 'react'

const CSS = css`
  padding-left: 15px;
  .MuiFormControlLabel-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
  .MuiButtonBase-root {
    height: 45px;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #556FB5;
  }
`

function Field(props) {
  const {
    onChange: handleChange,
    label,
    value,
    style,
  } = props

  const setValue = (e) => {
    handleChange(!!e.target.checked)
  }

  return (
    <FormControlLabel
      css={CSS}
      label={`${label}`}
      style={style}
      control={(
        <Checkbox
          checked={value === true}
          onChange={setValue}
          size="small"
        />
      )}
    />
  )
}

function AlternateCheckbox(props) {
  const {
    label, control, name, style,
  } = props

  return (
    <Fragment>
      <Controller
        control={control}
        name={name}
        render={
          ({ onChange: handleChange, value }) => (
            <Fragment>
              <Field
                label={label}
                onChange={handleChange}
                value={value}
                style={style}
              />
            </Fragment>
          )
        }
      />
    </Fragment>
  )
}

export default AlternateCheckbox
