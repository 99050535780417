/** @jsxImportSource @emotion/react */

import dropdownSVG from '@assets/field/dropdown.svg'
import { css } from '@emotion/react'

const CSS = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  height: 15px;
  width: 15px;
  pointer-events: none;
  & > img {
    top: 0;
    right: 0;
  }
`

function Icon(props) {
  const { className } = props
  return (
    <div css={CSS}>
      <img
        className={className}
        src={dropdownSVG}
        alt=""
      />
    </div>
  )
}

export default Icon
