import { deleteTheme } from '@lib/axios/theme'
import { useMutation, useQueryClient } from 'react-query'

const useDelete = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (id) => deleteTheme(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('saas-theme')
      },
    }
  )
}

export default useDelete
