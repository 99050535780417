/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import Delete from '@components/DataTable/button/Delete'
import useConfirm from '@hooks/useConfirm'
import useDelete from '@lib/query/setupStoreDashboardPage/useDelete'

const Hapus = (props) => {
  const { id } = props
  const confirmKey = `hapus-${id}`
  const { mutate } = useDelete()
  const { open, yes, no } = useConfirm('Hapus Dashboard', 'Apakah anda yakin ingin menghapus dashboard ini ?', confirmKey)
  yes(() => mutate(id), confirmKey)

  return (
    <Delete onClick={open} />
  )
}

export default Hapus
