/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import MuiButton from '@material-ui/core/Button'
import { openDialog } from '@lib/redux/slice/ui'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const CSS = css`
  &.MuiButton-root {
    border-radius: 5px;
    width: 130px;
    height: 30px;
    position: relative;
  }
  &.MuiButton-textPrimary {
    background-color: #556fb5;
  }
  .MuiButton-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    text-transform: none;
    color: #ffffff;
    position: absolute;
  }
`

const useOpenDialog = (type) => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  return () => dispatch(openDialog({ type: `${pathname}.${type}` }))
}

function Button(props) {
  const {
    type, title, onClick, hideTambah,
  } = props
  const handleClick = useOpenDialog(type)

  const handleClickButton = () => {
    handleClick()
    if (onClick) onClick()
  }

  return (
    <MuiButton
      disableElevation
      variant="contained"
      css={CSS}
      onClick={handleClickButton}
    >
      {!hideTambah && 'Tambah'} {title && title}
    </MuiButton>
  )
}

export default Button
