/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */
/* eslint-disable react-hooks/exhaustive-deps */

import useIndex from '@lib/query/store/useIndex'
import React, {
  Fragment,
  useMemo,
  useEffect,
} from 'react'
import { css } from '@emotion/react'
import DataTable from '@components/DataTable'
import PageTitle from '@components/PageTitle'
import { Helmet } from 'react-helmet'
import Layout from '@layout/Main'
import Card from '@components/card/Base'
import useUpdate from '@lib/query/store/useUpdate'
import qs from 'qs'
import { useLocation } from 'react-router'
import action from './action'
import Toolbar from './Toolbar'
import PackageDialog from './PackageDialog'
import { TableProvider } from './Toolbar/TableProvider'
import useTableState from './Toolbar/useTableState'

const CSS = css`
   thead tr > *:last-of-type {
    width: 200px;
    div {
      text-align: center;
    }
  }
  & tbody tr > *:last-of-type div {
    display: flex;
    justify-content: space-around;
  }
`

const titleCSS = css`
  display: flex;
`
const useQuery = () => new URLSearchParams(useLocation().search)

function StoreManagement() {
  const update = useUpdate()
  const state = useTableState()
  const location = useLocation()
  const query = useQuery()
  const onClickPage = (item) => {
    const queryString = qs.parse(location.search, { ignoreQueryPrefix: true })
    const newQs = { ...queryString, page: item.page }
    const page1 = qs.stringify({ ...queryString, page: 1 }, { encode: false })
    return `${location.pathname}?${item.page === 1 ? page1 : qs.stringify(newQs, { encode: false })}`
  }
  const {
    tableColumns,
    hiddenColumns,
    tablePagination,
  } = useIndex()
  useEffect(() => {
    state.getIndex()
  }, [location.search])
  return (
    <TableProvider value={state}>
      <Fragment>
        <Helmet>
          <title>
            SQUIDS :: MANAJEMEN TOKO
          </title>
        </Helmet>
        <PageTitle>
          Manajemen Toko
        </PageTitle>
        <Layout>
          <Card>
            <div css={titleCSS}>
              <h3>Manajemen Toko</h3>
            </div>
            <Toolbar />
            <DataTable
              css={CSS}
              data={state?.data}
              columns={useMemo(() => tableColumns, [tableColumns])}
              hooks={action}
              hiddenColumns={hiddenColumns}
              pagination={tablePagination}
              onChange={onClickPage}
              style={{ display: 'block', whiteSpace: 'nowrap', overflowX: 'auto' }}
            />
          </Card>
          <PackageDialog update={update} />
        </Layout>
      </Fragment>
    </TableProvider>
  )
}

export default StoreManagement
