import { closeDialog } from '@lib/redux/slice/ui'
import { useDispatch } from 'react-redux'

function useCloseDialog() {
  const dispatch = useDispatch()
  const open = () => dispatch(closeDialog())
  return open
}

export default useCloseDialog
