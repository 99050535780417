import { useCallback, useState, useEffect } from 'react'

function useSelect(query, selects, fn) {
  const [items, setItems] = useState()
  let refetch
  let data
  if (query) {
    ({ refetch, data } = query)
  }

  const handleOpen = useCallback(() => {
    if (refetch) {
      refetch()
    }
  }, [refetch])

  useEffect(() => {
    if (selects) {
      setItems(selects)
    }
  }, [selects])

  useEffect(() => {
    if (fn && data && Array.isArray(data)) {
      setItems(fn(data))
    }
  }, [fn, data])

  return {
    handleOpen,
    items,
  }
}

export default useSelect
