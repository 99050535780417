import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarOpen: false,
  sidebarType: 'sticky',
  pageTitle: '',
  dialog: {
    open: false,
    type: 'none',
    data: {},
  },
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleSidebarOpen: ({ sidebarOpen, ...state }) => ({
      ...state,
      sidebarOpen: !sidebarOpen,
    }),
    toggleSidebarType: ({ sidebarType, ...state }) => ({
      ...state,
      sidebarType: sidebarType === 'sticky' ? 'scroll' : 'sticky',
    }),
    setSidebarOpen: ({ sidebarOpen, ...state }, { payload }) => ({
      ...state,
      sidebarOpen: payload.open,
    }),
    setSidebarType: ({ sidebarType, ...state }, { payload }) => ({
      ...state,
      sidebarType: payload.type,
    }),
    setPageTitle: ({ sidebarType, ...state }, { payload }) => ({
      ...state,
      pageTitle: payload,
    }),
    openDialog: ({ dialog, ...state }, { payload }) => ({
      ...state,
      dialog: {
        open: true,
        type: payload.type,
        data: payload.data,
      },
    }),
    confirmSave: ({ dialog, ...state }, { payload }) => ({
      ...state,
      dialog: {
        ...initialState.dialog,
        type: 'confirm.data',
        data: {
          confirm: payload,
          confirmKey: dialog.data?.confirmKey,
        },
      },
    }),
    confirmClear: ({ dialog, ...state }) => ({
      ...state,
      dialog: initialState.dialog,
    }),
    closeDialog: ({ dialog, ...state }) => ({
      ...state,
      dialog: initialState.dialog,
    }),
  },
})

export const {
  toggleSidebarOpen,
  toggleSidebarType,
  setSidebarType,
  setSidebarOpen,
  setPageTitle,
  openDialog,
  closeDialog,
  confirmClear,
  confirmSave,
} = uiSlice.actions

export default uiSlice.reducer
