/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable arrow-body-style */
/** @jsxImportSource @emotion/react */

import Form from '@components/dialog/Form'
import { useForm } from 'react-hook-form'
import { css } from '@emotion/react'
import useSelectQuery from '@lib/query/admin/role/useIndex'
import Radio from '@components/field/Radio'
import Text from '@components/field/Text'
import Select from '@components/field/Select'
import { useEffect } from 'react'
// import { DevTool } from '@hookform/devtools'

import useValue from './useValue'

const CSS = css`
  min-width: 829px;
  border-radius: 5px;
  .MuiDialogContent-root {
    padding: 32px;
  }
  .field-wrap {
    width: 381px;
    display: flex;
    flex-direction: column;
  }
  .field-wrap > div {
    flex: 1;
    margin-bottom: 10px;
  }
`

export default function Dialog(props) {
  const {
    type, title, onSave: save, defaultValues,
  } = props

  const { value, open } = useValue(defaultValues)

  const {
    handleSubmit, register, control, reset,
  } = useForm({ defaultValues: value })

  const selectQuery = useSelectQuery()

  const selectQueryMap = (data) => (data.length > 0) && data.map(({ id, name }) => [`${id}`, name])

  useEffect(() => {
    const options = {
      isDirty: false,
      touched: false,
      dirtyFields: false,
      errors: false,
    }
    if (open) reset(value, options)
  }, [value, reset, open])

  return (
    <Form
      title={title}
      type={type}
      onSubmit={handleSubmit((data) => save(data))}
      css={CSS}
    >
      <div className="field-wrap">
        {
          value?.id && (
            <input name="id" type="hidden" value={value.id} ref={register} />
          )
        }
        <Text label="Nama" name="name" ref={register} />
        <Text label="Email" name="email" ref={register} />
        <Select
          label="Role"
          name="saas_role_id"
          control={control}
          query={selectQuery}
          map={selectQueryMap}
        />
        <Radio
          label="Status"
          name="activation_status"
          control={control}
          radios={[['active', 'aktif'], ['suspended', 'tidak aktif']]}
        />
        {/* <DevTool control={control} /> */}
      </div>
    </Form>
  )
}
