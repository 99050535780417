/* eslint-disable consistent-return */
/* eslint-disable camelcase */

import { updateVoucher } from '@lib/axios/voucher'
import { useMutation, useQueryClient } from 'react-query'
import useSuccess from '@hooks/useSuccess'
import { useHistory } from 'react-router-dom'

const useUpdate = () => {
  const showSuccess = useSuccess('Sukses', 'Sukses Mengubah Voucher')
  const queryClient = useQueryClient()
  const history = useHistory()
  const mutation = useMutation(
    ({ id, ...data }) => updateVoucher(id, data)
  )

  return (data) => mutation.mutate((data), {
    onSuccess: () => {
      showSuccess()
      history.goBack()
      queryClient.invalidateQueries('saas-voucher')
    },
  })
}

export default useUpdate
