/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

const formTitleCSS = css`
  p {
    margin: 0;
  }
  .num {
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    span {
      color: #3630C9;
    }
  }
  .date {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
`

const FormTitle = () => {
  const previewData = useSelector(({ ui: { dialog } }) => dialog.data)
  return (
    <div css={formTitleCSS}>
      <p className="num">
        No
        {' '}
        <span>{previewData?.number}</span>
      </p>
      <p className="date">
        {dayjs(previewData?.created_at).locale('id').format('DD MMMM YYYY')}
      </p>
    </div>
  )
}

export default FormTitle
