import { Fragment } from 'react'
import Ubah from '@components/button/Ubah'
import Suspend from './Suspend'

const action = (hooks) => {
  hooks.visibleColumns.push((columns) => [
    ...columns,
    {
      id: 'activation_status_display',
      Header: () => (<div>Status</div>),
      Cell: ({ row: { original: data } }) => (
        <span style={{ color: data.activation_status === 'suspended' ? '#E82A63' : '#4BAEA0' }}>
          { data.activation_status }
        </span>
      ),
    },
    {
      id: 'aksi',
      Header: () => (<div>Aksi</div>),
      Cell: ({ row: { original: data } }) => (
        <Fragment>
          <Suspend data={data} />
          <Ubah type="edit" data={data} />
        </Fragment>
      ),
    },
  ])
}

export default action
