const d = new Date()

export default {
  name: '',
  code: '',
  start_date: new Date(),
  end_date: new Date(d.setDate(d.getDay() + 30)),
  annual: false,
  annual_amount: '',
  annual_type_cd: 0,
  annual_period: '',
  monthly: false,
  monthly_type_cd: 0,
  monthly_amount: '',
  monthly_period: '',
  marketing_budget: '',
  limit: '',
  description: '',
}
