import { useQuery } from 'react-query'
import { listThemes } from '@lib/axios/theme'
import useQueryString from '@hooks/useQueryString'
import { useMemo } from 'react'

function useIndex() {
  const { params } = useQueryString()
  const { status, data } = useQuery(
    ['saas-theme', params],
    () => listThemes(params),
    {
      retry: false,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  )
  const tableData = useMemo(
    () => ((status === 'success' && Array.isArray(data?.data?.data)) ? data?.data?.data : []),
    [status, data]
  )

  const emptyPagination = useMemo(() => ({
    current_page: 1,
    first_page: true,
    last_page: false,
    next_page: null,
    prev_page: null,
    total_pages: 1,
  }), [])

  const tablePagination = useMemo(
    () => ((status === 'success' && data?.data?.pagination) ? data?.data?.pagination : emptyPagination),
    [status, data, emptyPagination]
  )

  const columns = [
    ['id', 'ID', true],
    ['name', 'NAMA TEMA', false],
    ['thumbnail', 'THUMBNAIL', true],
    ['status', 'Status', true],
  ]

  const tableColumns = columns
    .map(([column, title]) => ([column, title]))
    .map(([accessor, Header]) => ({ accessor, Header }))

  const hiddenColumns = columns
    .filter(([, , hidden]) => hidden)
    .map(([column]) => column)

  return {
    tableData,
    tableColumns,
    hiddenColumns,
    tablePagination,
  }
}

export default useIndex
