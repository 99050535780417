/* eslint-disable no-console */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { forwardRef } from 'react'
import clsx from 'clsx'

const inputCSS = css`
  position: absolute;
  width: 380px;
  height: 45px;
  left: 0;
  top: 0;
  background: #F6F9FB;
  border-radius: 5px;
  position: relative;


  & > input {
    border-width: 0px;
    border-radius: 5px;
    padding: 10px 50px;
    margin: 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 170%;
    box-sizing: border-box;
    width: 100%;
    background-color: transparent;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, .1);
    :focus {
      outline: none;
      box-shadow: 0 0 1px 1px rgba(0, 0, 0, .15);
    }
  }

  &.error > input {
    box-shadow: 0 0 1px 1px rgba(255, 0, 0, .9);
  }

  & > .before {
    position: absolute;
    left: 16px;
    top: 11px;
    width: 24px;
    height: 24px;
    z-index: 1;
  }

  & > .after{
    position: absolute;
    right: 12px;
    top: 11px;
    width: 24px;
    height: 24px;
    z-index: 1;
  }

  & > p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 170%;
    margin: 0;
    padding: 0;
    color: red;
  }
`

const Input = forwardRef((props, ref) => {
  const {
    type, className, before: Before, after: After,
    name, error, message, placeholder,
  } = props
  return (
    <div css={inputCSS} className={clsx(className, !!error && 'error')}>
      {
        Before && (
          <div className="before">
            <Before />
          </div>
        )
      }
      {
        After && (
          <div className="after">
            <After />
          </div>
        )
      }
      <input
        placeholder={placeholder}
        spellCheck="false"
        autoComplete="off"
        name={name}
        ref={ref}
        type={type === 'password' ? 'password' : 'text'}
      />
      {
        error && (<p>{ error }</p>)
      }
      {
        message && (<p>{ message }</p>)
      }
    </div>
  )
})

export default Input
