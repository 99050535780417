/** @jsxImportSource @emotion/react */

import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import { closeDialog } from '@lib/redux/slice/ui'
import { useEffect } from 'react'
import { css } from '@emotion/react'
import checkSVG from '@assets/dialog/check.svg'

const TYPE = 'success.dialog'

const CSS = css`
  .MuiDialog-paper {
    width: 368px;
    height: 117px;
    background-color: #ffffff;
    border-radius: 5px;
    margin: 0;
  }
  .success-dialog-image {
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .succcess-dialog-message {
    p {
      margin: 0%;
      line-height: 25px;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
    }
  }
`

function SuccessDialog(props) {
  const {
    open, type, data, close,
    className,
  } = props

  useEffect(() => {
    let cleanUp = () => null
    if (open && type === TYPE) {
      const timeoutId = setTimeout(() => close(), 900)
      cleanUp = () => clearTimeout(timeoutId)
    }
    return cleanUp
  }, [close, open, type])

  return (
    <Dialog open={open && type === TYPE} css={CSS} className={className}>
      {
        type === TYPE
        && (
          <div className="success-dialog-content">
            <div className="success-dialog-image">
              <img src={checkSVG} alt="" />
            </div>
            <div className="succcess-dialog-message">
              <p>{ data.message } </p>
            </div>
          </div>
        )
      }
    </Dialog>
  )
}

const mapState = ({ ui: { dialog: { open, type, data } } }) => ({
  open, type, data,
})

const mapDispatch = {
  close: closeDialog,
}
const connector = connect(mapState, mapDispatch)
const ConnectedSuccessDialog = connector(SuccessDialog)
export default ConnectedSuccessDialog
