/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable radix */
/** @jsxImportSource @emotion/react */

/* eslint-disable no-unused-vars */

import { Fragment, useState } from 'react'
import { Controller } from 'react-hook-form'
import FormControl from '@material-ui/core/FormControl'
import {
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  InputAdornment,
} from '@material-ui/core'
import { css } from '@emotion/react'
import Text from '@components/field/Text'
import { FormatNumber, UnFormatRp } from '@lib/formatter'

const CSS = css`
  margin-bottom: 20px;
  margin-left: 0;
  .MuiTypography-root {
    font-size: 12px;
    margin-left: 5px;
  }
`

const formControlCSS = css`
  &.MuiFormControl-root {
    width: 100%;
  }
`

const Form = ({
  control, openField, name, initialDiscountType,
}) => {
  const [discountType, setDiscountType] = useState(initialDiscountType)
  return (
    <Fragment>
      {openField && (
        <div style={{ paddingRight: name === 'annual' ? 10 : 0, width: '100%' }}>
          <FormControl fullWidth css={formControlCSS}>
            <Controller
              control={control}
              name={`${name}_amount`}
              render={({ onChange, value }) => (
                <div>
                  {openField && (
                    <Text
                      label="Potongan Harga"
                      placeholder="Potongan Harga"
                      startAdornment={
                        discountType === 1 && (
                          <InputAdornment position="start">Rp</InputAdornment>
                        )
                      }
                      endAdornment={
                        discountType === 0 && (
                          <InputAdornment position="end">%</InputAdornment>
                        )
                      }
                      onChange={(evt) => onChange(UnFormatRp(evt.target.value))}
                      value={discountType === 1 ? FormatNumber(value) : value}
                    />
                  )}
                </div>
              )}
            />
            <Controller
              control={control}
              name={`${name}_type_cd`}
              render={({ onChange, value }) => (
                <Fragment>
                  {openField && (
                    <div>
                      <RadioGroup
                        row
                        value={value}
                        onChange={(evt) => {
                          onChange(parseInt(evt.target.value))
                          setDiscountType(parseInt(evt.target.value))
                        }}
                      >
                        {[
                          [0, 'Persentase'],
                          [1, 'Rupiah'],
                        ].map((radio) => (
                          <FormControlLabel
                            css={CSS}
                            key={radio[0]}
                            value={radio[0]}
                            control={(
                              <Radio
                                size="small"
                                checked={radio[0] === value}
                              />
                            )}
                            label={radio[1]}
                          />
                        ))}
                      </RadioGroup>
                    </div>
                  )}
                </Fragment>
              )}
            />
            <Controller
              control={control}
              name={`${name}_period`}
              render={({ onChange, value }) => (
                <Fragment>
                  {openField && (
                    <div>
                      <Text
                        fullWidth
                        label="Berlaku Sampai Periode Ke"
                        placeholder="Berlaku Sampai Periode Ke"
                        value={value}
                        onChange={onChange}
                      />
                    </div>
                  )}
                </Fragment>
              )}
            />
          </FormControl>
        </div>
      )}
    </Fragment>
  )
}

function TextNumber(props) {
  const { control, editData, setValue } = props
  const [openAnnual, setOpenAnnual] = useState(!!editData?.annual_amount)
  const [openMonthly, setOpenMonthly] = useState(!!editData?.monthly_amount)
  const discount = [
    ['Tahunan', 'annual'],
    ['Bulanan', 'monthly'],
  ]

  const resetValues = (name) => {
    setValue(`${name}_amount`, 0)
    setValue(`${name}_period`, 0)
    setValue(`${name}_type_cd`, 0)
  }

  const handleChange = (name) => {
    if (name === 'annual') {
      if (openAnnual) resetValues(name)
      setOpenAnnual(!openAnnual)
    } else {
      if (openMonthly) resetValues(name)
      setOpenMonthly(!openMonthly)
    }
  }

  return (
    <Fragment>
      <div style={{ display: 'flex' }}>
        {discount.map((val) => (
          <Fragment>
            <div style={{ width: '50%', paddingRight: 50 }}>
              <Controller
                control={control}
                name={val[1]}
                render={({ onChange, value }) => (
                  <FormControlLabel
                    css={CSS}
                    label={val[0]}
                    control={(
                      <Checkbox
                        checked={value}
                        onChange={() => {
                          onChange(!value)
                          handleChange(val[1])
                        }}
                        size="small"
                      />
                    )}
                  />
                )}
              />
            </div>
          </Fragment>
        ))}
      </div>
      <div style={{ display: openAnnual && openMonthly ? 'flex' : 'block' }}>
        <Form
          control={control}
          openField={openAnnual}
          name="annual"
          initialDiscountType={
            editData?.annual_type_cd === 'annual_rupiah' ? 1 : 0
          }
        />
        <Form
          control={control}
          openField={openMonthly}
          name="monthly"
          initialDiscountType={
            editData?.monthly_type_cd === 'rupiah' ? 1 : 0
          }
        />
      </div>
    </Fragment>
  )
}

export default TextNumber
