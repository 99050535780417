/** @jsxImportSource @emotion/react */
/* eslint-disable no-console */

import { useState, forwardRef } from 'react'
import { css } from '@emotion/react'
// import lockSVG from '@assets/auth/lock.svg'
import LockIcon from '@material-ui/icons/LockOutlined'

import TogglePassword from './TogglePassword'
import Input from './Input'

const passwordCSS = css`
  left: 59px;
  top: 301px;
  position: absolute;
`

const Password = forwardRef((props, ref) => {
  const {
    name, error, placeholder, className,
    toggle,
  } = props
  const [type, setType] = useState('password')

  const handleTogglePassword = () => {
    if (type === 'password') setType('text')
    else setType('password')
  }

  const before = () => <LockIcon style={{ color: '#6a6e75' }} />
  // <img src={lockSVG} alt="" />
  const after = () => !!toggle && <TogglePassword onToggle={handleTogglePassword} type={type} />
  return (
    <Input
      error={error}
      name={name}
      ref={ref}
      before={before}
      after={after}
      type={type}
      css={passwordCSS}
      placeholder={placeholder}
      className={className}
    />
  )
})

export default Password
