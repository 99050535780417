/* eslint-disable consistent-return */
/* eslint-disable camelcase */

import { updateSaasAdmins } from '@lib/axios/admin'
import { useMutation, useQueryClient } from 'react-query'
import useSuccess from '@hooks/useSuccess'
import useCloseDialog from '@hooks/useCloseDialog'

const useUpdate = () => {
  const showSuccess = useSuccess('Sukses', 'Sukses Mengubah Admin')
  const closeDialog = useCloseDialog()
  const queryClient = useQueryClient()
  const mutation = useMutation(
    ({ id, ...data }) => updateSaasAdmins(id, data)
  )

  return (data) => mutation.mutate(data, {
    onError: () => closeDialog(),
    onSuccess: () => {
      showSuccess()
      queryClient.invalidateQueries('saas-admin')
    },
  })
}

export default useUpdate
