/** @jsxImportSource @emotion/react */

import Dropdown from '@components/filter/Dropdown'

function UserStatus(props) {
  const {
    onSelected: handleSelected,
    className,
    defaultValue,
  } = props
  return (
    <Dropdown
      onSelected={handleSelected}
      items={[
        ['Pilih Status User', ''],
        ['User Baru', 'new_user'],
        ['Pelanggan', 'user_customer'],
        ['tidak lanjut', 'not_continue'],
        ['tidak aktif', 'inactive'],
      ]}
      defaultValue={defaultValue || ''}
      className={className}
    />
  )
}

export default UserStatus
