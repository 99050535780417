import { adminApiBaseURL } from '@lib/env'
import Axios from 'axios'
import qs from 'qs'
import { setBearerToken, handleUnauthorized, normalizeResponse } from './interceptor'

const api = Axios.create({
  baseURL: adminApiBaseURL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
})
api.defaults.headers.common.Accept = 'application/json'
api.interceptors.request.use(setBearerToken)
api.interceptors.response.use(normalizeResponse, handleUnauthorized)

export const indexCourierManagement = (params) => api.get('/couriers', { params })

export const createCourierManagement = (name, code, icon) => {
  const formData = new FormData()

  formData.append('courier[name]', name)
  formData.append('courier[code]', code)
  if (icon) {
    formData.append('courier[images]', icon)
  }
  api.post('/couriers', formData)
}

export const showCourierManagement = (id) => api.get(`/couriers/${id}`)
export const deleteCourierManagement = (id) => api.delete(`/couriers/${id}`)

export const updateCourierManagement = (id, name, code, icon) => {
  const formData = new FormData()
  formData.append('courier[name]', name)
  formData.append('courier[code]', code)
  if (icon) {
    formData.append('courier[images]', icon)
  }
  api.put(`/couriers/${id}`, formData)
}
