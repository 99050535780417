import Axios from 'axios'
import { packageApiBaseURL } from '@lib/env'
import qs from 'qs'
import { setBearerToken, handleUnauthorized, normalizeResponse } from './interceptor'

const api = Axios.create({
  baseURL: packageApiBaseURL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
})

api.defaults.headers.common.Accept = 'application/json'
api.interceptors.request.use(setBearerToken)
api.interceptors.response.use(normalizeResponse, handleUnauthorized)

export const listPackages = (params) => api.get('/', { params })

export const createPackage = ({ package_details, package_features, ...data }) => api.post('/', { ...data, package_details_attributes: package_details, package_features_attributes: package_features })

export const updatePackage = ({ id, ...restData }) => {
  const { package_details, package_features, ...data } = restData
  return api.put(`/${id}`, { ...data, package_details_attributes: package_details, package_features_attributes: package_features })
}

export const showPackage = (id) => api.get(`/${id}`)

export const deletePackage = (id) => api.delete(`/${id}`)
