import { IconButton, makeStyles } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import EditIcon from '@material-ui/icons/EditOutlined'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import BillingDetail from './BillingDetail'
import { TableContext } from './TableProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  icon: {
    color: theme.palette.primary.main,
  },
  iconWA: {
    color: '#25D366',
  },
}))

const TableAction = ({ rowData }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { getShow } = useContext(TableContext)

  let phone

  if (rowData?.phone_number?.charAt(0) === '0' || rowData?.phone_number?.charAt(0) === '+') {
    phone = `62${rowData?.phone_number?.substr(1)}`
  } else if (rowData?.phone_number?.startsWith(62)) {
    phone = rowData?.phone_number
  } else {
    phone = `62${rowData?.phone_number}`
  }

  const message = `
Halo Kak ${rowData?.user_name},  saya dari tim squids mau mengkonfirmasi perihal registrasi Squids dengan rincian sebagai berikut :

Nama : ${rowData?.user_name}
Nama Toko : ${rowData?.store_name}
Domain : ${rowData?.domain}
Paket : ${rowData?.package_name}
Periode : ${rowData?.period}
Total Pembayaran : ${rowData?.formated_total}
Metode Pembayaran : ${rowData?.payment_method}
${rowData?.payment_detail}

Apakah sudah melakukan pembayaran perihal registrasi tersebut? terimakasih :)
  `

  const waMesaage = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(message)}`

  const onClickEdit = () => {
    setOpen(true)
    getShow(rowData.id)
  }

  return (
    <div className={classes.root}>
      <IconButton onClick={onClickEdit}>
        <EditIcon className={classes.icon} />
      </IconButton>
      {
        rowData?.status !== 'Paid' && (
          <IconButton onClick={() => window.open(waMesaage, '_blank')} title="Follow up">
            <WhatsAppIcon className={classes.iconWA} />
          </IconButton>
        )
      }
      <BillingDetail
        open={open}
        onClose={() => setOpen(false)}
        data={rowData}
      />
    </div>
  )
}

export default TableAction
