/* eslint-disable import/no-extraneous-dependencies */
/** @jsxImportSource @emotion/react */

import Form from '@components/dialog/Form'
import { useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Radio from '@components/field/Radio'
import AlternateCheckbox from '@components/field/AlternateCheckbox'
import Text from '@components/field/Text'
import NumberUnlimited from '@components/field/NumberUnlimited'
import NumberWatch from '@components/field/NumberWatch'
import Currency from '@components/field/Currency'
import Integration from '@components/field/Integration'
import Unlimited from '@components/field/Unlimited'
import { useEffect, useState, Fragment } from 'react'
import loadable from '@loadable/component'
import InputLabel from '@material-ui/core/InputLabel'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import useValue from './useValue'

const TextEditor = loadable(() => import('@components/field/DraftEditor'))

const schema = yup.object().shape({
  name: yup.string().required('Wajib Di Isi'),
  package_details: yup.array().of(
    yup.object().shape({
      integration_ability: yup
        .array()
        .of(yup.string())
        .min(1, 'Pilih Minimal 1'),
    })
  ),
})

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiFormLabel-root': {
      whiteSpace: 'nowrap',
    },
  },
  root: {
    borderRadius: 5,
    width: '50%',
    '& .limit': {
      borderRadius: 5,
      border: '1px solid #E5E5E5',
      padding: '17px 21px',
    },
    '& .limit > div': {
      display: 'flex',
      marginTop: 10,
    },
    '& .limit > div > div:first-of-type': {
      flex: 2,
    },
    '& .limit > div > div:last-of-type': {
      flex: 1,
    },
    '& .limit .order': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .limit .order > div': {
      maxWidth: '48%',
      flex: 1,
    },
    '& .nama > div, & .deskripsi > div': {
      width: '100%',
    },
    '& .status': {
      width: '100%',
    },
    '& .harga-domain': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    '& .harga-domain > *': {
      flex: 1,
      width: '50%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    '& .MuiAccordion-root:before': {
      display: 'none',
    },
    '& .parent-addon': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '& .MuiFormControlLabel-root': {
        width: '47.5%',
      },
    },
  },
  accords: {
    border: 'none',
    padding: 0,
    boxShadow: 'none',
    minHeight: 0,
    width: '50%',
  },
}))

export default function Dialog(props) {
  const { type, title, onSave: save } = props
  const classes = useStyles()

  const { value, open } = useValue()
  const [isAddOn, setIsAddOn] = useState(false)

  const {
    handleSubmit, register, control, reset, watch, errors,
  } = useForm({
    defaultValues: value,
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (open) {
      reset(value, {
        isDirty: false,
        touched: false,
        dirtyFields: false,
        errors: false,
      })
    }
  }, [value, reset, open])

  return (
    open && (
      <Form
        title={title}
        type={type}
        onSubmit={handleSubmit((data) => save(data))}
        className={classes.root}
        dividers
      >
        {value?.id && (
          <input type="hidden" name="id" value={value.id} ref={register} />
        )}
        {value['package_details.0.id'] && (
          <input
            type="hidden"
            name="package_details.0.id"
            value={value['package_details.0.id']}
            ref={register}
          />
        )}
        {value['package_features.0.id'] && (
          <input
            type="hidden"
            name="package_features.0.id"
            value={value['package_features.0.id']}
            ref={register}
          />
        )}
        <div className="nama">
          <Text
            label="Nama Paket"
            name="name"
            ref={register}
            error={errors?.name?.message}
          />
        </div>
        <div className="harga-domain">
          <NumberUnlimited
            label="Urutan Paket"
            name="package_details.0.position"
            control={control}
            step={1}
          />
        </div>
        <div className="status">
          <Radio
            label="Status"
            name="status"
            control={control}
            radios={[
              ['true', 'Aktif'],
              ['false', 'Tidak Aktif'],
            ]}
          />
        </div>
        <div className="harga-domain">
          <AlternateCheckbox
            style={{ marginLeft: -24 }}
            label="Billing berdasarkan omset"
            name="omzet_based_billing"
            control={control}
          />
          {watch('omzet_based_billing') && (
            <Fragment>
              <NumberUnlimited
                label="Harga Bulanan"
                name="omzet_percentage"
                control={control}
                step={1}
                endText="% dari omset"
              />
              <Currency
                label="Minimum Penarikan Saldo"
                name="package_details.0.minimum_disbursement"
                control={control}
                error={errors?.price?.minimum_disbursement}
              />
              <NumberUnlimited
                label="Biaya Xendit Dicover"
                name="package_details.0.covered_xendit_fee"
                control={control}
                step={1}
                endText="%"
              />
            </Fragment>
          )}
          {!watch('omzet_based_billing') && (
            <Fragment>
              <Currency
                label="Harga per Tahun"
                name="annual_price"
                control={control}
                error={errors?.price?.annual_price}
              />
              <Currency
                label="Harga per Bulan"
                name="monthly_price"
                control={control}
                error={errors?.price?.monthly_price}
              />
            </Fragment>
          )}
          <NumberUnlimited
            label="Komisi Affiliate"
            name="affiliate_commission"
            control={control}
            step={1}
            endText="%"
          />
          <AlternateCheckbox
            label="Sembunyikan Harga (Hubungi Admin)"
            name="package_details.0.hide_price"
            control={control}
          />
          <AlternateCheckbox
            label="Termasuk Toko Online"
            name="package_details.0.storefront_status"
            control={control}
          />
          <AlternateCheckbox
            label="Custom Domain"
            name="package_details.0.custom_domain"
            control={control}
          />
        </div>
        <Accordion
          expanded={isAddOn}
          style={{
            border: 'none',
            padding: 0,
            marginBottom: 10,
            boxShadow: 'none',
          }}
          onChange={() => {
            setIsAddOn(!isAddOn)
          }}
        >
          <AccordionSummary
            className={classes.accords}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <InputLabel disableAnimation shrink>
              Tambah Add On
            </InputLabel>
          </AccordionSummary>
          <AccordionDetails
            style={{
              border: 'none',
              padding: 0,
              boxShadow: 'none',
              minHeight: 0,
            }}
          >
            <div className="parent-addon">
              <AlternateCheckbox
                label="Custom Embedded Checkout Form"
                name="package_features.0.can_access_checkout_form"
                control={control}
              />
              <AlternateCheckbox
                label="Fitur DP/Cicilan"
                name="package_features.0.can_access_partial_payment"
                control={control}
              />
              <AlternateCheckbox
                label="Fitur Beli Titip"
                name="package_features.0.can_access_keep_stocks"
                control={control}
              />
              <AlternateCheckbox
                label="Fitur Pre Order"
                name="package_features.0.can_access_pre_order"
                control={control}
              />
              <AlternateCheckbox
                label="Fitur Asal Uang"
                name="package_features.0.can_access_money_sources"
                control={control}
              />
              <AlternateCheckbox
                label="Fitur Membership Konsumen"
                name="package_features.0.can_access_memberships"
                control={control}
              />
              <AlternateCheckbox
                label="Fitur Laporan Member"
                name="package_features.0.can_access_member_reports"
                control={control}
              />
              <AlternateCheckbox
                label="Fitur Warehousing"
                name="package_features.0.can_access_sub_warehouse"
                control={control}
              />
              <AlternateCheckbox
                label="Fitur Dropship ke Pusat"
                name="package_features.0.can_access_internal_dropships"
                control={control}
              />
              <AlternateCheckbox
                label="Fitur Diskon Bertingkat Berdasarkan Kuantitas"
                name="package_features.0.can_access_tiered_quantity_rate_discount"
                control={control}
              />
              <AlternateCheckbox
                label="Fitur Harga Eceran"
                name="package_features.0.can_access_price_variation"
                control={control}
              />
              <AlternateCheckbox
                label="Fitur Kunjungan Member"
                name="package_features.0.can_access_daily_visit"
                control={control}
              />
              <AlternateCheckbox
                label="Fitur Voucher"
                name="package_features.0.can_access_voucher"
                control={control}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <div className="deskripsi">
          {/* <Text label="Deskripsi" name="description" ref={register} /> */}
          {/* <div>Deskripsi</div> */}
          <InputLabel disableAnimation shrink>
            Deskripsi
          </InputLabel>
          <TextEditor label="Deskripsi" name="description" control={control} />
        </div>
        <h4>Limit Paket</h4>
        <div className="limit">
          <div>
            <NumberUnlimited
              label="Jumlah Store User"
              name="package_details.0.user_total"
              control={control}
              step={100}
              placeholder="User"
              endText="User"
            />
            <div>
              <Unlimited
                label="Unlimited"
                name="package_details.0.user_total"
                control={control}
                watch={watch}
              />
            </div>
          </div>
          <div>
            <div className="order">
              <NumberUnlimited
                label="Jumlah Order"
                name="package_details.0.order_per_day"
                control={control}
                step={100}
                placeholder="Order"
              />
              {/* <Select
              label="Dalam"
              name="package_details.0.order_in_days"
              control={control}
              selects={[[30, '1 Bulan'], [90, '3 Bulan'], [180, '6 Bulan'], [360, '1 Tahun']]}
            /> */}
              <NumberWatch
                label="Dalam"
                name="package_details.0.order_in_days"
                control={control}
                placeholder="Hari"
                disabled={
                  `${watch('package_details.0.order_per_day')}` === '-1'
                }
              />
            </div>
            <div>
              <Unlimited
                label="Unlimited"
                name="package_details.0.order_per_day"
                control={control}
                watch={watch}
              />
            </div>
          </div>
          <div>
            <NumberUnlimited
              label="Jumlah Produk"
              name="package_details.0.product_total"
              control={control}
              step={10}
              endText="Produk"
            />
            <div>
              <Unlimited
                label="Unlimited"
                name="package_details.0.product_total"
                control={control}
                watch={watch}
              />
            </div>
          </div>
          <div>
            <NumberUnlimited
              label="Jumlah Reseller"
              name="package_details.0.reseller_total"
              control={control}
              step={10}
              endText="Reseler"
            />
            <div>
              <Unlimited
                label="Unlimited"
                name="package_details.0.reseller_total"
                control={control}
                watch={watch}
              />
            </div>
          </div>
          <div>
            <NumberUnlimited
              label="Warehouse Limit"
              name="package_details.0.warehouse_limit"
              control={control}
              step={1}
              endText="Warehouse"
            />
            <div>
              <Unlimited
                label="Unlimited"
                name="package_details.0.warehouse_limit"
                control={control}
                watch={watch}
              />
            </div>
          </div>
          <div>
            <NumberUnlimited
              label="Waktu Trial"
              name="package_details.0.trial_time"
              control={control}
              step={1}
              endText="Hari"
            />
            <div>
              <Unlimited
                label="Unlimited"
                name="package_details.0.trial_time"
                control={control}
                watch={watch}
              />
            </div>
          </div>
          <div className="sistem-integrasi">
            <Integration
              name="package_details.0.integration_ability"
              control={control}
              error={errors?.package_details?.[0]?.integration_ability.message}
            />
          </div>
          {/* <DevTool control={control} /> */}
        </div>
      </Form>
    )
  )
}
