import { useQuery } from 'react-query'
import { listVoucherUsage } from '@lib/axios/voucherUsage'
import { useMemo } from 'react'
import useQueryString from '@hooks/useQueryString'

function useIndex() {
  const { params } = useQueryString()

  const { status, data } = useQuery(
    ['saas-voucher-usage', params],
    () => listVoucherUsage(params),
    {
      retry: false,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  )

  // prettier-ignore
  const tableData = useMemo(
    () => (status === 'success' && Array.isArray(data?.data?.data)
      ? data?.data?.data
      : []),
    [status, data]
  )

  const emptyPagination = useMemo(
    () => ({
      current_page: 1,
      first_page: true,
      last_page: false,
      next_page: null,
      prev_page: null,
      total_pages: 1,
    }),
    []
  )

  // prettier-ignore
  const tablePagination = useMemo(
    () => (status === 'success' && data?.data?.pagination
      ? data?.data?.pagination
      : emptyPagination),
    [status, data, emptyPagination]
  )

  const columns = [
    ['id', 'ID', false],
    ['store_name', 'Nama Toko', false],
    ['store_domain', 'Domain', false],
    ['voucher_package_code', 'Kode Voucher', false],
    ['invoice_total', 'Harga', false],
    ['invoice_discount', 'Potongan', false],
  ]

  const tableColumns = columns
    .map(([column, title]) => [column, title])
    .map(([accessor, Header]) => ({ accessor, Header }))

  const hiddenColumns = columns
    .filter(([, , hidden]) => hidden)
    .map(([column]) => column)

  return {
    tableData,
    tableColumns,
    hiddenColumns,
    tablePagination,
  }
}

export default useIndex
