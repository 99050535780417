/** @jsxImportSource @emotion/react */

import { securedRoutePath } from '@lib/constant'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { useHistory } from 'react-router-dom'

import myAccountSVG from './svg/myAccount.svg'

function MyAccount(props) {
  const history = useHistory()
  const { onClick: handleClose } = props

  const handleClick = (e) => {
    handleClose(e)
    history.push(`${securedRoutePath}/akun`)
  }

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        <img src={myAccountSVG} alt="" />
      </ListItemIcon>
      Akun Saya
    </MenuItem>
  )
}

export default MyAccount
