/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import useIndex from '@lib/query/admin/useIndex'
import {
  Fragment,
  useMemo,
  useState,
} from 'react'
import useQueryString from '@hooks/useQueryString'
import { css } from '@emotion/react'
import DataTable from '@components/DataTable'
import PageTitle from '@components/PageTitle'
import { Helmet } from 'react-helmet'
import Layout from '@layout/Main'
import Card from '@components/card/Base'
import Tambah from '@components/button/Tambah'

import useCreate from '@lib/query/admin/useCreate'
import useUpdate from '@lib/query/admin/useUpdate'
import action from './action'
import Form from './Form'

import defaultValues from './defaultValues'
import Toolbar from './Toolbar'

const CSS = css`
   thead tr > *:last-of-type {
    width: 200px;
    div {
      text-align: center;
    }
  }
  & tbody tr > *:last-of-type div {
    display: flex;
    justify-content: space-around;
  }
`

const addButtonCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > *:first-of-type {
    margin-left: 10px;
  }
`

const titleCSS = css`
  display: flex;
`
function Admin() {
  const create = useCreate()
  const update = useUpdate()
  const {
    setQueries,
  } = useQueryString()
  const [{ pageSize }, setTableState] = useState({
    pageIndex: 0,
    pageSize: 0,
    sortBy: [],
  })

  const {
    tableData,
    tableColumns,
    hiddenColumns,
    tablePagination,
  } = useIndex()

  return (
    <Fragment>
      <Helmet>
        <title>
          SQUIDS :: MANAJEMEN ADMIN
        </title>
      </Helmet>
      <PageTitle>
        Manajemen Admin
      </PageTitle>
      <Layout>
        <Card>
          <div css={titleCSS}>
            <h3>Manajemen Admin</h3>
            <div css={addButtonCSS}>
              <Tambah type="create" />
            </div>
          </div>
          <Toolbar />
          <DataTable
            css={CSS}
            data={tableData}
            columns={useMemo(() => tableColumns, [tableColumns])}
            hooks={action}
            hiddenColumns={hiddenColumns}
            pagination={tablePagination}
            onChange={setTableState}
          />
        </Card>
      </Layout>
      <Form
        title="Tambah Admin"
        type="create"
        defaultValues={defaultValues}
        onSave={create}
      />
      <Form
        title="Ubah Admin"
        type="edit"
        defaultValues={defaultValues}
        onSave={update}
      />
    </Fragment>
  )
}

export default Admin
