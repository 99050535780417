/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import axios from 'axios'
import {
  setBearerToken,
  handleUnauthorized,
  normalizeResponse,
} from '@lib/axios/interceptor'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { authApiBaseURL } from '@lib/env'
import { TableContext } from './TableProvider'

const useStyles = makeStyles((theme) => ({
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    backgroundColor: '#F6F9FB',
    '&.MuiInputBase-root': {
      '&.MuiOutlinedInput-root': {
        height: 45,
        outline: 'none !important',
        border: 'none',
      },
    },
  },
}))

const FilterDialog = ({ open, onClose }) => {
  const classes = useStyles()
  const d = new Date()
  const { queryPush, queryRemove, params } = useContext(TableContext)
  const [packages, setPackages] = useState([])
  const [selectedPackage, setSelectedPackage] = useState(
    params?.package_id || ''
  )
  const [selectedStatus, setSelectedStatus] = useState(params?.status || '')
  const [selectedStore, setSelectedStore] = useState(params?.store_name || '')
  const [selectedName, setSelectedName] = useState(params?.store_name || '')
  const [endDate, setEndDate] = useState(new Date())
  const [startDate, setStartDate] = useState(d.setDate(d.getDay() - 30))
  const [startExpiredDate, setStartExpiredDate] = useState(
    d.setDate(d.getDay() - 30)
  )
  const [endExpiredDate, setEndExpiredDate] = useState(
    params?.startExpiredDate || params?.expired_at || new Date()
  )
  const [filter, setFilter] = useState({})

  const onChangeUser = (e) => {
    const { value } = e.target
    setSelectedName(value)
    setFilter({ ...filter, user_name: value })
  }

  const onChangeStore = (e) => {
    const { value } = e.target
    setSelectedStore(value)
    setFilter({ ...filter, store_name: value })
  }

  const onChangePackage = (e) => {
    const { value } = e.target
    setSelectedPackage(value)
    setFilter({ ...filter, package_id: value })
  }

  const onChangeStatus = (e) => {
    const { value } = e.target
    setSelectedStatus(value)
    setFilter({ ...filter, status: value })
  }

  const clearAll = () => {
    setFilter({})
    setSelectedName('')
    setSelectedStore('')
    setSelectedPackage('')
    setSelectedStatus('')
    setStartDate(new Date())
    setEndDate(new Date())
    setStartExpiredDate(new Date())
    setEndExpiredDate(new Date())
    const remove = [
      'start_date',
      'user_name',
      'store_name',
      'end_date',
      'status',
      'package_id',
      'start_expired_date',
      'end_expired_date',
    ]
    queryRemove(remove)
    onClose()
  }

  const onFilter = () => {
    queryPush(filter)
    onClose()
  }

  const clearFilter = (name) => {
    setFilter({ ...filter, [name]: '' })
    queryRemove([name])
  }

  const handleStartDateChange = (date) => {
    setStartDate(date)
    setFilter({ ...filter, start_date: date })
  }
  const handleExpiredDateChange = (date, name) => {
    setFilter({ ...filter, [name]: date })
  }
  const handleEndDateChange = (date) => {
    setEndDate(date)
    setFilter({ ...filter, end_date: date })
  }

  const renderClearIcon = (name) => filter[name] !== '' && (
  <Tooltip title="clear">
    <IconButton onClick={() => clearFilter(name)}>
      <CloseIcon />
    </IconButton>
  </Tooltip>
  )

  const api = axios.create({
    baseURL: authApiBaseURL,
  })

  api.defaults.headers.common.Accept = 'application/json'
  api.interceptors.request.use(setBearerToken)
  api.interceptors.response.use(normalizeResponse, handleUnauthorized)

  const getPackages = async (e) => {
    try {
      const {
        status,
        data: { data },
      } = await api.get('/api/v1/packages', {
        params: { name: e?.target?.value || '', per_page: 100 },
      })
      if (status === 'ok') {
        setPackages(data)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  useEffect(() => {
    getPackages()
  }, [])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Filter Data</DialogTitle>
      <DialogContent>
        <div className={classes.fieldWrapper}>
          <Typography color="textSecondary">Dari Tanggal</Typography>
          <KeyboardDatePicker
            clearable
            value={startDate}
            maxDate={endDate}
            onChange={(date) => handleStartDateChange(date)}
            format="dd-MM-yyyy"
            className={classes.textField}
            InputProps={{
              classes: { root: classes.textField },
            }}
          />
        </div>
        <div className={classes.fieldWrapper}>
          <Typography color="textSecondary">Sampai Tanggal</Typography>
          <KeyboardDatePicker
            clearable
            value={endDate}
            onChange={(date) => handleEndDateChange(date)}
            format="dd-MM-yyyy"
            minDate={startDate}
            className={classes.textField}
            InputProps={{
              classes: { root: classes.textField },
            }}
          />
        </div>
        <div className={classes.fieldWrapper}>
          <Typography color="textSecondary">Expired dari Tanggal</Typography>
          <KeyboardDatePicker
            clearable
            value={startExpiredDate}
            onChange={(date) => {
              setStartExpiredDate(date)
              handleExpiredDateChange(date, 'start_expired_date')
            }}
            format="dd-MM-yyyy"
            className={classes.textField}
            InputProps={{
              classes: { root: classes.textField },
            }}
          />
        </div>
        <div className={classes.fieldWrapper}>
          <Typography color="textSecondary">Expired sampai Tanggal</Typography>
          <KeyboardDatePicker
            clearable
            value={endExpiredDate}
            onChange={(date) => {
              setEndExpiredDate(date)
              handleExpiredDateChange(date, 'end_expired_date')
            }}
            minData={startExpiredDate}
            format="dd-MM-yyyy"
            className={classes.textField}
            InputProps={{
              classes: { root: classes.textField },
            }}
          />
        </div>
        <div className={classes.fieldWrapper}>
          <Typography color="textSecondary">Nama User</Typography>
          <TextField
            id="user"
            type="text"
            value={selectedName}
            name="user_name"
            onChange={onChangeUser}
            className={classes.textField}
            InputProps={{
              endAdornment: renderClearIcon('user_name'),
              classes: { root: classes.textField },
            }}
          />
        </div>
        <div className={classes.fieldWrapper}>
          <Typography color="textSecondary">Nama Toko</Typography>
          <TextField
            id="store"
            type="text"
            value={selectedStore}
            name="store_name"
            onChange={onChangeStore}
            className={classes.textField}
            InputProps={{
              endAdornment: renderClearIcon('store_name'),
              classes: { root: classes.textField },
            }}
          />
        </div>
        <div className={classes.fieldWrapper}>
          <Typography color="textSecondary">Paket</Typography>
          <Select
            name="package_id"
            variant="outlined"
            value={selectedPackage}
            className={classes.textField}
            onChange={onChangePackage}
            placeholder="Pilih Paket"
          >
            {packages.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={classes.fieldWrapper}>
          <Typography color="textSecondary">Status</Typography>
          <Select
            name="status"
            variant="outlined"
            className={classes.textField}
            value={selectedStatus}
            onChange={onChangeStatus}
            placeholder="Pilih Paket"
          >
            <MenuItem value="" />
            <MenuItem value="open">Menunggu Pembayaran</MenuItem>
            <MenuItem value="expired">Kedaluarsa</MenuItem>
            <MenuItem value="paid">Dibayar</MenuItem>
          </Select>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onFilter}>
          Terapkan
        </Button>
        <Button variant="contained" color="secondary" onClick={clearAll}>
          Reset
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Batal
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default FilterDialog
