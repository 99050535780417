/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import Checkbox from '@material-ui/core/Checkbox'

const cbCSS = css`
  width: 30px;
`

const cbCSS2 = css`
  & .MuiSvgIcon-root {
    font-size: 18px;
  }
  &.MuiIconButton-root {
    padding: 6px;
  }
  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }
`

const rowCheckbox = {
  id: 'selection',
  Header: ({ getToggleAllRowsSelectedProps }) => (
    <div css={cbCSS}>
      <Checkbox css={cbCSS2} size="small" {...getToggleAllRowsSelectedProps()} />
    </div>
  ),
  Cell: ({ row }) => (
    <div css={cbCSS}>
      <Checkbox css={cbCSS2} size="small" {...row.getToggleRowSelectedProps()} />
    </div>
  ),
}

export default rowCheckbox
