/** @jsxImportSource @emotion/react */

import Form from '@components/dialog/Form'
import { useForm } from 'react-hook-form'
import { css } from '@emotion/react'
import Text from '@components/field/Text'
import { useEffect } from 'react'
import useListStores from '@lib/query/setupXenplatform/useListStores'
import Select from '@components/field/Select'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { rules, schema } from '@lib/validation'
import useValue from './useValue'

const CSS = css`
  min-width: 829px;
  border-radius: 5px;
  .MuiDialogContent-root {
    padding: 32px;
  }
  .field-wrap {
    width: 381px;
    display: flex;
    flex-direction: column;
  }
  .field-wrap > div {
    flex: 1;
    margin-bottom: 10px;
  }
  .field-wrap .bank {
    display: flex;
    justify-content: space-between;
  }
  .field-wrap .bank > div {
    max-width: 48%;
  }
  .MuiFormControl-root {
    width: 100%;
  }
`

export default function Dialog(props) {
  const {
    type, title, onSave: save, defaultValues,
  } = props

  const { value, open } = useValue(defaultValues)
  const selectQuery = useListStores()
  const selectQueryMap = () => selectQuery?.length > 0
    && selectQuery.map(({ id, name }) => [`${id}`, name])

  const {
    handleSubmit, register, reset, control, errors,
  } = useForm({
    defaultValues,
    resolver: yupResolver(
      schema(type === 'create-xenid' ? {
        xenplatform_id: rules.stringRequired,
      } : {
        email: rules.email,
        public_profile: yup.object().shape({
          business_name: rules.stringRequired,
        }),
        account_type: rules.stringRequired,
        store_id: yup.string().required('Toko harus dipilih'),
      })
    ),
  })
  useEffect(() => {
    if (open) {
      reset(value, {
        isDirty: false,
        touched: false,
        dirtyFields: false,
        errors: false,
      })
    }
  }, [value, reset, open])

  return (
    <Form
      title={title}
      type={type}
      onSubmit={handleSubmit((data) => save(data))}
      css={CSS}
    >
      <div className="field-wrap">
        {value?.id && (
          <input name="id" type="hidden" value={value.id} ref={register} />
        )}
        {type === 'create-xenid' ? (
          <Text label="xenPlatform ID" name="xenplatform_id" ref={register} />
        ) : (
          <div style={{ width: '100%' }}>
            <Text
              label="Email"
              name="email"
              ref={register}
              error={errors?.email?.message}
            />
            <Text
              label="Nama Bisnis"
              name="public_profile.business_name"
              ref={register}
              error={errors?.public_profile?.business_name?.message}
            />
            <Select
              label="Pilih Tipe Akun"
              name="account_type"
              error={errors?.account_type?.message}
              control={control}
              selects={[
                ['MANAGED', 'Managed'],
                ['OWNED', 'Owned'],
              ]}
            />
            <Select
              label="Pilih Toko"
              error={errors?.store_id?.message}
              name="store_id"
              control={control}
              selects={selectQueryMap}
            />

          </div>
        )}
      </div>
    </Form>
  )
}
