/* eslint-disable import/no-extraneous-dependencies */
/** @jsxImportSource @emotion/react */

import { Controller } from 'react-hook-form'
import { Fragment, useState } from 'react'
import { InputLabel, makeStyles, FormHelperText } from '@material-ui/core/'
import { KeyboardDatePicker } from '@material-ui/pickers'
import './style.css'

const useStyles = makeStyles(() => ({
  textField: {
    backgroundColor: '#F6F9FB',
    width: '100%',
    '&.MuiInputBase-root': {
      '&.MuiOutlinedInput-root': {
        height: 45,
        outline: 'none !important',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, .02)',
      },
    },
  },
}))

function RangeDateField(props) {
  const classes = useStyles()
  const {
    label, control, errors, startDate, setValue,
  } = props

  const [start, setStart] = useState(startDate)

  return (
    <Fragment>
      <InputLabel shrink>{label}</InputLabel>
      <div style={{ display: 'flex' }}>
        <Controller
          control={control}
          name="start_date"
          render={
          ({ onChange: handleChange, value }) => (
            <Fragment>
              <KeyboardDatePicker
                value={value}
                onChange={(date) => {
                  handleChange(date)
                  setValue('end_date', date)
                  setStart(date)
                }}
                format="dd-MM-yyyy"
                style={{ width: '50%', marginRight: 10 }}
                InputProps={{
                  classes: { root: classes.textField },
                }}
              />
              <FormHelperText error={!!errors?.start_date?.message}>
                {errors?.start_date?.message || <Fragment>&nbsp;</Fragment>}
              </FormHelperText>
            </Fragment>
          )
        }
        />
        <Controller
          control={control}
          name="end_date"
          render={
          ({ onChange: handleChange, value }) => (
            <Fragment>
              <KeyboardDatePicker
                value={value}
                onChange={handleChange}
                format="dd-MM-yyyy"
                minDate={start}
                style={{ width: '50%' }}
                disablePast
                InputProps={{
                  classes: { root: classes.textField },
                }}
              />
              <FormHelperText error={!!errors?.end_date?.message}>
                {errors?.end_date?.message || <Fragment>&nbsp;</Fragment>}
              </FormHelperText>
            </Fragment>
          )
        }
        />
      </div>
    </Fragment>
  )
}

export default RangeDateField
