/** @jsxImportSource @emotion/react */

import { Fragment, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import PageTitle from '@components/PageTitle'
import Layout from '@layout/Main'
import Card from '@components/card/Base'
import DataTable from '@components/DataTable'
import useIndex from '@lib/query/setupStoreDashboardPage/useIndex'
import Tambah from '@components/button/Tambah'
import useCreate from '@lib/query/setupStoreDashboardPage/useCreate'
import useUpdate from '@lib/query/setupStoreDashboardPage/useUpdate'
import { css } from '@emotion/react'
import useQueryString from '@hooks/useQueryString'
import Toolbar from './Toolbar'
import action from './action'
import Form from './Form'

const titleCSS = css`
  display: flex;
`

const addButtonCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > *:first-of-type {
    margin-left: 10px;
  }
`

function useOnTableChange() {
  const { setQueries, setOrderBy } = useQueryString()
  return useMemo(() => (args) => {
    const id = args?.[0]?.id
    const set_order = args?.[0]?.desc ? 'desc' : 'asc'
    const order_by = {
      'parent.name': 'parents_sync_pages.name',
      name: 'sync_pages.name',
      order: 'sync_pages.order',
    }[id]
    const valid = ['parent.name', 'name', 'order'].includes(id)
    if (valid) {
      setQueries({
        set_order,
        order_by,
      })
    } else {
      setOrderBy(undefined)
      setQueries({ set_order: undefined })
    }
  }, [setOrderBy, setQueries])
}

function SetupStoreDashboardPage() {
  const result = useIndex()
  const create = useCreate()
  const update = useUpdate()
  const onTableChange = useOnTableChange()

  const {
    tableData,
    tableColumns,
    hiddenColumns,
    tablePagination,
  } = result

  return (
    <Fragment>
      <Helmet>
        <title>
          SQUIDS :: Setup Store Dashboard Page
        </title>
      </Helmet>
      <PageTitle>
        Setup Store Dashboard Page
      </PageTitle>
      <Layout>
        <Card>
          <div css={titleCSS}>
            <h3>Setup Store Dashboard Page</h3>
            <div css={addButtonCSS}>
              <Tambah type="create" />
            </div>
          </div>
          <Toolbar />
          <DataTable
            data={tableData}
            columns={useMemo(() => tableColumns, [tableColumns])}
            hooks={action}
            hiddenColumns={hiddenColumns}
            pagination={tablePagination}
            onChange={onTableChange}
          />
        </Card>
      </Layout>
      <Form
        title="Tambah Store Dashboard Page"
        type="create"
        defaultValues={{
          name: '',
          parent_id: null,
          order: 0,
          path: '',
          icon: '',
          parent: null,
        }}
        onSave={create}
      />
      <Form
        title="Ubah Store Dashboard Page"
        type="edit"
        defaultValues={{
          name: '',
          parent_id: null,
          order: 0,
          path: '',
          icon: '',
          parent: null,
        }}
        onSave={update}
      />
    </Fragment>
  )
}

export default SetupStoreDashboardPage
