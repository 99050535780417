/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core'
import React, {
  useContext, useState, useEffect,
} from 'react'
import { api } from '@lib/axios/admin'

import { useSnackbar } from 'notistack'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ReactComponent as Folder } from '@assets/dialog/folderFig.svg'
import { ReactComponent as Border } from '@assets/dialog/border.svg'
import { ReactComponent as Cloud } from '@assets/dialog/cloud.svg'
import { TableContext } from './TableProvider'
import useStyles from './tableBilling-jss'
import currencyFormatter from './currencyFormat'

const whiteList = ['image/jpeg', 'image/png', 'image/jpg']

const FormDialog = ({ open, onClose }) => {
  const classes = useStyles()
  const {
    getIndex, showData, setOpenDialog,
  } = useContext(TableContext)

  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState(new Date())
  const [status, setStatus] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const [fileHandler, setFileHandler] = useState(null)
  useEffect(() => {
    setStatus(showData?.status || 'pending')
    setDate(showData?.transfered_at || new Date())
  }, [showData])

  const editData = async (params) => {
    try {
      setLoading(true)
      const { status } = await api.put(`/affiliate_withdraws/${showData.id}`, params)
      if (status === 'ok') {
        setLoading(false)
        enqueueSnackbar('Berhasil merubah data', { variant: 'success' })
        setOpenDialog(false)
        getIndex()
      }
    } catch (error) {
      setLoading(false)
      if (error?.response) {
        const { data } = error.response
        data.map((item) => enqueueSnackbar(item, { variant: 'error' }))
      } else {
        enqueueSnackbar('Maaf, terjadi kesalahan saat merubah data', { variant: 'error' })
      }
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const data = new FormData()
    if (fileHandler) {
      data.append('affiliate_withdraw[approved_attachment]', fileHandler[0])
    }
    data.append('affiliate_withdraw[status]', status)
    data.append('affiliate_withdraw[transfered_at]', date)

    editData(data)
  }

  const handleDateChange = (date) => {
    setDate(date)
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
    >
      <form onSubmit={onSubmit}>
        <DialogTitle>&nbsp;</DialogTitle>
        <DialogContent>
          <Folder className={classes.svgFolder} />
          <div className={classes.partition}>
            <div className={classes.leftSide}>
              <Typography
                variant="h3"
                className={classes.txHeader}
                gutterBottom
              >
                Upload Bukti Transfer
              </Typography>
              <Typography
                variant="h5"
                className={classes.subHeader}
              >
                Tarik dan letakkan file anda disini!
              </Typography>
              <div className={classes.uploadBox}>
                <Border className={classes.borderBox} />
                {
                  Boolean(fileHandler?.length) && whiteList.includes(fileHandler[0].type) ? (
                    <img className={classes.imagePreview} src={URL.createObjectURL(fileHandler[0])} alt="payment" />
                  ) : (
                    showData?.approved_attachment?.url && (
                      <img className={classes.imagePreview} src={showData?.approved_attachment?.url} alt="payment" />
                    )
                  )
                }
                <div className={classes.boxContent}>
                  <Cloud />
                  <div className={classes.extensionsList}>
                    <Typography>
                      .PNG &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;.JPG&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; .PDF
                    </Typography>
                  </div>
                  <Typography
                    variant="h5"
                    className={classes.subHeader}
                  >
                    Anda juga dapat mengunggah file dengan
                  </Typography>
                  <Typography className={classes.txUnderlined}>
                    mengklik disini
                  </Typography>
                </div>
                <input onChange={(e) => setFileHandler(e.target.files)} className={classes.uploadArea} name="approved_attachment" type="file" accept=".jpg, .jpeg, .png, .pdf" multiple="false" />
              </div>
            </div>
            <div className={classes.fieldWrapper}>
              <Typography color="textSecondary">Status</Typography>
              <Select
                name="status"
                variant="outlined"
                defaultValue={status}
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className={classes.selectPage}
              >
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="processed">Diproses</MenuItem>
                <MenuItem value="canceled">Batal</MenuItem>
              </Select>
              <div className={classes.fieldWrapper}>
                <Typography color="textSecondary">Tanggal Transfer</Typography>
                <KeyboardDatePicker
                  onChange={handleDateChange}
                  format="dd MMMM yyyy"
                  className={classes.textField}
                  value={date}
                  InputProps={{
                    classes: { root: classes.textField },
                  }}
                />
              </div>
              <div className={classes.fieldWrapper}>
                <Typography color="textSecondary">Nama Affiliate</Typography>
                <div className={classes.fakeInput}>
                  {showData?.affiliate_user_name || ''}
                </div>
              </div>
              <div className={classes.fieldWrapper}>
                <Typography color="textSecondary">Amount Withdraw</Typography>
                <div className={classes.fakeInput}>
                  {currencyFormatter(showData?.amount || 0)}
                </div>
              </div>
              <div className={classes.fieldWrapper}>
                <Typography color="textSecondary">Saldo Tersedia</Typography>
                <div className={classes.fakeInput}>
                  {currencyFormatter(showData?.current_balance || 0)}
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="outlined"
            onClick={onClose}
            disabled={loading}
          >
            Batal
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Simpan'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
export default FormDialog
