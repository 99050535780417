/** @jsxImportSource @emotion/react */
/* eslint-disable no-unused-vars */

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'

function Field(props) {
  const {
    value,
    onChange: handleChange,
    radios,
    inputRef,
    name,
  } = props
  return (
    <RadioGroup
      value={value}
      onChange={handleChange}
      ref={inputRef}
      name={name}
    >
      {
        radios
        && Array.isArray(radios)
        && radios.map((radio) => (
          <FormControlLabel
            key={Array.isArray(radio) ? radio[0] : radio}
            value={Array.isArray(radio) ? radio[0] : radio}
            control={<Radio size="small" />}
            label={Array.isArray(radio) ? radio[1] : radio}
          />
        ))
      }
    </RadioGroup>
  )
}

export default Field
