/** @jsxImportSource @emotion/react */
/* eslint-disable no-console */

import useIndex from '@lib/query/theme/useIndex'
import { Fragment, useMemo } from 'react'
import { css } from '@emotion/react'
import DataTable from '@components/DataTable'
import PageTitle from '@components/PageTitle'
import { Helmet } from 'react-helmet'
import Layout from '@layout/Main'
import Card from '@components/card/Base'

import Tambah from '@components/button/Tambah'

import useCreate from '@lib/query/theme/useCreate'
import useUpdate from '@lib/query/theme/useUpdate'

import action from './action'
import Form from './Form'
import defaultValues from './defaultValues'
import Toolbar from './Toolbar'

const CSS = css`
   thead tr > *:last-of-type {
    width: 200px;
    div {
      text-align: center;
    }
  }
  & tbody tr > *:last-of-type div {
    display: flex;
    justify-content: space-around;
  }
`

const addButtonCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > *:first-of-type {
    margin-left: 10px;
  }
`

const titleCSS = css`
  display: flex;
`
function Admin() {
  const onTableChange = () => null

  const {
    tableData,
    tableColumns,
    hiddenColumns,
    tablePagination,
  } = useIndex()

  const create = useCreate()
  const update = useUpdate()

  return (
    <Fragment>
      <Helmet>
        <title>SQUIDS :: MANAJEMEN TEMA TOKO ONLINE</title>
      </Helmet>
      <PageTitle>Manajemen Tema Toko Online</PageTitle>
      <Layout>
        <Card>
          <div css={titleCSS}>
            <h3>Manajemen Tema Toko Online</h3>
            <div css={addButtonCSS}>
              <Tambah type="create" />
            </div>
          </div>
          <Toolbar />
          <DataTable
            css={CSS}
            data={tableData}
            columns={useMemo(() => tableColumns, [tableColumns])}
            hooks={action}
            hiddenColumns={hiddenColumns}
            pagination={tablePagination}
            onChange={onTableChange}
          />
        </Card>
      </Layout>
      <Form
        type="create"
        title="Tambah Tema Toko Online"
        defaultValues={defaultValues}
        onSave={create}
      />
      <Form
        type="edit"
        title="Ubah Tema Toko Online"
        defaultValues={defaultValues}
        onSave={update}
      />
    </Fragment>
  )
}

export default Admin
