import { adminApiBaseURL } from '@lib/env'
import Axios from 'axios'
import qs from 'qs'
import { setBearerToken, handleUnauthorized, normalizeResponse } from './interceptor'

export const api = Axios.create({
  baseURL: adminApiBaseURL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
})
api.defaults.headers.common.Accept = 'application/json'
api.interceptors.request.use(setBearerToken)
api.interceptors.response.use(normalizeResponse, handleUnauthorized)

export const getSaasAdmins = (params) => api.get('/', { params })

export const createSaasAdmins = (name, email, saas_role_id) => api.post('/', {
  saas_admin: {
    name,
    email,
    saas_role_id,
  },
})

export const askChangePasswordSaasAdmins = (email) => api.post('/password', {
  email,
})

// -soeMxgs16jLHTKWdZnD
export const changePasswordSaasAdmins = (token, password, passwordConfirmation) => api.put('/password', {
  reset_password_token: token,
  saas_admin: {
    password,
    password_confirmation: passwordConfirmation,
  },
})

export const getTokenChangePasswordSaasAdmins = (token) => api.get(`/password/edit?reset_password_token=${token}`)

export const updateSaasAdmins = (id, { name, saas_role_id, activation_status }) => api.put(`/${id}`, {
  saas_admin: {
    name, saas_role_id, status: activation_status === 'active',
  },
})

export const showSaasAdmins = (id) => api.get(`/${id}`)

export const suspendSaasAdmins = (id) => api.post(`/${id}/suspend`)

export const deleteSaasAdmins = (id) => api.delete(`/${id}`)

export const getSaasRoles = () => api.get('/saas_roles')

export const createSaasRoles = (name) => api.post('/saas_roles', {
  saas_role: {
    name,
  },
})
