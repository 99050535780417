/** @jsxImportSource @emotion/react */

import { securedRoutePath } from '@lib/constant'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { useHistory } from 'react-router-dom'
import changePasswordSVG from './svg/changePassword.svg'

function ChangePassword(props) {
  const history = useHistory()
  const { onClick: handleClose } = props

  const handleClick = (e) => {
    handleClose(e)
    history.push(`${securedRoutePath}/akun/password`)
  }

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        <img src={changePasswordSVG} alt="" />
      </ListItemIcon>
      Ubah Kata Sandi
    </MenuItem>
  )
}

export default ChangePassword
