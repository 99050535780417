import { Fragment } from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import useCreate from '@lib/query/pg/useCreate'
import useUpdate from '@lib/query/pg/useUpdate'
import Selection from './Selection'
import Moota from './Moota'
import Form from './Form'

function Integration() {
  const { path } = useRouteMatch()
  const create = useCreate()
  const update = useUpdate()
  return (
    <Fragment>
      <Switch>
        <Route exact path={`${path}`} component={Selection} />
        <Route exact path={`${path}/moota`} component={Moota} />
      </Switch>
      <Form
        title="Ubah Payment Gateway"
        type="edit.pg"
        defaultValues={{
          secret_api_key: '',
          verification_token: '',
        }}
        onSave={({ id, ...data }) => (id ? update({ id, ...data }) : create({ ...data }))}
      />
    </Fragment>
  )
}

export default Integration
