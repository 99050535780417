/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import MuiButton from '@material-ui/core/Button'

const buttonCSS = css`
  &.MuiButton-root {
    border-radius: 5px;
    padding: 4px 9px;
    margin-right: 6px;
  }
  &.MuiButton-textPrimary {
    background-color: #556FB5;
  }
  .MuiButton-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    text-transform: none;
    color: #FFFFFF;
    width: 62px;
  }
`
function Button(props) {
  const {
    children, className, onClick: handleClick, type,
  } = props
  return (
    <MuiButton
      type={type}
      disableElevation
      variant="contained"
      css={buttonCSS}
      className={className}
      onClick={handleClick}
    >
      {
        children
      }
    </MuiButton>
  )
}

export default Button
