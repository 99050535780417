import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { useContext, useState } from 'react'
import { TableContext } from './TableProvider'
import { deleteApi } from '../api'

const DeleteDialog = ({
  open,
  onClose,
}) => {
  const {
    id, getIndex,
  } = useContext(TableContext)
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const onDelete = async () => {
    try {
      setLoading(true)
      const { status } = await deleteApi(id)

      if (status === 'ok') {
        setLoading(false)
        onClose()
        getIndex()
        enqueueSnackbar('Berhasil menghapus bank', { variant: 'success' })
      } else {
        setLoading(false)
        getIndex()
        enqueueSnackbar('Gagal menghapus bank', { variant: 'error' })
      }
    } catch (error) {
      setLoading(false)
      enqueueSnackbar('Gagal menghapus bank', { variant: 'error' })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        Hapus Data
      </DialogTitle>
      <DialogContent>
        <Typography>
          Anda yakin akan menghapus data ini?
        </Typography>
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          variant="outlined"
          onClick={onClose}
          disabled={loading}
        >
          Tidak
        </Button>
        <Button
          variant="contained"
          onClick={onDelete}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} color="secondary" /> : 'Ya'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default DeleteDialog
