/** @jsxImportSource @emotion/react */

import InputBase from '@material-ui/core/InputBase'
import { css } from '@emotion/react'

const inputCSS = css`
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .02);
    background-color: rgba(103, 103, 103, 0.41);
  &.Mui-focused {
    border: 1px solid rgba(0, 0, 0, .1);
  }
  & .MuiInputBase-input {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 43px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 10px;
    height: 45px;
  }
  & .MuiSelect-select.MuiSelect-select {
    padding-left: 10px;
  }
  & .MuiSelect-select:focus {
    background-color: #F6F9FB;
  }
`

function Field() {
  return (
    <InputBase
      inputProps={{ autoComplete: 'off', spellCheck: 'false' }}
      defaultValue="Unlimited"
      disabled
      css={inputCSS}
    />
  )
}

export default Field
