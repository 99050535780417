import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  list: {
    flex: 1,
    width: '100%',
  },
  profilePictWrapper: {
    flex: 1,
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
  },
  avatar: {
    height: 180,
    width: 180,
  },
  formAction: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
}))

export default useStyles
