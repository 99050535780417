/* eslint-disable no-console */
/** @jsxImportSource @emotion/react */

import useIndex from '@lib/query/managementCourier/useIndex'
import { Fragment, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import PageTitle from '@components/PageTitle'
import Layout from '@layout/Main'
import DataTable from '@components/DataTable'
import Card from '@components/card/Base'
import { css } from '@emotion/react'
import Tambah from '@components/button/Tambah'

import useCreate from '@lib/query/managementCourier/useCreate'
import useUpdate from '@lib/query/managementCourier/useUpdate'

import action from './action'
import Form from './Form'

const titleCSS = css`
  display: flex;
`

const addButtonCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > *:first-of-type {
    margin-left: 10px;
  }
`

function CourierManagement() {
  const create = useCreate()
  const update = useUpdate()

  const result = useIndex()

  const onTableChange = () => null

  const {
    tableData,
    tableColumns,
    hiddenColumns,
    tablePagination,
  } = result

  return (
    <Fragment>
      <Helmet>
        <title>
          SQUIDS :: Manajemen Kurir
        </title>
      </Helmet>
      <PageTitle>
        Manajemen Kurir
      </PageTitle>
      <Layout>
        <Card>
          <div css={titleCSS}>
            <h3>Manajemen Kurir</h3>
            <div css={addButtonCSS}>
              <Tambah type="create" />
            </div>
          </div>
          <DataTable
            data={tableData}
            columns={useMemo(() => tableColumns, [tableColumns])}
            hooks={action}
            hiddenColumns={hiddenColumns}
            pagination={tablePagination}
            onChange={onTableChange}
          />
        </Card>
      </Layout>
      <Form
        title="Tambah Kurir"
        type="create"
        defaultValues={{
          name: '',
          code: '',
          icon: null,
          images: null,
        }}
        onSave={create}
      />
      <Form
        title="Ubah Kurir"
        type="edit"
        defaultValues={{
          name: '',
          code: '',
          icon: null,
          images: null,
        }}
        onSave={update}
      />
    </Fragment>
  )
}

export default CourierManagement
