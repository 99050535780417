/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
import { useEffect, useMemo, useState } from 'react'
import { authApiBaseURL } from '@lib/env'
import qs from 'qs'
import { useHistory, useLocation } from 'react-router'
import { indexApi, showApi } from '../api'

const billingColumn = [
  {
    name: 'user_name',
    label: 'Nama',
  },
  {
    name: 'store_name',
    label: 'Toko',
  },
  {
    name: 'package_name',
    label: 'Paket',
  },
  {
    name: 'formated_total',
    label: 'Tagihan',
    nowrap: true,
  },
  {
    name: 'period',
    label: 'Periode',
  },
  {
    name: 'created_at',
    label: 'Tanggal Invoice',
    is_date: true,
  },
  {
    name: 'due_date',
    label: 'Tanggal Jatuh Tempo',
    is_date: true,
  },
  {
    name: 'status',
    label: 'Status',
  },
]

const dummy = [
  {
    id: 10,
    name: 'asep',
    store: 'toko asep',
    package: 'paket asep',
    bill: 'Rp. 5.000.000',
    period: 'Maret 2021',
    due_date: '31 Maret 2021',
    status: 'Pending',
  },
  {
    id: 11,
    name: 'eneng',
    store: 'toko eneng',
    package: 'paket eneng',
    bill: 'Rp. 5.000.000',
    period: 'Maret 2021',
    due_date: '31 Maret 2021',
    status: 'Pending',
  },
]

const useTableState = () => {
  const location = useLocation()
  const history = useHistory()
  const [queryString, setQueryString] = useState()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [total, setTotal] = useState(10)
  const [data, setData] = useState([])
  const column = billingColumn
  const [loading, setLoading] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [invoice, setInvoice] = useState()
  const collection = useMemo(() => data, [data])

  const queryPush = (query) => {
    const newQuery = { ...queryString, ...query }
    setQueryString(newQuery)
    history.push({ path: location.pathname, search: qs.stringify(newQuery) })
  }

  const queryRemove = (key) => {
    const newQuery = { ...queryString }

    if (typeof key === 'string') {
      delete newQuery[key]
    } else if (Array.isArray(key)) {
      key.map((item) => delete newQuery[item])
    }

    setQueryString(newQuery)
    history.push({ path: location.pathname, search: qs.stringify(newQuery) })
  }

  const getIndex = async () => {
    setData([])
    try {
      setLoading(true)
      const querySearch = qs.parse(location.search, { ignoreQueryPrefix: true })
      const { status, data } = await indexApi(querySearch)
      if (status === 'ok') {
        setLoading(false)
        setData(data?.data)
        setTotal(data?.pagination?.total_pages)
        setPage(data?.pagination?.current_page)
      } else {
        setLoading(false)
        if (querySearch?.csv || querySearch?.xlsx) {
          const downloadUrl = data?.data?.url || null
          delete querySearch.csv
          delete querySearch?.xlsx
          if (downloadUrl) window.open(`${downloadUrl}`, '_blank')
          history.push(`${location.pathname}?${qs.stringify(querySearch)}`)
        }
      }
    } catch {
      setLoading(false)
    }
  }

  const getShow = async (id) => {
    setInvoice()
    try {
      setShowLoading(true)
      const { status, data } = await showApi(id)
      if (status === 'ok') {
        setShowLoading(false)
        setInvoice(data?.data)
      }
    } catch {
      setShowLoading(false)
    }
  }

  return {
    queryString,
    setQueryString,
    page,
    setPage,
    perPage,
    setPerPage,
    queryPush,
    column,
    data,
    getIndex,
    queryRemove,
    loading,
    total,
    setTotal,
    collection,
    getShow,
    invoice,
    showLoading,
  }
}

export default useTableState
