import { useLocation, useHistory } from 'react-router-dom'
import { useState, useCallback, useMemo } from 'react'
import { parse, stringify } from 'qs'

const defaultConfig = {
  key: {
    orderBy: 'order_by',
    sortBy: 'sort_by',
    page: 'page',
    perPage: 'per_page',
  },
  parse: {
    ignoreQueryPrefix: true,
  },
  stringify: {
    arrayFormat: 'brackets',
    encodeValuesOnly: true,
    format: 'RFC1738',
  },
}

function useQueryString(config = defaultConfig) {
  const { search } = useLocation()
  const urlParams = useMemo(() => parse(search, config.parse), [search, config])
  const getUrlParams = useCallback((key) => urlParams[key], [urlParams])
  const { push } = useHistory()
  const [params, setParams] = useState(urlParams)
  const clearable = useMemo(() => {
    // prettier-ignore
    const clears = Object.entries(urlParams).reduce(
      (prev, [key, value]) => (!Object.values(config.key).includes(key)
        ? { ...prev, [key]: value }
        : prev),
      {}
    )
    return clears
  }, [urlParams, config.key])

  const applyQueryParams = useCallback(
    (object) => {
      const next = `?${stringify(object, config.stringify)}`
      const prev = `?${stringify(params, config.stringify)}`
      if (prev !== next) {
        setParams(object)
        // prettier-ignore
        // if (
        //   Object.keys(object).includes('name_or_email')
        //   || Object.keys(object).includes('email_or_name')
        //   || Object.keys(object).includes('status')
        // ) {
        //   const { page, ...newObj } = object
        //   push(`?${stringify(newObj, config.stringify)}`)
        // } else {
        const isParamsExist = Boolean(Object.keys(params).length)
        const isObjectExist = Boolean(Object.keys(object).length)
        if (isParamsExist && !isObjectExist) {
          const {
            name_or_email,
            email_or_name,
            name,
            start_price,
            end_price,
            status,
            parent_or_name,
            set_order,
            order_by,
            store_name,
            store_domain,
            voucher_code,
            invoice_status,
            ...newParams
          } = params
          push(`?${stringify(newParams, config.stringify)}`)
        } else {
          push(`?${stringify(object, config.stringify)}`)
        }
        // }
      }
    },
    [config.stringify, push, params]
  )

  // ORDER
  const getOrderBy = useMemo(
    () => getUrlParams(config.key.orderBy),
    [getUrlParams, config.key.orderBy]
  )

  const setOrderBy = useCallback(
    (orderBy) => {
      const next = { ...urlParams, [config.key.orderBy]: orderBy }
      applyQueryParams(next)
    },
    [urlParams, applyQueryParams, config.key.orderBy]
  )

  // SORT
  const getSortBy = useMemo(
    () => getUrlParams(config.key.sortBy),
    [getUrlParams, config.key.sortBy]
  )

  const setSortBy = useCallback(
    (sortBy) => {
      const next = { ...urlParams, [config.key.sortBy]: sortBy }
      applyQueryParams(next)
    },
    [urlParams, applyQueryParams, config.key.sortBy]
  )

  // PAGE
  const getPage = useMemo(
    () => getUrlParams(config.key.page) || 1,
    [getUrlParams, config.key.page]
  )

  const setPage = useCallback(
    (page) => {
      const next = { ...urlParams, [config.key.page]: page }
      applyQueryParams(next)
    },
    [urlParams, applyQueryParams, config.key.page]
  )

  // PERPAGE
  const getPerPage = useMemo(
    () => getUrlParams(config.key.perPage),
    [getUrlParams, config.key.perPage]
  )

  // FILTER
  const setPerPage = useCallback(
    (perPage) => {
      const next = { ...urlParams, [config.key.perPage]: perPage }
      applyQueryParams(next)
    },
    [urlParams, applyQueryParams, config.key.perPage]
  )

  // FILTER
  const setQuery = useCallback(
    (key, value) => {
      const next = { ...urlParams, [key]: value }
      applyQueryParams(next)
    },
    [urlParams, applyQueryParams]
  )

  const setQueries = useCallback(
    (filters) => {
      const next = { ...urlParams, ...filters }
      applyQueryParams(next)
    },
    [urlParams, applyQueryParams]
  )

  const clearQuery = useCallback(
    (keys = []) => {
      // prettier-ignore
      const next = Object.entries(urlParams).reduce(
        (prev, [key, value]) => (keys.includes(key) ? { ...prev, [key]: value } : prev),
        {}
      )
      applyQueryParams(next)
    },
    [urlParams, applyQueryParams]
  )

  const clearQueries = useCallback(() => {
    const next = Object.entries(urlParams).reduce(
      // prettier-ignore
      (prev, [key, value]) => (Object.values(config.key).includes(key)
        ? { ...prev, [key]: value }
        : prev),
      {}
    )
    applyQueryParams(next)
  }, [urlParams, applyQueryParams, config.key])

  return {
    params: urlParams,
    getUrlParams,
    setPage,
    page: getPage,
    setPerPage,
    perPage: getPerPage,
    setOrderBy,
    orderBy: getOrderBy,
    setSortBy,
    sortBy: getSortBy,
    setQuery,
    clearQuery,
    setQueries,
    clearQueries,
    clearable,
  }
}

export default useQueryString
