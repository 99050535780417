/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { connect } from 'react-redux'
import { openDialog, confirmClear } from '@lib/redux/slice/ui'
import useSuspend from '@lib/query/store/useSuspend'
import { sendInvoiceApi } from '@screens/secured/BillingManagement/api'
import { useSnackbar } from 'notistack'

const options = ['Suspend', 'Ubah Paket', 'Kirim Invoice Manual']
function MenuAction(props) {
  const {
    openForm,
    openConfirm,
    data,
    type,
    confirm,
    confirmKey,
    clearConfirm,
  } = props
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const { mutate } = useSuspend()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (type === 'confirm.data' && data.id === confirmKey) {
      if (confirm) {
        mutate({ id: data.id, status: !data?.status })
      }
      clearConfirm()
    }
  }, [type, confirm, confirmKey, data, clearConfirm, mutate])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const sendInvoice = async (id) => {
    const res = await sendInvoiceApi(id)
    if (res?.data?.data === 'Tagihan berhasil dikirim!') {
      enqueueSnackbar(res?.data?.data, { variant: 'success' })
    } else {
      enqueueSnackbar(res?.data?.data, { variant: 'error' })
    }
  }
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '20ch',
          },
        }}
      >
        {options.map((option, i) => (
          <MenuItem
            key={option}
            onClick={() => {
              if (i === 0) {
                openConfirm(data.id, !data?.status)
              } else if (i === 1) {
                openForm(data)
              } else if (i === 2) {
                sendInvoice(data?.id)
              }
            }}
          >
            {i === 0 ? (!data?.status ? 'Unsuspend' : option) : option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

const mapDispatch = {
  openForm: (data) => openDialog({
    type: 'form.dialog.show',
    data,
  }),
  openConfirm: (confirmKey, suspended) => openDialog({
    type: 'confirm.dialog',
    data: {
      message: suspended
        ? 'Apakah Anda yakin ingin membatalkan penangguhan user ini?'
        : 'Apakah Anda yakin ingin menangguhkan user ini?',
      title: suspended ? 'Unsuspend User' : 'Suspend User',
      confirmKey,
    },
  }),
  clearConfirm: confirmClear,
}
const mapState = ({
  ui: {
    dialog: { type, data },
  },
}) => ({
  type,
  confirm: data?.confirm,
  confirmKey: data?.confirmKey,
})

const connector = connect(mapState, mapDispatch)
const ConnectedShowButton = connector(MenuAction)
export default ConnectedShowButton
