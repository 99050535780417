/* eslint-disable radix */
/* eslint-disable camelcase */

import { useMutation, useQueryClient } from 'react-query'
import useSuccess from '@hooks/useSuccess'
import useCloseDialog from '@hooks/useCloseDialog'
import { createXenPlatforms } from '@lib/axios/xenplatform'
import { useSnackbar } from 'notistack'

const useCreateAdmin = () => {
  const showSuccess = useSuccess('Sukses', 'Sukses Menambah Akun XenPlatform')
  const { enqueueSnackbar } = useSnackbar()

  const closeDialog = useCloseDialog()
  const queryClient = useQueryClient()
  const mutation = useMutation(
    (mdata) => createXenPlatforms(
      mdata.email,
      mdata.public_profile,
      mdata.account_type,
      mdata.store_id ? parseInt(mdata.store_id) : null
    ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('saas-xenplatforms')
      },
    }
  )
  return (data) => mutation.mutate(data, {
    onError: () => closeDialog(),
    onSuccess: (m) => {
      if (m?.response?.status > 200) {
        enqueueSnackbar('Gagal menambah akun', { variant: 'error' })
        closeDialog()
      } else {
        showSuccess()
      }
    },
  })
}

export default useCreateAdmin
