import { isDev } from '@lib/env'
import { Redirect } from 'react-router-dom'
import loadable from '@loadable/component'

const Selection = loadable(() => import('@components/Dev/Selection'))

function Dev(props) {
  const { redirect } = props
  return isDev
    ? (
      <Selection />
    )
    : <Redirect to={redirect} />
}

export default Dev
