/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'

const searchCSS = css`
  position: relative;
  width: 294px;
  padding: 5px;
  input {
    height: 37px;
    width: 294px;
    background: #ECF2F5;
    border-radius: 60px;
    border: 0 none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 170%;
    padding: 0px 15px;
    box-sizing: border-box;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, .1);

  }
  input:focus {
    outline: none;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, .2);
  }
`

function Search() {
  return (
    <div css={searchCSS}>
      <input placeholder="cari" type="text" />
    </div>
  )
}

export default Search
