/** @jsxImportSource @emotion/react */

import { openDialog, confirmClear } from '@lib/redux/slice/ui'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import useSuspend from '@lib/query/admin/useSuspend'
import BaseSuspendButton from '@components/DataTable/button/Suspend'

function SuspendButton(props) {
  const {
    openConfirm, data, type, confirm, confirmKey,
    clearConfirm,
  } = props
  const { mutate } = useSuspend()

  useEffect(() => {
    if (type === 'confirm.data' && data.id === confirmKey) {
      if (confirm) {
        mutate(data.id)
      }
      clearConfirm()
    }
  }, [type, confirm, confirmKey, data, clearConfirm, mutate])

  return (
    <BaseSuspendButton
      onClick={() => openConfirm(data.id, data?.activation_status === 'suspended')}
      suspended={data?.activation_status === 'suspended'}
    />
  )
}

const mapDispatch = {
  openConfirm: (confirmKey, suspended) => openDialog({
    type: 'confirm.dialog',
    data: {
      message: suspended
        ? 'Apakah Anda yakin ingin membatalkan penangguhan user ini?'
        : 'Apakah Anda yakin ingin menangguhkan user ini?',
      title: suspended
        ? 'Unsuspend User'
        : 'Suspend User',
      confirmKey,
    },
  }),
  clearConfirm: confirmClear,
}

const mapState = ({ ui: { dialog: { type, data } } }) => ({
  type,
  confirm: data?.confirm,
  confirmKey: data?.confirmKey,
})
const connector = connect(mapState, mapDispatch)
const ConnectedSuspendButton = connector(SuspendButton)
export default ConnectedSuspendButton
