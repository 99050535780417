/* eslint-disable no-unused-vars */

import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openDialog, confirmClear } from '@lib/redux/slice/ui'

function useConfirm(title, message, id) {
  const dispatch = useDispatch()
  const confirmKey = useSelector(({ ui: { dialog: { data } } }) => data?.confirmKey)
  const confirm = useSelector(({ ui: { dialog: { data } } }) => data?.confirm)

  const clear = useCallback(() => dispatch(confirmClear()), [dispatch])

  const open = () => dispatch(openDialog({
    type: 'confirm.dialog',
    data: { title, message, confirmKey: id },
  }))

  useEffect(() => {
    if (confirmKey === id && typeof confirm === 'boolean') {
      clear()
    }
  }, [confirm, confirmKey, clear, id])

  const yes = useCallback(
    (callback, check) => (typeof confirm === 'boolean' && confirm && check === confirmKey && callback(confirmKey)),
    [confirm, confirmKey]
  )

  const no = useCallback(
    (callback, check) => (typeof confirm === 'boolean' && !confirm && check === confirmKey && callback(confirmKey)),
    [confirm, confirmKey]
  )

  return {
    open,
    yes,
    no,
  }
}

export default useConfirm
