import { useQuery } from 'react-query'
import { indexSaasMootaSetups } from '@lib/axios/moota'
import { useMemo } from 'react'
import useQueryString from '@hooks/useQueryString'

function useIndex() {
  const { params } = useQueryString()
  const { status, data } = useQuery(
    ['saas-moota', params],
    () => indexSaasMootaSetups(params),
    {
      retry: false,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  )
  // const mapper = (({ status, ...d }) =>
  // ({ status: status ? 'terhubung' : 'tidak terhubung', ...d }))

  const tableData = useMemo(
    () => ((status === 'success' && Array.isArray(data?.data?.data)) ? data?.data?.data : []),
    [status, data]
  )

  const emptyPagination = useMemo(() => ({
    current_page: 1,
    first_page: true,
    last_page: false,
    next_page: null,
    prev_page: null,
    total_pages: 1,
  }), [])

  const tablePagination = useMemo(
    () => ((status === 'success' && data?.data?.pagination) ? data?.data?.pagination : emptyPagination),
    [status, data, emptyPagination]
  )

  const columns = [
    ['id', 'ID', true],
    ['name', 'Nama', false],
    ['bank_name', 'Bank', false],
    ['account_number', 'No. Rekening', false],
    ['personal_token', 'Personal Token', false],
    ['status', 'Status', true],
    ['created_by_name', 'Di buat Oleh', true],
    ['updated_by_name', 'Di edit Oleh', true],
    ['created_at', 'created_at ', true],
    ['updated_at', 'updated_at', true],
  ]

  const tableColumns = columns
    .map(([column, title]) => ([column, title]))
    .map(([accessor, Header]) => ({ accessor, Header }))

  const hiddenColumns = columns
    .filter(([, , hidden]) => hidden)
    .map(([column]) => column)

  return {
    tableData,
    tableColumns,
    hiddenColumns,
    tablePagination,
  }
}

export default useIndex
