/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import MuiButton from '@material-ui/core/Button'

const CSS = css`
  &.MuiButton-root {
    border-radius: 5px;
    min-width: 80px;
    width: 80px;
    height: 30px;
    position: relative;
  }
  &.MuiButton-textPrimary {
    background-color: #556FB5;
  }
  .MuiButton-label {
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    position: absolute;
    text-transform: capitalize;
  }
`
function Button(props) {
  const { onClick: handleClick } = props
  return (
    <MuiButton
      disableElevation
      variant="contained"
      css={CSS}
      onClick={handleClick}
    >
      Terapkan
    </MuiButton>
  )
}

export default Button
