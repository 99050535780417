/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import {
  useTable, useRowSelect, useSortBy, usePagination,
} from 'react-table'
import { Fragment, useEffect } from 'react'
import useQueryString from '@hooks/useQueryString'

import Pagination from './Pagination'
import THead from './THead'

const tableCSS = css`
  border-spacing: 0;
  width: 100%;
  td, th {
    border-bottom: 1px solid #ECF2F5;
    height: 40px;
  }
  td, th {
    padding: 5px 10px;
  }
  td:first-of-type, th:first-of-type {
    padding-left: 33px;
  }
  th[role=columnheader]:last-of-type {
  }
  // td[role="cell"]:last-of-type {
  //   display: flex;
  //   // padding-right: 33px;
  //   justify-content: center;
  //   align-items: center;
  // }
  td[role="cell"]:last-of-type > * {
    margin-right: 10px;
  }
  th {
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1em;
    color: #556FB5;
  }
  thead span {
    position: relative;
  }
  thead span img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  tbody td {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #1A253B;
  }
`

function Table(props) {
  const {
    data, columns, hooks, className,
    hiddenColumns, onChange,
    pagination, style,
  } = props

  const {
    setPage, setOrderBy, setSortBy,
  } = useQueryString()
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: hiddenColumns || [],
        pageSize: 10,
        pageIndex: (pagination.current_page - 1),
      },
      pageCount: pagination.total_pages,
      manualPagination: true,
      // manualSortBy: true,
      disableMultiSort: true,
      autoResetSortBy: false,
      autoResetPage: true,
      disableSortRemove: true,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    hooks
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    selectedFlatRows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {
      selectedRowIds, pageIndex, pageSize, sortBy,
    },
  } = tableInstance

  useEffect(() => {
    // setPage(pageIndex < 1 ? undefined : pageIndex + 1)
  }, [pageIndex, onChange, sortBy, pageSize, setPage])

  useEffect(() => {
    if (onChange) onChange?.(sortBy)
  }, [pageIndex, onChange, sortBy, pageSize, setPage])

  useEffect(() => {
    if (sortBy.length > 0) {
      setSortBy(sortBy[0].desc === true ? 'desc' : 'asc')
      setOrderBy(sortBy[0]?.id)
      // setPage(1)
    } else {
      setSortBy()
      setOrderBy()
    }
  }, [setOrderBy, setSortBy, sortBy])

  const paginationProps = {
    gotoPage,
    previousPage,
    pageOptions,
    canNextPage,
    canPreviousPage,
    pageCount,
    pageIndex,
    nextPage,
    pageSize,
    setPageSize,
    selectedRowIds,
    selectedFlatRows,
  }

  return (
    <Fragment>
      {/* <div style={{
        width: '100%', overflowX: 'auto', overflowY: 'hidden',
      }}
      > */}
      <table css={tableCSS} className={className} style={style} {...getTableProps()}>
        <THead headerGroups={headerGroups} />
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {
              row.cells.map(
                (cell) => (
                  <td {...cell.getCellProps()}>
                    {cell?.value !== null ? cell.render('Cell') : '-'}
                  </td>
                )
              )
            }
              </tr>
            )
          })}
        </tbody>
      </table>
      {/* </div> */}
      {
        pagination && (
          <Pagination
            pagination={paginationProps}
            data={pagination}
          />
        )
      }
    </Fragment>
  )
}

export default Table
