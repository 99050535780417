/* eslint-disable no-console */
/** @jsxImportSource @emotion/react */
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { flatten } from 'flat'
import defaultValues from './defaultValues'

const useValue = () => {
  const open = useSelector(({ ui: { dialog } }) => dialog.open)
  const data = useSelector(({ ui: { dialog } }) => dialog.data)

  const next = useMemo(
    () => {
      let value = defaultValues
      if (data) {
        const { id } = data
        let v = Object.entries(flatten(value))
        const d = flatten(data, { maxDepth: 3 })
        v = v.reduce((p, [prop, val]) => ({
          ...p,
          [prop]: d[prop] === undefined || d[prop] === null ? val : d[prop],
        }),
        {})
        value = id ? { id, ...v } : v
      }
      return value
    },
    [data]
  )

  return { value: next, open }
}

export default useValue
