/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import Button from '@material-ui/core/Button'
import clsx from 'clsx'

const CSS = css`
  &.MuiButton-root {
    border-radius: 5px;
    padding: 3px 8px;
    border: 1px solid #E82A63;
  }
  &.MuiButton-textPrimary {
    background-color: #FFFFFF;
  }
  .MuiButton-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    text-transform: none;
    color: #E82A63;
    width: 62px;
  }
  &.active.MuiButton-textPrimary {
    background-color: #E82A63;
    .MuiButton-label {
      color: #FFFFFF
    }
  }
`

function SuspendButton(props) {
  const {
    className,
    suspended,
    onClick: handleClick,
  } = props
  return (
    <Button onClick={() => handleClick && handleClick()} css={CSS} className={clsx(className, !suspended && 'active')}>
      {
        suspended ? 'unsuspend' : 'suspend'
      }
    </Button>
  )
}

export default SuspendButton
