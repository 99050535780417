/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import Drawer from '@material-ui/core/Drawer'
import menus from '@lib/menus'
import { useSelector } from 'react-redux'

import Logo from './Logo'
import Menu from './Menu'

const sidebarCSS = css`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1100;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, .05);
  width: 278px;
`

const menuCSS = css`
  flex: 1;
  background: #FFFFFF;
`

function Sidebar() {
  const selector = useSelector((state) => state?.ui?.sidebarOpen)
  return (
    <Drawer css={sidebarCSS} open={selector} variant="persistent" anchor="left" style={{ width: !selector && 0 }}>
      <Logo />
      <div css={menuCSS}>
        <Menu menus={menus} />
      </div>
    </Drawer>
  )
}

export default Sidebar
