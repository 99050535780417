import { openDialog } from '@lib/redux/slice/ui'
import { useDispatch } from 'react-redux'

function useSuccess(title, message) {
  const dispatch = useDispatch()
  const open = () => dispatch(
    openDialog({
      type: 'success.dialog',
      data: {
        title,
        message,
      },
    })
  )
  return open
}

export default useSuccess
