/* eslint-disable camelcase */

import { createVoucher } from '@lib/axios/voucher'
import { useMutation, useQueryClient } from 'react-query'
import useSuccess from '@hooks/useSuccess'
import { useHistory } from 'react-router-dom'

const useCreateAdmin = () => {
  const showSuccess = useSuccess('Sukses', 'Sukses Menambah Voucher')
  const queryClient = useQueryClient()
  const history = useHistory()
  const mutation = useMutation(
    (data) => createVoucher(data)
  )

  return (data) => mutation.mutate(data, {
    onSuccess: () => {
      showSuccess()
      history.goBack()
      queryClient.invalidateQueries('saas-voucher')
    },
  })
}

export default useCreateAdmin
