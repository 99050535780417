import Axios from 'axios'
import { userApiBaseURL } from '@lib/env'
import { setBearerToken, handleUnauthorized, normalizeResponse } from '@lib/axios/interceptor'
import qs from 'qs'

const api = Axios.create({
  baseURL: userApiBaseURL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
})
api.defaults.headers.common.Accept = 'application/json'
api.interceptors.request.use(setBearerToken)
api.interceptors.response.use(normalizeResponse, handleUnauthorized)

export const resetPassword = () => api.post('/password', {})

export const changePassword = () => api.put('/password', {})

export const getSaasUser = (params) => api.get('/', { params })

export const suspendSaasUser = (id) => api.put(`/${id}`)
