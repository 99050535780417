import { adminApiBaseURL } from '@lib/env'
import Axios from 'axios'
import qs from 'qs'
import { setBearerToken, handleUnauthorized, normalizeResponse } from './interceptor'

const api = Axios.create({
  baseURL: adminApiBaseURL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
})
api.defaults.headers.common.Accept = 'application/json'
api.interceptors.request.use(setBearerToken)
api.interceptors.response.use(normalizeResponse, handleUnauthorized)

export const indexSaasMootaSetups = (params) => api.get('/saas_moota_setups', { params })

export const createSaasMootaSetups = (name, bank_name, account_number, personal_token) => api.post('/saas_moota_setups', {
  saas_moota_setup: {
    name,
    bank_name,
    account_number,
    personal_token,
  },
})

export const showSaasMootaSetups = (id) => api.get(`/saas_moota_setups/${id}`)
export const deleteSaasMootaSetups = (id) => api.delete(`/saas_moota_setups/${id}`)

export const updateSaasMootaSetups = (id, name, bank_name, account_number, personal_token, status) => api.put(`/saas_moota_setups/${id}`, {
  saas_moota_setup: {
    name,
    bank_name,
    account_number,
    personal_token,
    status,
  },
})
