/** @jsxImportSource @emotion/react */

import Form from '@components/dialog/Form'
import { useForm } from 'react-hook-form'
import { css } from '@emotion/react'
import Text from '@components/field/Text'
import NumberField from '@components/field/Number'
import { useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import useValue from './useValue'
import Autocomplete from './Autocomplete'

const CSS = css`
  min-width: 829px;
  border-radius: 5px;
  .MuiDialogContent-root {
    padding: 32px;
  }
  .field-wrap {
    width: 381px;
    display: flex;
    flex-direction: column;
  }
  .field-wrap > div {
    flex: 1;
    margin-bottom: 10px;
  }
  .field-wrap .bank {
    display: flex;
    justify-content: space-between;
  }
  .field-wrap .bank > div {
    max-width: 48%;
  }
`

const schema = yup.object().shape({
  name: yup.string().required('Wajib Di Isi'),
  order: yup.string().required('Wajib Di Isi'),
})

export default function Dialog(props) {
  const {
    type, title, onSave: save, defaultValues,
  } = props

  const {
    handleSubmit, register, reset, control, setValue, errors,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const { value, open } = useValue(defaultValues)

  useEffect(() => {
    if (open) {
      reset(
        value,
        {
          isDirty: false,
          touched: false,
          dirtyFields: false,
          errors: false,
        }
      )
    }
  }, [value, reset, open])

  return (
    <Form
      title={title}
      type={type}
      onSubmit={handleSubmit((data) => {
        const { parent, ...rest } = data
        save({ parent_id: parent?.id || null, ...rest })
      })}
      css={CSS}
    >
      {
          value?.id && (
            <input name="id" type="hidden" value={value.id} ref={register} />
          )
        }
      <div className="field-wrap">
        <Text label="Nama" name="name" ref={register} error={errors?.name?.message} />
        <Autocomplete label="Parent" name="parent" control={control} setValue={setValue} register={register} />
        <Text label="Path" name="path" ref={register} />
        <Text label="Icon" name="icon" ref={register} />
        <NumberField label="Order" name="order" control={control} error={errors?.order?.message} />
      </div>
    </Form>
  )
}
