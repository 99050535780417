/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
import { Fragment } from 'react'
import dayjs from 'dayjs'
import Ubah from '@components/button/Ubah'
// import Action5Ticket from './Action5Ticket'

const Cell = ({ row: { original: data } }) => (
  <Fragment>
    <Ubah data={data} type="ticket-edit" />
  </Fragment>
)

export default (hooks) => {
  hooks.visibleColumns.push((columns) => {
    return [
      {
        id: 'table_number_display',
        Header: () => (<div>No.</div>),
        Cell: ({ row: { original: data } }) => `${data.table_number}.`,
      },
      {
        id: 'created_at_display',
        Header: () => (<div>Tanggal</div>),
        Cell: ({ row: { original: data } }) => `${dayjs(data?.created_at).format('DD-MM-YYYY HH:mm')} WIB`,
      },
      {
        id: 'number_display',
        Header: () => (<div>No. Ticketing</div>),
        Cell: ({ row: { original: data } }) => data?.number,
      },
      {
        id: 'saas_user_name_display',
        Header: () => (<div>Nama User</div>),
        Cell: ({ row: { original: data } }) => `${data.saas_user_name}`,
      },
      {
        id: 'store_name_display',
        Header: () => (<div>Nama Toko</div>),
        Cell: ({ row: { original: data } }) => `${data.store_name}`,
      },
      {
        id: 'type_support_display',
        Header: () => (<div>Jenis Support</div>),
        Cell: ({ row: { original: data } }) => `${data.type_support}`,
      },
      {
        id: 'status_display',
        Header: () => (<div>Status</div>),
        Cell: ({ row: { original: data } }) => (
          <div style={{ color: '#4BAEA0' }}>
            {data.status}
          </div>
        ),
      },
      ...columns,
      {
        id: 'action_display',
        Header: () => (<div>Aksi</div>),
        Cell,
      },
    ]
  })
}
