/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Link } from 'react-router-dom'

const forgetPasswordLinkCSS = css`
  position: absolute;
  bottom: 51px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  width: 140px;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 170%;
  text-decoration: none;
  color: #3630C9;
`

const forgetPasswordText = 'Lupa Kata Sandi ?'

function ForgetPasswordLink() {
  return (
    <Link to="/forgot-password" css={forgetPasswordLinkCSS}>
      { forgetPasswordText }
    </Link>
  )
}

export default ForgetPasswordLink
