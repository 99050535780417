/* eslint-disable no-extra-semi */
/* eslint-disable arrow-body-style */
/** @jsxImportSource @emotion/react */
/* eslint-disable no-unused-vars */

import { indexSyncPages } from '@lib/axios/syncPages'
import {
  Autocomplete as MuiAutocomplete,
  FormControl,
  InputBase,
  InputLabel,
} from '@material-ui/core'
import { useWatch } from 'react-hook-form'
import {
  useState, useMemo, useEffect, Fragment,
} from 'react'

import { css } from '@emotion/react'

const inputCSS = css`
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .02);
    background-color: #F6F9FB;
  &.Mui-focused {
    border: 1px solid rgba(0, 0, 0, .1);
  }
  & .MuiInputBase-input {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 43px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 10px;
    height: 45px;
  }
  & .MuiSelect-select.MuiSelect-select {
    padding-left: 10px;
  }
  & .MuiSelect-select:focus {
    background-color: #F6F9FB;
  }
`
const formControlCSS = css`
  &.MuiFormControl-root {
    padding-top: 18px;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #676767;
  }
  & .MuiFormLabel-root.Mui-focused {
    color: #676767;
  }
  & .MuiFormHelperText-root {
    margin: 5px 5px 0 5px;
    font-size: 10px;
    line-height: 1;
  }
`

function useRenderInputProps({ label }) {
  return useMemo(() => (renderInputProps) => {
    return (
      <FormControl css={formControlCSS} fullWidth>
        <InputLabel
          {...renderInputProps.InputLabelProps}
          disableAnimation
          shrink
        >
          {
            `${label} ` || (
              <Fragment>
                &nbsp;
              </Fragment>
            )
          }
          {
            renderInputProps?.error && (
              <span className="error-message">
                * { renderInputProps?.error }
              </span>
            )
          }
        </InputLabel>
        <InputBase
          inputProps={{
            autoComplete: 'off',
            spellCheck: 'false',
            ...renderInputProps.inputProps,
          }}
          name={renderInputProps?.name}
          css={inputCSS}
          {...renderInputProps.InputProps}
        />
        &nbsp;
      </FormControl>
    )
  }, [label])
}

function useGetOptionLabel() {
  return useMemo(() => (option) => (typeof option === 'string' ? option : option?.name ?? ''),
    [])
}

function useGetOptionSelected() {
  return useMemo(() => (option, value) => option.id === value.id, [])
}

function Autocomplete(props) {
  const {
    label, name, control, setValue: setValueProps, register,
  } = props
  const watched = useWatch({ control, name: 'parent' })
  const [[inputValue, inputValueReason], setInputValue] = useState(['', null])
  const [value, setValue] = useState(watched)
  const [options, setOptions] = useState([])

  const handleInputChange = useMemo(() => (...[, newValue, reason]) => {
    setInputValue([newValue, reason])
  }, [setInputValue])

  const handleChange = useMemo(() => (...[, newValue, reason]) => {
    setValue(newValue)
    setValueProps(name, newValue)
  }, [setValueProps, name])

  const filteredOptions = useMemo(() => options, [options])

  useEffect(() => {
    let active = true
    if (inputValue) {
      ;(async () => {
        const res = await indexSyncPages({ page: 1, per_page: 25, name: inputValue })
        if (res?.status === 'ok') {
          if (active) {
            setOptions(res?.data?.data)
          }
        }
      })()
    }
    return () => {
      active = false
    }
  }, [inputValue])

  return (
    <Fragment>
      <input type="hidden" {...register({ name, type: 'custom' })} />
      <MuiAutocomplete
        options={filteredOptions}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleChange}
        value={value}
        getOptionLabel={useGetOptionLabel()}
        getOptionSelected={useGetOptionSelected()}
        size="small"
        includeInputInList
        renderInput={useRenderInputProps({ label })}
        filterOptions={(x) => x}
      />
    </Fragment>
  )
}

export default Autocomplete
