/** @jsxImportSource @emotion/react */

import InputBase from '@material-ui/core/InputBase'
import { forwardRef } from 'react'
import { css } from '@emotion/react'

const CSS = css`
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .02);
    background-color: #F6F9FB;
    &.Mui-focused {
      border: 1px solid rgba(0, 0, 0, .1);
      border-radius: 5px;
    }
  .MuiSelect-select {
    border-radius: 5px;
  }
  .MuiSelect-select:focus {
    border-radius: 5px;
  }
  .MuiInputBase-input {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 0;
    padding-bottom: 0;
  }
  .MuiSelect-select.MuiSelect-select {
    padding-left: 10px;
  }
  .MuiSelect-select:focus {
    background-color: #F6F9FB;
  }
`

const Input = forwardRef((props, ref) => (<InputBase css={CSS} {...props} ref={ref} />))

export default Input
