/* eslint-disable no-console */
/** @jsxImportSource @emotion/react */

import useIndex from '@lib/query/setupXenplatform/useIndex'
import { Fragment, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import PageTitle from '@components/PageTitle'
import Layout from '@layout/Main'
import DataTable from '@components/DataTable'
import Card from '@components/card/Base'
import { css } from '@emotion/react'
import Tambah from '@components/button/Tambah'

import useCreate from '@lib/query/setupXenplatform/useCreate'
import useConnect from '@lib/query/setupXenplatform/useConnect'

import useSyncAll from '@lib/query/setupXenplatform/useSyncAll'
import useUpdate from '@lib/query/setupXenplatform/useUpdate'
import action from './action'
import Form from './Form'

const titleCSS = css`
  display: flex;
`

const addButtonCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > *:first-of-type {
    margin-left: 10px;
  }
`

function XenplatformAccounts() {
  const create = useCreate()
  const connect = useConnect()
  const syncAll = useSyncAll()
  const update = useUpdate()

  const result = useIndex()

  const onTableChange = () => null

  const {
    tableData, tableColumns, hiddenColumns, tablePagination,
  } = result

  const defaultValues = {
    email: '',
    public_profile: {
      business_name: '',
    },
    account_type: '',
    store_id: '',
  }

  return (
    <Fragment>
      <Helmet>
        <title>SQUIDS :: Setup Akun xenPlatform</title>
      </Helmet>
      <PageTitle>Setup Akun xenPlatform</PageTitle>
      <Layout>
        <Card>
          <div css={titleCSS}>
            <h3>Setup Akun xenPlatform</h3>
            <div css={addButtonCSS}>
              <Tambah type="create" title="Akun" />
            </div>
            <div css={addButtonCSS}>
              <Tambah
                type="create-xenid"
                title="Konek xenPlatform"
                hideTambah
              />
            </div>
            <div css={addButtonCSS}>
              <Tambah onClick={syncAll} title="Sinkronkan" hideTambah />
            </div>
          </div>
          <DataTable
            data={tableData}
            columns={useMemo(() => tableColumns, [tableColumns])}
            hooks={action}
            hiddenColumns={hiddenColumns}
            pagination={tablePagination}
            onChange={onTableChange}
          />
        </Card>
      </Layout>
      <Form
        title="Tambah Akun Xenplatform"
        type="create"
        defaultValues={defaultValues}
        onSave={create}
      />
      <Form
        title="Ubah Akun Xenplatform"
        type="edit"
        defaultValues={defaultValues}
        onSave={update}
      />
      <Form
        title="Konek xenPlatform"
        type="create-xenid"
        defaultValues={{
          xenplatform_id: '',
        }}
        onSave={connect}
      />
    </Fragment>
  )
}

export default XenplatformAccounts
