/** @jsxImportSource @emotion/react */

import { useSelector } from 'react-redux'
import { useMemo, useEffect } from 'react'
import { flatten } from 'flat'

import useIndex from '@lib/query/pg/useIndex'

const useValue = (defaultValues) => {
  const { data: response, refetch } = useIndex()

  const open = useSelector(({ ui: { dialog } }) => dialog.open)
  const type = useSelector(({ ui: { dialog } }) => dialog.type)

  useEffect(() => {
    if ((open && type === '/s/integrasi.edit.pg')) {
      refetch()
    }
  }, [open, type, refetch])

  const data = useMemo(
    () => ((open && type === '/s/integrasi.edit.pg')
      ? response?.data
      : false),
    [open, type, response]
  )

  const next = useMemo(
    () => {
      let value = defaultValues
      if (data) {
        const { id } = data
        let v = Object.entries(flatten(value))
        const d = flatten(data)
        v = v.reduce((p, [prop, val]) => ({
          ...p,
          [prop]: d[prop] === undefined || d[prop] === null ? val : d[prop],
        }), {})
        value = id ? { id, ...v } : v
      }
      return value
    },
    [data, defaultValues]
  )

  return { value: next, open }
}

export default useValue
