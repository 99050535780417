import { render } from 'react-dom'
import App from '@components/App'
import { isDev } from '@lib/env'
import dayjs from 'dayjs'
import 'dayjs/locale/id'

import reportWebVitals from './reportWebVitals'

dayjs.locale('id')

if (isDev) { import('@fontsource/poppins') }

const MOUNT_NODE = document.getElementById('root')

render(
  <App />,
  MOUNT_NODE
)

reportWebVitals()
