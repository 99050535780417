/** @jsxImportSource @emotion/react */

import { ResponsiveLineCanvas as ResponsiveLine } from '@nivo/line'
import React from 'react'
import { css } from '@emotion/react'

const CSS = css`
  height: 700px;
  width: 100%;
  padding-top: 50px;
  background: #FFFFFF !important;
  border-radius: 10px !important;
  border: unset !important;
  position: relative;
  h3 {
    position: absolute;
    top: 0px;
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;
    line-height: 50px;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0em;
  }
`

const MyResponsiveLine = ({ data, subTitle, fn }) => (
  <ResponsiveLine
    enableGridX={false}
    data={data}
    curve="natural"
    margin={{
      top: 50, right: 50, bottom: 150, left: 150,
    }}
    xScale={{ type: 'point' }}
    yScale={{
      type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false,
    }}
    yFormat={fn}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: 'bottom',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Tanggal',
      legendOffset: 50,
      legendPosition: 'middle',
    }}
    axisLeft={{
      format: fn,
      legend: subTitle,
      legendPosition: 'middle',
      legendOffset: -100,
    }}
    colors={{ scheme: 'nivo' }}
    pointSize={10}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor' }}
    pointLabel="y"
    pointLabelYOffset={-12}
    useMesh
    tooltip={(input) => (
      <div style={{
        background: 'white',
        color: 'inherit',
        fontSize: '13px',
        borderRadius: '2px',
        boxShadow: 'rgb(0 0 0 / 25%) 0px 1px 2px',
        padding: '5px 9px',
      }}
      >
        <div style={{
          whiteSpace: 'pre',
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <span style={{
            display: 'block',
            width: '12px',
            height: '12px',
            background: input.point?.serieColor,
            marginRight: '7px',
          }}
          />
          Tanggal: <b>{input.point?.data?.x}</b><span style={{ marginRight: '5px' }}>,</span>
          {input.point?.serieId}: <b>{input.point?.data?.yFormatted}</b>
        </div>
      </div>
    )}
    legends={[
      {
        anchor: 'bottom',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: 100,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1,
            },
          },
        ],
      },
    ]}
  />
)

export default ({
  data, title, subTitle, fn,
}) => (
  <div css={CSS}>
    <h3>{title}</h3>
    <MyResponsiveLine
      data={data}
      subTitle={subTitle}
      fn={fn}
    />
  </div>
)
