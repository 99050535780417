/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import useProfile from '@lib/query/profile/useProfile'
import { useForm } from 'react-hook-form'
import {
  Button, CircularProgress, List, ListItem, ListItemText,
} from '@material-ui/core'
import Text from '@components/field/Text'
import { useHistory } from 'react-router'
import { ArrowBackIos } from '@material-ui/icons'
import useStyles from './account-jss'

const Form = () => {
  const [isEdit, setIsEdit] = useState(false)
  const {
    data, update, isLoading, isUpdating,
  } = useProfile(() => setIsEdit(false))
  const history = useHistory()
  const user = data?.data
  const classes = useStyles()

  const {
    setValue, register, errors, handleSubmit,
  } = useForm({
    defaultValues: {
      name: '',
      password: '',
      password_confirmation: '',
    },
  })

  const renderTextForm = () => (isLoading ? <CircularProgress />
    : (
      <div className={classes.fieldWrapper}>
        <List className={classes.list}>
          <ListItem>
            <ListItemText primary="Nama" secondary={user.name} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Username" secondary={user.username} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Email" secondary={user.email} />
          </ListItem>
        </List>
        <div className={classes.formAction}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={() => setIsEdit(true)}
          >
            Ubah
          </Button>
        </div>
      </div>
    ))

  const onSubmit = (value) => {
    update(value)
  }

  const renderFieldForm = () => (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.fieldWrapper}>
      <Text
        ref={register({ required: 'tidak boleh kosong' })}
        label="Nama"
        name="name"
        error={errors?.name?.message}
      />
      <div className={classes.formAction}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isUpdating}
        >
          {isUpdating ? <CircularProgress size={24} /> : 'Simpan'}
        </Button>
      </div>
    </form>
  )

  useEffect(() => {
    if (isEdit) {
      setValue('name', user?.name)
    }
  }, [isEdit])

  return (
    <div className={classes.root}>
      <Button
        onClick={() => history.goBack()}
        startIcon={<ArrowBackIos />}
        style={{ width: 100 }}
      >
        Back
      </Button>
      {isEdit ? renderFieldForm() : renderTextForm()}
    </div>
  )
}
export default Form
