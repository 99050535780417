/** @jsxImportSource @emotion/react */
/* eslint-disable no-console */

import useIndex from '@lib/query/voucherUsage/useIndex'
import { Fragment, useMemo } from 'react'
import { css } from '@emotion/react'
import DataTable from '@components/DataTable'
import PageTitle from '@components/PageTitle'
import { Helmet } from 'react-helmet'
import Layout from '@layout/Main'
import Card from '@components/card/Base'

import action from './action'
import Toolbar from './Toolbar'
// import PackageDetail from './PackageDetail'

const CSS = css`
  thead tr > *:last-of-type {
    div {
      text-align: center;
    }
  }
  & tbody tr > *:last-of-type div {
    display: flex;
    justify-content: space-around;
  }
`

const titleCSS = css`
  display: flex;
`
function VoucherUsage() {
  const onTableChange = () => null

  const {
    tableData, tableColumns, hiddenColumns, tablePagination,
  } = useIndex()

  return (
    <Fragment>
      <Helmet>
        <title>SQUIDS :: Pemakaian Voucher</title>
      </Helmet>
      <PageTitle>Pemakaian Voucher</PageTitle>
      <Layout>
        <Card>
          <div css={titleCSS}>
            <h3>Pemakaian Voucher</h3>
          </div>
          <Toolbar />
          <DataTable
            css={CSS}
            data={tableData}
            columns={useMemo(() => tableColumns, [tableColumns])}
            hooks={action}
            hiddenColumns={hiddenColumns}
            pagination={tablePagination}
            onChange={onTableChange}
          />
        </Card>
      </Layout>
    </Fragment>
  )
}

export default VoucherUsage
