import PropTypes from 'prop-types'
import {
  Button, Dialog, DialogContent, makeStyles, Typography,
} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({

  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  text: {
    fontSize: 14,
    marginBottom: theme.spacing(1),
    width: 300,
    textAlign: 'center',
  },
  button: {
    margin: theme.spacing(1),
  },
  btnWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  dialogPaper: {
    height: 230,
  },
}))

const AlertDialog = ({
  open,
  onClose,
  title,
  text,
  onResponseNegative,
  onResponsePositive,
  positive,
  negative,
  disabledButton,
}) => {
  const classes = useStyles()
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.dialogPaper }}
      maxWidth="xs"
    >
      <DialogContent className={classes.dialogContent}>
        <Typography
          variant="h1"
          className={classes.title}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          className={classes.text}
        >
          {text}
        </Typography>
        <div className={classes.btnWrapper}>
          {
            positive
              && (
              <Button
                onClick={onResponsePositive}
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={disabledButton}
              >
                {positive}
              </Button>
              )
          }
          {
            negative && (
              <Button
                onClick={onResponseNegative}
                variant="outlined"
                color="primary"
                className={classes.button}
                disabled={disabledButton}
              >
                {negative}
              </Button>
            )
          }
        </div>
      </DialogContent>
    </Dialog>
  )
}

AlertDialog.defaultProps = {
  disabledButton: false,
  negative: null,
  onClose: null,
  onResponseNegative: null,
  onResponsePositive: null,
  open: false,
  positive: null,
  text: '',
  title: '',
}

AlertDialog.propTypes = {
  disabledButton: PropTypes.bool,
  negative: PropTypes.any,
  onClose: PropTypes.func,
  onResponseNegative: PropTypes.func,
  onResponsePositive: PropTypes.func,
  open: PropTypes.bool,
  positive: PropTypes.any,
  text: PropTypes.any,
  title: PropTypes.any,
}

export default AlertDialog
