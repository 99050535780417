/* eslint-disable camelcase */
import Axios from 'axios'
import { login } from '@lib/redux/slice/auth'
import { store } from '@lib/redux'
import { authApiBaseURL } from '@lib/env'
import qs from 'qs'

const api = Axios.create({
  baseURL: authApiBaseURL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
})
// api.interceptors.response.use((response) => response, logError)

export const loginSaasAdmins = ({ username, password }) => api.post('/oauth/token', {
  username,
  password,
  scope: 'saas_admin',
  grant_type: 'password',
}).then(
  ({ data: { access_token, data } }) => store
    .dispatch(login({ accessToken: access_token, userData: data }))
)

export const loginSaasUsers = ({ username, password }) => api.post('/oauth/token', {
  username,
  password,
  scope: 'saas_user',
  grant_type: 'password',
})

export const registerSaasUsers = () => api.post('/oauth/token', {
  saas_user: {
    name: 'string',
    email: 'string',
    password: 'string',
    password_confirmation: 'string',
    phone_number: 'string',
    address: 'string',
    package_name: 'string',
    price: 0,
    provider: 'string',
    payment_method: 'string',
  },
})
