/* eslint-disable no-console */

import { Fragment } from 'react'
import Ubah from '@components/button/Ubah'
import Hapus from './Delete'

const action = (hooks) => {
  hooks.visibleColumns.push((columns) => [
    ...columns,
    {
      id: 'action',
      Header: () => (<div>Aksi</div>),
      Cell: ({ row: { original: data } }) => (
        <Fragment>
          <Ubah type="edit" data={data} />
          <Hapus id={data.id} />
        </Fragment>
      ),
    },
  ])
}

export default action
