/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
// import eyeSVG from '@assets/auth/eye.svg'
import MuiIconButton from '@material-ui/core/IconButton'
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined'

const togglePasswordCSS = css`
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  &:focus {
    outline: none;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, .1);
  }
`

function TogglePassword(props) {
  // eslint-disable-next-line no-unused-vars
  const { onToggle: handleToggle, type } = props

  function handleClick() {
    handleToggle()
  }

  return (
    <MuiIconButton type="button" css={togglePasswordCSS} onClick={handleClick}>
      {/* <img src={eyeSVG} alt="" /> */}
      { type === 'password' ? <VisibilityIcon /> : <VisibilityOffIcon /> }
    </MuiIconButton>
  )
}

export default TogglePassword
