/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import List from '@material-ui/core/List'
import { ListItem } from '@material-ui/core'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Link, useRouteMatch } from 'react-router-dom'
import { forwardRef } from 'react'
import clsx from 'clsx'

const CSS = css`

  &.MuiList-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
  & .MuiListItem-gutters {
    padding-left: 37px;
    padding-right: 37px;
  }
  & .MuiListItemIcon-root {
    min-width: 32px;
  }
  & .MuiListItemText-root > span {
    text-transform: capitalize;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #1A253B;
  }
  & .MuiListItem-button.active,
  & .MuiListItem-button.active-child {
    background-color: #E5E5E5;
  }
  & .active .MuiListItemText-root > span,
  & .active-child .MuiListItemText-root > span {
    color: #556FB5;
  }
  & [to="/s/setup-store-dashboard-page"] .MuiTypography-root,
  & [href="/s/setup-store-dashboard-page"] .MuiTypography-root {
    width: 160px;
  }
`

const LinkButton = forwardRef((props, ref) => {
  const {
    to, className, children, ...restProps
  } = props
  return (
    <li>
      <Link to={to} ref={ref} className={className} {...restProps}>
        { children }
      </Link>
    </li>
  )
})

function MenuList(props) {
  const { menus } = props
  const { path } = useRouteMatch()
  return (menus && Array.isArray(menus))
    ? menus.map(([title, [src, activeSrc], to]) => (
      <ListItem
        key={title}
        button
        component={path !== to ? LinkButton : 'li'}
        to={to}
        className={clsx(
          (path.includes(to)) && (path !== to) && (to !== '/s') && 'active-child',
          path === to && 'active'
        )}
      >
        <ListItemIcon>
          <img src={path === to ? activeSrc : src} alt="" />
        </ListItemIcon>
        <ListItemText>
          { title }
        </ListItemText>
      </ListItem>
    ))
    : null
}

function Menu(props) {
  const { menus } = props
  return (
    <List css={CSS}>
      <MenuList menus={menus} />
    </List>
  )
}

export default Menu
