import Axios from 'axios'
import { adminApiBaseURL } from '@lib/env'
import qs from 'qs'
import { setBearerToken, handleUnauthorized, normalizeResponse } from './interceptor'

const api = Axios.create({
  baseURL: adminApiBaseURL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
})
api.defaults.headers.common.Accept = 'application/json'
api.interceptors.request.use(setBearerToken)
api.interceptors.response.use(normalizeResponse, handleUnauthorized)

export const listTickets = (params) => api.get('/customer_tickets', { params })

export const updateTicket = ({ id, ...customer_ticket }) => api.put(`/customer_tickets/${id}`, { customer_ticket })

export const showTicket = (id) => api.get(`/customer_tickets/${id}`)

export const deleteTicket = (id) => api.delete(`/customer_tickets/${id}`)
