/* eslint-disable consistent-return */
/* eslint-disable camelcase */

import { useMutation, useQueryClient } from 'react-query'
import useSuccess from '@hooks/useSuccess'
import useCloseDialog from '@hooks/useCloseDialog'
import { updateXenplatform } from '@lib/axios/xenplatform'

const useUpdate = () => {
  const showSuccess = useSuccess('Sukses', 'Sukses Mengubah Xenplatform ')
  const closeDialog = useCloseDialog()
  const queryClient = useQueryClient()
  const mutation = useMutation(
    (data) => updateXenplatform(data)
  )

  return (data) => mutation.mutate(data, {
    onError: () => closeDialog(),
    onSuccess: () => {
      showSuccess()
      queryClient.invalidateQueries('saas-xenplatforms')
    },
  })
}

export default useUpdate
