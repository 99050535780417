import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    margin: theme.spacing(3),
    alignItems: 'center',
  },
  txHeader: {
    fontSize: 20,
    fontWeight: 'bold',
    marginRight: 8,
  },
  subHeader: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#C4C4C4',
    letterSpacing: -0.2,
    marginBottom: 35,
  },
  txHead: {
    color: theme.palette.primary.main,
    fontSize: 14,
    paddingBottom: theme.spacing(1),
  },
  txUnderlined: {
    textDecorationLine: 'underline',
    color: '#556FB5',
    fontSize: 14,
    marginTop: -20,
  },
  tFooter: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  textField: {
    backgroundColor: '#F6F9FB',
    height: 45,
    marginBottom: 15,
    '&.MuiInputBase-root': {
      '&.MuiOutlinedInput-root': {
        outline: 'none !important',
        border: 'none',
      },
    },
  },
  col: {
    flex: 1,
    margin: theme.spacing(1),
  },
  container: {
    display: 'flex',
  },
  selectPage: {
    height: 40,
    fontSize: 16,
    backgroundColor: '#F6F9FB',
    marginBottom: 35,
    outline: 'none',
    border: 'none',
    '&.MuiSelect-outlined': {
      border: 'none',
      outline: 'none',
    },
  },
  partition: {
    display: 'flex',
  },
  leftSide: {
    width: '30vw',
  },
  svgFolder: {
    marginBottom: 40,
  },
  uploadBox: {
    width: '90%',
    position: 'relative',
  },
  borderBox: {
    width: '100%',
    height: '100%',
  },
  boxContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  uploadArea: {
    position: 'absolute',
    opacity: 0,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    cursor: 'pointer',
    zIndex: 4,
  },
  extensionsList: {
    marginTop: 20,
    marginBottom: 10,
  },
  fakeInput: {
    height: 40,
    fontSize: 16,
    backgroundColor: '#F6F9FB',
    marginBottom: 25,
    padding: 8,
  },
  imagePreview: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 3,
    objectFit: 'cover',
    borderRadius: 8,
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: 10,
  },
  textInfo: {
    color: '#009bc2',
    marginTop: 10,
  },
  emptyDataMessage: {
    marginTop: 30,
    textAlign: 'center',
  },
}))

export default useStyles
