/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import useQueryString from '@hooks/useQueryString'
import { useState } from 'react'
import Terapkan from '@components/button/Terapkan'
import Reset from '@components/button/Reset'
import useWidth from '@hooks/useWidth'

import PageSize from '@components/filter/PageSize'
import Text from '@components/filter/Text'
import Status from './Status'
import UserStatus from './UserStatus'

const tableToolCSS = css`
  display: flex;
  margin-bottom: 14px;
  margin-right: 30px;
  margin-left: 30px;
  button,
  input {
    margin-right: 5px;
  }
  & > div {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  & > div > div {
    flex: 1;
    width: 49%;
  }
  & > div > div:last-of-type {
    flex: 1;
    display: flex;
  }
`

function Tools() {
  const { clearable, setQueries, clearQueries } = useQueryString()
  const [state, setState] = useState(clearable)
  const width = useWidth()

  function applyFilter() {
    setQueries({ ...state, page: 1 })
  }

  return (
    <div css={tableToolCSS}>
      <div
        style={{
          flexDirection: ['xs'].includes(width) && 'column',
          alignItems: ['xs'].includes(width) && 'center',
          justifyContent: ['xs'].includes(width) && 'center',
        }}
      >
        <div
          style={{
            flexDirection: ['xs'].includes(width) && 'column',
            width: ['xs'].includes(width),
          }}
        >
          <PageSize />
          <Text
            placeholder="Cari Nama Atau Email"
            onChange={(name_or_email) => {
              setState({ ...state, name_or_email: name_or_email || undefined })
            }}
            defaultValue={state.name_or_email}
            style={{
              maxWidth: 200,
              margin: ['xs'].includes(width) && '5px auto',
            }}
          />
          <UserStatus
            onSelected={(status) => setState({ ...state, user_status: status || undefined })}
            defaultValue={state.user_status}
          />
          <Status
            onSelected={(status) => setState({ ...state, is_suspend: status || undefined })}
            defaultValue={state.is_suspend}
          />
          <div
            style={{
              flexWrap: 'nowrap',
              whiteSpace: 'nowrap',
              marginTop: ['xs'].includes(width) && 5,
            }}
          >
            <Terapkan onClick={applyFilter} />
            <Reset
              onClick={() => {
                clearQueries()
                setState({})
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tools
