/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Text from '@components/field/Text'
import { useState } from 'react'
import { CircularProgress, IconButton, InputAdornment } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import useProfile from '@lib/query/profile/useProfile'
import Save from './Save'

const CSS = css`
  width: 444px;
  height: 462px;
  background: #FFFFFF;
  border: 1px solid #ECF2F5;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 30px;
  & > div {
    padding: 30px;
  }
  p {
    width: 381px;
    height: 81px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 27px;
    color: #676767;
  }
  .back-button {
    background: none;
    border: none;
    padding: 0;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #3630C9;
  }
  & form .field {
    margin-top: 12px;
  }
`

const text = 'Buat kata sandi baru '
              + 'minimal 8 karakter '
              + 'yang terdiri dari kombinasi huruf besar, '
              + 'huruf kecil, angka, dan simbol.'

function Form() {
  const history = useHistory()
  const {
    register, handleSubmit, errors, reset,
  } = useForm({
    defaultValues: {
      name: '',
      password: '',
      confirmPassword: '',
    },
  })
  const { name: username } = useSelector(({ auth: { userData: { name } } }) => ({ name }))
  const [visibility, setVisibility] = useState({
    password: false,
    confirmation_password: false,
  })

  const { update, isUpdating } = useProfile(reset)

  const togglePasswordVisibility = (name, value) => setVisibility({ ...visibility, [name]: value })

  const renderVisibilityIcon = (name) => (
    <InputAdornment position="end">
      <IconButton onClick={() => togglePasswordVisibility(name, !visibility[name])}>
        {visibility[name] ? <Visibility /> : <VisibilityOff /> }
      </IconButton>
    </InputAdornment>
  )

  return (
    <div css={CSS}>
      <div>
        <button className="back-button" type="button" onClick={() => history.goBack()}>&lt; Kembali</button>
        <p>{text}</p>
        <form onSubmit={handleSubmit(update)}>
          <input type="hidden" name="name" ref={register} value={username} />
          <Text
            ref={register({ required: 'tidak boleh kosong' })}
            label="Password"
            name="password"
            error={errors?.password?.message}
            type={visibility.password ? 'text' : 'password'}
            endAdornment={renderVisibilityIcon('password')}
          />
          <Text
            type={visibility.password_confirmation ? 'text' : 'password'}
            ref={register({ required: 'tidak boleh kosong' })}
            label="Konfirmasi Password"
            name="password_confirmation"
            error={errors?.password_confirmation?.message}
            endAdornment={renderVisibilityIcon('password_confirmation')}
          />
          <Save type="submit" disabled={isUpdating}>
            {isUpdating ? <CircularProgress size={24} color="secondary" /> : 'Simpan'}
          </Save>
        </form>
      </div>
    </div>
  )
}

export default Form
