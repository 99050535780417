/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
import { Fragment } from 'react'
import dayjs from 'dayjs'
import Ubah from '@components/button/Ubah'
// import Hapus from './Hapus'

const Cell = ({ row: { original: data } }) => (
  <Fragment>
    <Ubah data={data} type="edit" />
    {/* <Hapus id={data.id} /> */}
  </Fragment>
)

export default (hooks) => {
  hooks.visibleColumns.push((columns) => {
    return [
      {
        id: 'created_at_display',
        Header: () => (<div>Tanggal</div>),
        Cell: ({ row: { original: data } }) => `${dayjs(data?.created_at).format('DD-MM-YYYY HH:mm')} WIB`,
      },
      {
        id: 'id_display',
        Header: () => (<div>No. Ticketing</div>),
        Cell: ({ row: { original: data } }) => data?.number,
      },
      {
        id: 'username',
        Header: () => (<div>Nama User</div>),
        Cell: ({ row: { original: data } }) => data?.saas_user_name,
      },
      {
        id: 'store_label',
        Header: () => (<div>Nama Toko</div>),
        Cell: ({ row: { original: data } }) => data?.store_name,
      },
      {
        id: 'type_support_display',
        Header: () => (<div>Jenis Support</div>),
        Cell: ({ row: { original: data } }) => data?.type_support,
      },
      {
        id: 'status_display',
        Header: () => (<div>Status</div>),
        Cell: ({ row: { original: data } }) => data?.status,
      },
      {
        id: 'action_display',
        Header: () => (<div>Aksi</div>),
        Cell,
      },
    ]
  })
}
