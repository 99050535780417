/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import React, {
  useContext, useState, useEffect,
} from 'react'
import { api } from '@lib/axios/admin'

import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { TableContext } from './TableProvider'

const useStyles = makeStyles((theme) => ({
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    backgroundColor: '#F6F9FB',
    '&.MuiInputBase-root': {
      '&.MuiOutlinedInput-root': {
        height: 45,
        outline: 'none !important',
        border: 'none',
      },
    },
  },
  col: {
    flex: 1,
    margin: theme.spacing(1),
  },
  container: {
    display: 'flex',
  },
}))

const FormDialog = ({ open, onClose }) => {
  const classes = useStyles()
  const {
    handleSubmit, register, control, setValue, errors,
  } = useForm({
    defaultValues: {
      precentage: 0,
      published_at: new Date(),
    },
  })
  const {
    showLoading, getIndex, showData, setOpenDialog,
  } = useContext(TableContext)

  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState(new Date())
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (showData) {
      setIsEdit(true)
      setValue('percentage', parseInt(showData.percentage), { shouldValidate: true })
      setDate(showData.published_at)
    }
  }, [showData])

  const postData = async (params) => {
    try {
      setLoading(true)
      const { status, data: { data } } = await api.post('/affiliate_commissions', params)

      if (status === 'ok') {
        setLoading(false)
        enqueueSnackbar('Berhasil merubah data', { variant: 'success' })
        setOpenDialog(false)
        getIndex()
      } else {
        data.map((item) => enqueueSnackbar(item, { variant: 'error' }))
      }
    } catch (error) {
      setLoading(false)
      if (error?.response) {
        const { data } = error.response
        data.map((item) => enqueueSnackbar(item, { variant: 'error' }))
      } else {
        enqueueSnackbar('Maaf, terjadi kesalahan saat menambah data', { variant: 'error' })
      }
    }
  }

  const editData = async (params) => {
    try {
      setLoading(true)
      const { status } = await api.put(`/affiliate_commissions/${showData.id}`, params)

      if (status === 'ok') {
        setLoading(false)
        enqueueSnackbar('Berhasil merubah data', { variant: 'success' })
        setOpenDialog(false)
        getIndex()
      }
    } catch (error) {
      setLoading(false)
      if (error?.response) {
        const { data } = error.response
        data.map((item) => enqueueSnackbar(item, { variant: 'error' }))
      } else {
        enqueueSnackbar('Maaf, terjadi kesalahan saat merubah data', { variant: 'error' })
      }
    }
  }

  const onSubmit = async (value) => {
    const params = {
      affiliate_commission: value,
    }

    if (isEdit) {
      editData(params)
    } else {
      postData(params)
    }
  }

  const handleDateChange = (date) => {
    setDate(date)
    setValue('published_at', date, { shouldValidate: true })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Setup Affiliate Komisi</DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          {showLoading && 'Memuat data...'}
          <div className={classes.fieldWrapper}>
            <Typography color="textSecondary">Persentase</Typography>
            <Controller
              name="percentage"
              control={control}
              render={() => (
                <TextField
                  variant="outlined"
                  className={classes.textField}
                  disabled={loading || showLoading}
                  placeholder="Persentase"
                  type="number"
                  name="percentage"
                  max={100}
                  min={0}
                  helperText={errors?.precentage?.message}
                  InputProps={{
                    inputProps: { min: 0, max: 100 },
                    inputRef: register({ required: 'tidak boleh kosong', max: 100, min: 0 }),
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              )}
            />
            <div className={classes.fieldWrapper}>
              <Typography color="textSecondary">Tanggal Publikasi</Typography>
              <Controller
                name="published_at"
                control={control}
                render={() => (
                  <KeyboardDatePicker
                    clearable
                    inputRef={register({ required: 'tidak boleh kosong' })}
                    onChange={handleDateChange}
                    format="MM/dd/yyyy"
                    className={classes.textField}
                    value={date}
                    InputProps={{
                      classes: { root: classes.textField },
                    }}
                  />
                )}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Simpan'}
          </Button>
          <Button
            type="button"
            variant="outlined"
            onClick={onClose}
            disabled={loading}
          >
            Tutup
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
export default FormDialog
