import Axios from 'axios'
import { authApiBaseURL, packageApiBaseURL } from '@lib/env'
import qs from 'qs'
import { setBearerToken, handleUnauthorized, normalizeResponse } from './interceptor'

const api = Axios.create({
  baseURL: `${authApiBaseURL}/api/v1/saas_admins/stores`,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
})

const apiPackages = Axios.create({
  baseURL: packageApiBaseURL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
})

api.defaults.headers.common.Accept = 'application/json'
api.interceptors.request.use(setBearerToken)
api.interceptors.response.use(normalizeResponse, handleUnauthorized)

apiPackages.defaults.headers.common.Accept = 'application/json'
apiPackages.interceptors.request.use(setBearerToken)
apiPackages.interceptors.response.use(normalizeResponse, handleUnauthorized)

export const listStores = (params) => api.get('', { params })
export const listPackages = (params) => apiPackages.get('/list', { params })

export const updateStore = (id, status, package_id) => api.put(`/${id}`, {
  store: {
    status,
    package_id,
  },
})
