/** @jsxImportSource @emotion/react */

import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import { closeDialog } from '@lib/redux/slice/ui'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Simpan from '@components/button/Simpan'
import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import closeSVG from '@assets/dialog/close.svg'
import { css, ClassNames } from '@emotion/react'
import { makeStyles } from '@material-ui/core'
import ErrorBoundary from './ErrorBoundary'

const closeCSS = css`
  &.MuiIconButton-root {
    position: absolute;
    top: 20px;
    right: 20px;
  }
`

const CSS = css`
  & .MuiDialogActions-root {
    padding: 10px 40px;
  }
`

const errorCSS = css`
  .error-message {
    color: red;
  }
`

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2),
    },
  },
}))

function Form(props) {
  const {
    onClose: handleClose,
    className,
    title,
    children,
    onSubmit: handleSubmit,
    dialog,
    type,
    dividers,
    noSubmit,
    cancelButtonText,
  } = props
  const classes = useStyles()

  const { pathname } = useLocation()

  const isOpen = useMemo(
    () => dialog.open && dialog.type === `${pathname}.${type}`,
    [pathname, dialog.open, dialog.type, type]
  )

  return (
    <ClassNames>
      {({ css: cssToClassName, cx }) => (
        <Dialog
          open={isOpen}
          onClose={handleClose}
          scroll="paper"
          maxWidth="lg"
          PaperProps={{
            onSubmit: handleSubmit,
            className: cx(className, cssToClassName`${errorCSS}`),
            component: 'form',
          }}
          css={CSS}
        >
          <DialogTitle>
            { title }
            <IconButton
              size="small"
              css={closeCSS}
              onClick={handleClose}
            >
              <img src={closeSVG} alt="" />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers={dividers} className={classes.dialogContent}>
            <ErrorBoundary>
              { children }
            </ErrorBoundary>
          </DialogContent>
          <DialogActions>
            <Button type="button" onClick={handleClose}>{cancelButtonText || 'Batal'}</Button>
            {!noSubmit && (<Simpan />)}
          </DialogActions>
        </Dialog>
      )}
    </ClassNames>
  )
}

const mapState = ({ ui: { dialog } }) => ({ dialog })

const mapDispatch = {
  onClose: () => closeDialog(),
}

const connector = connect(mapState, mapDispatch)
const ConnectedForm = connector(Form)

export default ConnectedForm
