import React, { useContext } from 'react'
import { TableBody as MUITableBody, TableCell, TableRow } from '@material-ui/core'
import { TableContext } from './TableProvider'
import TableAction from './TableAction'

const TableBody = () => {
  const { data, column } = useContext(TableContext)
  return (
    <MUITableBody>
      {
      data.map((row) => (
        <TableRow key={row.id}>
          {
            column.map((col) => (
              <TableCell variant="body" key={col.name}>
                {row[col.name]}
              </TableCell>
            ))
          }
          <TableCell>
            <TableAction rowData={row} />
          </TableCell>
        </TableRow>
      ))
    }
    </MUITableBody>
  )
}
export default TableBody
