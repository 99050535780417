/* eslint-disable import/no-extraneous-dependencies */
/** @jsxImportSource @emotion/react */

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { css } from '@emotion/react'
import { Controller } from 'react-hook-form'
import { Fragment } from 'react'

const CSS = css`
  padding-top: 18px;
  padding-left: 15px;
  .MuiFormControlLabel-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
  .MuiButtonBase-root {
    height: 45px;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #556FB5;
  }
`

function Field(props) {
  const {
    onChange: handleChange,
    label,
    value,
  } = props

  const setUnlimited = () => handleChange(`${value}` === '-1' ? 0 : -1)

  return (
    <FormControlLabel
      css={CSS}
      label={label}
      control={(
        <Checkbox
          checked={`${value}` === '-1'}
          onChange={setUnlimited}
          size="small"
        />
      )}
    />
  )
}

function Unlimited(props) {
  const {
    label, control, name, watch,
  } = props

  return (
    <Fragment>
      <Controller
        control={control}
        name={name}
        render={
          ({ onChange: handleChange }) => (
            <Fragment>
              <Field
                label={label}
                onChange={handleChange}
                value={watch(name)}
              />
            </Fragment>
          )
        }
      />
    </Fragment>
  )
}

export default Unlimited
