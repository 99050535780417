/* eslint-disable no-unused-expressions */
import { useSnackbar } from 'notistack'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { indexProfile, updateSaasAdminProfile } from '../../axios/profiles'

const useProfile = (successCallback, errorCallback) => {
  const { data, isLoading } = useQuery('profile', indexProfile)
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const mutation = useMutation((params) => updateSaasAdminProfile(params), {
    onSuccess: () => {
      successCallback && successCallback()
      queryClient.invalidateQueries('profile')
      enqueueSnackbar('Data berhasil diubah', { variant: 'success' })
    },
    onError: () => {
      errorCallback && errorCallback()
      queryClient.invalidateQueries('profile')
      enqueueSnackbar('Maaf terjadi kesalahan', { variant: 'error' })
    },
  })

  const update = (params) => mutation.mutate(params, {})

  return {
    data,
    isLoading,
    update,
    isUpdating: mutation.isLoading,
  }
}

export default useProfile
