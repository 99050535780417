export default {
  name: '',
  annual_price: '',
  monthly_price: '',
  description: '',
  affiliate_commission: '0',
  omzet_based_billing: false,
  omzet_percentage: '0',
  package_details: [
    {
      id: null,
      user_total: '0',
      minimum_disbursement: '0',
      product_total: '0',
      order_per_day: '0',
      order_in_days: '0',
      reseller_total: '0',
      warehouse_limit: '1',
      trial_time: '0',
      position: '0',
      covered_xendit_fee: '0',
      custom_domain: false,
      hide_price: false,
      storefront_status: true,
      integration_ability: [],
    },
  ],
  package_features: [
    {
      id: null,
      can_access_memberships: false,
      can_access_keep_stocks: false,
      can_access_money_sources: false,
      can_access_checkout_form: false,
      can_access_partial_payment: false,
      can_access_pre_order: false,
      can_access_member_reports: false,
      can_access_sub_warehouse: false,
      can_access_internal_dropships: false,
      can_access_price_variation: false,
      can_access_daily_visit: false,
      can_access_tiered_quantity_rate_discount: false,
      can_access_voucher: false,
    },
  ],
  status: false,
}
