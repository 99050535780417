import queryClient from '@lib/query/client'
import { useEffect, useRef } from 'react'
import {
  closeDialog as closeDialogAction,
} from '@lib/redux/slice/ui'

import { connect } from 'react-redux'

function AuthSubscriber(props) {
  const { isLoggedIn, closeDialog } = props
  const prevIsLoggedIn = useRef(isLoggedIn)
  const firstLogin = useRef(true)

  useEffect(() => {
    if (!isLoggedIn) queryClient.clear()
    if (prevIsLoggedIn.current !== isLoggedIn) {
      if (isLoggedIn) {
        firstLogin.current = false
        // login event
      } else {
        firstLogin.current = true
        // logout event
        closeDialog()
      }
    }
    prevIsLoggedIn.current = isLoggedIn
  }, [isLoggedIn, closeDialog])
  return null
}

const mapState = ({ auth }) => ({ isLoggedIn: auth.isLoggedIn })
const mapDispatch = {
  closeDialog: closeDialogAction,
}
const connector = connect(mapState, mapDispatch)
const ConnectedAuthSubscriber = connector(AuthSubscriber)
export default ConnectedAuthSubscriber
