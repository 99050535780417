/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import clsx from 'clsx'
import ButtonBase from '@material-ui/core/ButtonBase'
import { Controller } from 'react-hook-form'
import { Fragment, useCallback } from 'react'

import facebookPixelSVG from './svg/facebookPixel.svg'
import paymentGatewaySVG from './svg/paymentGateway.svg'
import mootaSVG from './svg/moota.svg'
import shipmentSVG from './svg/shipment.svg'
import googleAnalyticsSVG from './svg/googleAnalytics.svg'
import woowaSVG from './svg/woowa.svg'
import checkSVG from './svg/check.svg'

const CSS = css`
  display: flex;
  position: relative;
  padding-top: 40px;
  overflow: auto;
  & h3 {
    position: absolute;
    top: 0;
    left: 0;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(0, 0, 0, 0.54);
  }
  & > button {
    width: 100px;
    min-width: 100px;
    height: 100px;
    background: #FFFFFF;
    border: 1px solid transparent;
    border-radius: 5px;
    margin-left: 17px;
    background: #F6F9FB;
    position: relative;
  }
  & > button.selected {
    border-color: #556FB5;
    background: #FFFFFF;
  }
  & p {
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%);
    width: 100px;
  }
  & img:not(.check) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
  & img.check {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }
  & .selected img.check {
    display: block;
  }
`

function SelectionButton(props) {
  const {
    imgSrc,
    title,
    selected,
    selections,
    value,
    onClick: save,
  } = props

  const handleClick = useCallback(
    () => {
      const selectionArray = selections || []
      const next = selected
        ? selectionArray.filter((selection) => selection !== value)
        : [...selectionArray, value]
      save(next)
    },
    [save, selections, selected, value]
  )

  return (
    <ButtonBase
      onClick={handleClick}
      className={clsx(selected && 'selected')}
    >
      <img src={imgSrc} alt="" />
      <img src={checkSVG} alt="" className="check" />
      <p>{title}</p>
    </ButtonBase>
  )
}

const integrations = [
  ['Pg', 'Payment Gateway', paymentGatewaySVG],
  ['moota', 'Moota', mootaSVG],
  ['Shipment', 'Shipment', shipmentSVG],
  ['Google Analytic', 'Google Analytics', googleAnalyticsSVG],
  ['Facebook Pixel', 'Facebook Pixel', facebookPixelSVG],
  ['Woo-wa', 'Woowa', woowaSVG],
]

function Integration(props) {
  const { control, name, error } = props

  return (
    <Controller
      control={control}
      name={name}
      render={
        ({ value, onChange: handleClick }) => (
          <div css={CSS}>
            <h3>
              {
                error
                  ? (
                    <Fragment>
                      Sistem Integrasi <span className="error-message"> * {error}</span>
                    </Fragment>
                  )
                  : 'Sistem Integrasi'
              }
            </h3>
            {
            integrations.map(([key, title, imgSrc]) => (
              <SelectionButton
                key={key}
                imgSrc={imgSrc}
                title={title}
                selected={value && Array.isArray(value) && value.includes(key)}
                onClick={handleClick}
                value={key}
                selections={value}
              />
            ))
          }
          </div>
        )
      }
    />
  )
}

export default Integration
