/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import React, { useContext, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { TableContext } from './TableProvider'

const useStyles = makeStyles((theme) => ({
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    backgroundColor: '#F6F9FB',
    '&.MuiInputBase-root': {
      '&.MuiOutlinedInput-root': {
        height: 45,
        outline: 'none !important',
        border: 'none',
      },
    },
  },
}))

const FilterDialog = ({ open, onClose }) => {
  const classes = useStyles()
  const d = new Date()
  const { queryPush, queryRemove, params } = useContext(TableContext)
  const [selectedName, setSelectedName] = useState(params?.store_name || '')
  const [filter, setFilter] = useState({})

  const onChange = (e) => {
    const { value } = e.target
    setSelectedName(value)
    setFilter({ ...filter, name: value })
  }

  const clearAll = () => {
    setFilter({})
    setSelectedName('')
    const remove = ['name']
    queryRemove(remove)
    onClose()
  }

  const onFilter = () => {
    queryPush(filter)
    onClose()
  }

  const clearFilter = (name) => {
    setFilter({ ...filter, [name]: '' })
    queryRemove([name])
  }

  const renderClearIcon = (name) => (
    filter[name] !== '' && (
      <Tooltip title="clear">
        <IconButton onClick={() => clearFilter(name)}>
          <CloseIcon />
        </IconButton>
      </Tooltip>
    )
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>Filter Data</DialogTitle>
      <DialogContent>
        <div className={classes.fieldWrapper}>
          <Typography color="textSecondary">Nama Bank</Typography>
          <TextField
            id="user"
            type="text"
            value={selectedName}
            name="name"
            onChange={onChange}
            className={classes.textField}
            InputProps={{
              endAdornment: renderClearIcon('name'),
              classes: { root: classes.textField },
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={onFilter}
        >
          Terapkan
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={clearAll}
        >
          Reset
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
        >
          Batal
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default FilterDialog
