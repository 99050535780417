import { adminApiBaseURL } from '@lib/env'
import Axios from 'axios'
import qs from 'qs'
import { setBearerToken, handleUnauthorized, normalizeResponse } from './interceptor'

const api = Axios.create({
  baseURL: adminApiBaseURL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
})
api.defaults.headers.common.Accept = 'application/json'
api.interceptors.request.use(setBearerToken)
api.interceptors.response.use(normalizeResponse, handleUnauthorized)

export const indexSyncPages = (params) => api.get('/sync_pages', { params })

export const createSyncPages = (name, parent_id, order, path, icon) => api.post('/sync_pages', {
  sync_page: {
    name, // string,
    parent_id, // 0,
    order, // 0,
    path, // string,
    icon, // string,
  },
})

export const showSyncPages = (id) => api.get(`/sync_pages/${id}`)
export const deleteSyncPages = (id) => api.delete(`/sync_pages/${id}`)

export const updateSyncPages = (id, name, parent_id, order, path, icon) => api.put(`/sync_pages/${id}`, {
  sync_page: {
    name, // string,
    parent_id, // 0,
    order, // 0,
    path, // string,
    icon, // string,
  },
})
