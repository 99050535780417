/** @jsxImportSource @emotion/react */

import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import { confirmSave } from '@lib/redux/slice/ui'
import Tidak from '@components/button/Tidak'
import Ya from '@components/button/Ya'
import { css } from '@emotion/react'

const TYPE = 'confirm.dialog'

const CSS = css`
  .MuiDialog-paper {
    width: 389px;
    height: 229px;
    background-color: #ffffff;
    border-radius: 5px;
    margin: 0;
  }
  .confirm-dialog-container {

  }
  .confirm-dialog-title {
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 35px;
    margin-bottom: 0;
  }
  .confirm-dialog-message {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 7px;
    max-width: 307px;
    margin-left: auto;
    margin-right: auto;
  }
  .confirm-dialog-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    button:last-of-type {
      margin-left: 20px;
    }
  }
`

function ConfirmDialog(props) {
  const {
    open, type, data, onConfirm: handleConfirm,
    className,
  } = props

  return (
    <Dialog variant="confirm" open={open && type === TYPE} css={CSS} className={className}>
      {
        open
        && type === TYPE
        && (
          <div className="confirm-dialog-container">
            <h3 className="confirm-dialog-title">{ data?.title }</h3>
            <p className="confirm-dialog-message">{ data?.message }</p>
            <div className="confirm-dialog-buttons">
              <Tidak onClick={() => handleConfirm(false)}>
                { data?.options?.no || 'Tidak' }
              </Tidak>
              <Ya onClick={() => handleConfirm(true)}>
                { data?.options?.yes || 'Ya' }
              </Ya>
            </div>
          </div>
        )
      }
    </Dialog>
  )
}

const mapState = ({ ui: { dialog: { open, type, data } } }) => ({
  open, type, data,
})

const mapDispatch = {
  onConfirm: (data) => confirmSave(data),
}
const connector = connect(mapState, mapDispatch)
const ConnectedConfirmDialog = connector(ConfirmDialog)
export default ConnectedConfirmDialog
