/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import MuiButton from '@material-ui/core/Button'

const CSS = css`
  &.MuiButton-root {
    border-radius: 5px;
    width: 96px;
    height: 45px;
    position: relative;
    border: 2px solid #556FB5;
  }
  &.MuiButton-textPrimary {
    background-color: #FFFFFF;
  }
  .MuiButton-label {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #556FB5;
    position: absolute;
  }
`

function Button(props) {
  const {
    children,
    onClick: handleClick,
  } = props
  return (
    <MuiButton
      disableElevation
      onClick={handleClick}
      css={CSS}
      variant="outlined"
    >
      { children }
    </MuiButton>
  )
}

export default Button
