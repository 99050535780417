/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button, FormHelperText, InputLabel, makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: 200,
    width: '100%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      height: 160,
      marginBottom: theme.spacing(12),
    },
  },
  avatar: {
    width: 120,
    height: 120,
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      width: 140,
      height: 140,
    },
  },
  errorMessage: {
    color: 'red',
  },
  button: {
    // borderRadius: 100,
    width: '100%',
    '&.MuiIconButton-root': {
      backgroundColor: '#ECF2F5',
    },
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(6),
      width: '100%',
    },
  },
  imgWrapper: {
    display: 'flex',
    width: '100%',
    height: 150,
    alignItems: 'center',
    justifyContent: 'center',
    border: 'solid 4px #ECF2F5',
    borderStyle: 'dashed',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}))

const FieldImageUploader = ({
  placeholder, src, register, errors, name, label, getValues, setValue, ...props
}) => {
  const classes = useStyles()
  const [imgPath, setImagePath] = useState(src)

  const onChange = (e) => {
    const { files } = e.target
    setValue(name, files, { shouldValidate: true })
    if (files[0]) {
      setImagePath(URL.createObjectURL(files[0]))
    }
  }

  return (
    <div className={classes.root}>
      { label && <InputLabel>{label}</InputLabel>}
      <div className={classes.imgWrapper}>
        {
        imgPath
          ? <img alt={imgPath} src={imgPath} className={classes.avatar} />
          : placeholder || 'Please Select Image'
      }
      </div>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        component="label"
        fullWidth
      >
        <input
          name={name}
          style={{ display: 'none' }}
          type="file"
          ref={register}
          onChange={onChange}
          {...props}
        />
        Upload
      </Button>
      <FormHelperText className={classes.errorMessage}>
        { !!errors[name] && `${label} ${errors[name].message}`}
      </FormHelperText>
    </div>
  )
}

FieldImageUploader.propTypes = {
  errors: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
}

export default FieldImageUploader
