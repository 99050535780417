/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import axios from 'axios'
import {
  setBearerToken,
  handleUnauthorized,
  normalizeResponse,
} from '@lib/axios/interceptor'
import { authApiBaseURL } from '@lib/env'
import DateRangePicker from '@material-ui/lab/DateRangePicker'
import AdapterDateFns from '@material-ui/lab/AdapterDateFns'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import { TableContext } from './TableProvider'
import calendarSVG from './svg/calendar.svg'
import downSVG from './svg/down.svg'

const useStyles = makeStyles((theme) => ({
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    backgroundColor: '#F6F9FB',
    '&.MuiInputBase-root': {
      '&.MuiOutlinedInput-root': {
        height: 45,
        outline: 'none !important',
        border: 'none',
      },
    },
  },
}))

const RangeDate = ({
  value, open, handleChange, setOpen,
}) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DateRangePicker
      label="Advanced keyboard"
      value={value}
      open={open}
      onChange={handleChange}
      renderInput={(startProps, endProps) => (
        <React.Fragment>
          <div
            onClick={() => {
              setOpen(true)
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              background: '#F6F9FB',
              borderRadius: '4px',
              border: '1px solid #999',
              padding: '5px 12px',
              height: '45px',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '47.5%',
              }}
            >
              <img src={calendarSVG} alt="calendar" />
              <input
                style={{
                  fontFamily: 'Nunito Sans',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: 'inherit',
                  lineHeight: '19px',
                  background: 'unset',
                  outline: 'none',
                  border: 'unset',
                  color: '#7B7B7B',
                  width: '100%',
                  marginLeft: 16,
                }}
                ref={startProps.inputRef}
                {...startProps.inputProps}
              />
            </div>
            <Box sx={{ mx: 2 }}> - </Box>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '47.5%',
              }}
            >
              <input
                style={{
                  fontFamily: 'Nunito Sans',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: 'inherit',
                  lineHeight: '19px',
                  background: 'unset',
                  outline: 'none',
                  border: 'unset',
                  color: '#7B7B7B',
                  marginRight: 7,
                  width: '100%',
                }}
                ref={endProps.inputRef}
                {...endProps.inputProps}
              />
              <img src={downSVG} alt="calendar" />
            </div>
          </div>
        </React.Fragment>
      )}
    />
  </LocalizationProvider>
)

const FilterDialog = ({ open, onClose }) => {
  const classes = useStyles()
  const { queryPush, queryRemove, params } = useContext(TableContext)
  const [packages, setPackages] = useState([])
  const [selectedPackage, setSelectedPackage] = useState(
    params?.package_id || ''
  )
  const [selectedStatus, setSelectedStatus] = useState(params?.status || '')
  const [invoiceStatus, setInvoiceStatus] = useState(params?.invoice_status || '')

  const [selectedDistributor, setSelectedDistributor] = useState(
    params?.status || ''
  )
  const [selectedPeriode, setSelectedPeriode] = useState(params?.periode || '')
  const [selectedStore, setSelectedStore] = useState(params?.store_name || '')
  const [selectedName, setSelectedName] = useState(params?.user_or_email || '')
  const [filter, setFilter] = useState({})
  const [minReseller, setMinReseller] = useState(0)
  const [affiliateName, setAffiliateName] = useState('')
  const [maxReseller, setMaxReseller] = useState(minReseller)
  const [registerDate, setRegisterDate] = useState([
    new Date()?.toISOString(),
    new Date()?.toISOString(),
  ])
  const [invoiceDate, setInvoiceDate] = useState([
    new Date()?.toISOString(),
    new Date()?.toISOString(),
  ])
  const [openRegisterDate, setOpenRegisterDate] = useState(false)
  const [openInvoiceDate, setOpenInvoiceDate] = useState(false)

  const onChangeUser = (e) => {
    const { value } = e.target
    setSelectedName(value)
    setFilter({ ...filter, email_or_name: value })
  }
  const onChangeText = (e) => {
    const { value, name } = e.target
    setFilter({ ...filter, [name]: value })
  }

  const onChangeStore = (e) => {
    const { value } = e.target
    setSelectedStore(value)
    setFilter({ ...filter, store_name: value })
  }

  const onChangePackage = (e) => {
    const { value } = e.target
    setSelectedPackage(value)
    setFilter({ ...filter, package_id: value })
  }
  const onChangePeriode = (e) => {
    const { value } = e.target
    setSelectedPeriode(value)
    setFilter({ ...filter, periode: value })
  }
  const onChangeStatus = (e) => {
    const { value } = e.target
    setSelectedStatus(value)
    setFilter({ ...filter, status: value })
  }
  const onChangeInvoiceStatus = (e) => {
    const { value } = e.target
    setInvoiceStatus(value)
    setFilter({ ...filter, invoice_status: value })
  }
  const onChangeIsDistributor = (e) => {
    const { value } = e.target
    setSelectedDistributor(value)
    setFilter({ ...filter, is_distributor: value })
  }
  const clearAll = () => {
    setFilter({})
    setSelectedName('')
    setSelectedStore('')
    setSelectedPackage('')
    setSelectedStatus('')
    setSelectedPeriode('')
    setRegisterDate([new Date()?.toISOString(),
      new Date()?.toISOString()])
    setInvoiceDate([new Date()?.toISOString(),
      new Date()?.toISOString()])
    setMinReseller(0)
    setMaxReseller(0)
    const remove = [
      'start_date',
      'email_or_name',
      'store_name',
      'end_date',
      'status',
      'package_id',
      'periode',
      'start_invoice_date ',
      'end_invoice_date ',
      'min_reseller_total',
      'max_reseller_total',
      'affiliate_name',
    ]
    queryRemove(remove)
    onClose()
  }

  const onFilter = () => {
    queryPush(filter)
    onClose()
  }

  const clearFilter = (name) => {
    setFilter({ ...filter, [name]: '' })
    queryRemove([name])
  }
  const renderClearIcon = (name) => filter[name] !== '' && (
  <Tooltip title="clear">
    <IconButton onClick={() => clearFilter(name)}>
      <CloseIcon />
    </IconButton>
  </Tooltip>
  )

  const api = axios.create({
    baseURL: authApiBaseURL,
  })

  api.defaults.headers.common.Accept = 'application/json'
  api.interceptors.request.use(setBearerToken)
  api.interceptors.response.use(normalizeResponse, handleUnauthorized)

  const getPackages = async (e) => {
    try {
      const {
        status,
        data: { data },
      } = await api.get('/api/v1/packages', {
        params: { name: e?.target?.value || '', per_page: 100 },
      })
      if (status === 'ok') {
        setPackages(data)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  useEffect(() => {
    getPackages()
  }, [])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Filter Data</DialogTitle>
      <DialogContent>
        <div className={classes.fieldWrapper}>
          <Typography color="textSecondary">Tanggal Mendaftar</Typography>
          <RangeDate
            handleChange={(newValue) => {
              setRegisterDate([
                newValue?.[0]?.toISOString(),
                newValue?.[1]?.toISOString(),
              ])
              setFilter({
                ...filter,
                start_date: newValue?.[0]?.toISOString(),
                end_date: newValue?.[1]?.toISOString(),
              })
              setOpenRegisterDate(false)
            }}
            value={registerDate}
            open={openRegisterDate}
            setOpen={setOpenRegisterDate}
          />
        </div>
        <div className={classes.fieldWrapper}>
          <Typography color="textSecondary">Tanggal Invoice</Typography>
          <RangeDate
            handleChange={(newValue) => {
              setInvoiceDate([
                newValue?.[0]?.toISOString(),
                newValue?.[1]?.toISOString(),
              ])
              setFilter({
                ...filter,
                start_invoice_date: newValue?.[0]?.toISOString(),
                end_invoice_date: newValue?.[1]?.toISOString(),
              })
              setOpenInvoiceDate(false)
            }}
            value={invoiceDate}
            open={openInvoiceDate}
            setOpen={setOpenInvoiceDate}
          />
        </div>
        <div className={classes.fieldWrapper}>
          <Typography color="textSecondary">Nama / Email User</Typography>
          <TextField
            id="email_or_name"
            type="text"
            value={selectedName}
            name="email_or_name"
            onChange={onChangeUser}
            className={classes.textField}
            InputProps={{
              endAdornment: renderClearIcon('email_or_name'),
              classes: { root: classes.textField },
            }}
          />
        </div>
        <div className={classes.fieldWrapper}>
          <Typography color="textSecondary">Nama Toko</Typography>
          <TextField
            id="store"
            type="text"
            value={selectedStore}
            name="store_name"
            onChange={onChangeStore}
            className={classes.textField}
            InputProps={{
              endAdornment: renderClearIcon('store_name'),
              classes: { root: classes.textField },
            }}
          />
        </div>
        <div className={classes.fieldWrapper}>
          <Typography color="textSecondary">Paket</Typography>
          <Select
            name="package_id"
            variant="outlined"
            value={selectedPackage}
            className={classes.textField}
            onChange={onChangePackage}
            placeholder="Pilih Paket"
          >
            {packages.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={classes.fieldWrapper}>
          <Typography color="textSecondary">Tipe Periode</Typography>
          <Select
            name="periode"
            variant="outlined"
            value={selectedPeriode}
            className={classes.textField}
            onChange={onChangePeriode}
            placeholder="Pilih Tipe Periode"
          >
            <MenuItem value="monthly">Bulanan</MenuItem>
            <MenuItem value="annual">Tahunan</MenuItem>
          </Select>
        </div>
        <div className={classes.fieldWrapper}>
          <Typography color="textSecondary">Status</Typography>
          <Select
            name="status"
            variant="outlined"
            className={classes.textField}
            value={selectedStatus}
            onChange={onChangeStatus}
            placeholder="Pilih Paket"
          >
            <MenuItem value="" />
            <MenuItem value="true">Aktif</MenuItem>
            <MenuItem value="false">Suspended</MenuItem>
          </Select>
        </div>
        <div className={classes.fieldWrapper}>
          <Typography color="textSecondary">Status Invoice</Typography>
          <Select
            name="invoice_status"
            variant="outlined"
            className={classes.textField}
            value={invoiceStatus}
            onChange={onChangeInvoiceStatus}
            placeholder="Pilih Status"
          >
            <MenuItem value="" />
            <MenuItem value="paid">Paid</MenuItem>
            <MenuItem value="open">Open</MenuItem>
            <MenuItem value="expired">Expired</MenuItem>
          </Select>
        </div>
        <div className={classes.fieldWrapper}>
          <Typography color="textSecondary">
            Apakah Memiliki Distributor?
          </Typography>
          <Select
            name="is_distributor"
            variant="outlined"
            className={classes.textField}
            value={selectedDistributor}
            onChange={onChangeIsDistributor}
            placeholder="Apakah Memiliki Distributor?"
          >
            <MenuItem value="" />
            <MenuItem value="true">Ya</MenuItem>
            <MenuItem value="false">Tidak</MenuItem>
          </Select>
        </div>
        <div className={classes.fieldWrapper}>
          <Typography color="textSecondary">
            Jumlah Reseller (minimal)
          </Typography>
          <TextField
            id="min_reseller_total"
            type="number"
            value={minReseller}
            name="min_reseller_total"
            onChange={(e) => {
              setMinReseller(e?.target?.value)
              onChangeText(e)
            }}
            className={classes.textField}
            InputProps={{
              endAdornment: renderClearIcon('min_reseller_total'),
              classes: { root: classes.textField },
            }}
          />
        </div>
        <div className={classes.fieldWrapper}>
          <Typography color="textSecondary">
            Jumlah Reseller (maximal)
          </Typography>
          <TextField
            id="max_reseller_total"
            type="number"
            value={maxReseller}
            name="max_reseller_total"
            onChange={(e) => {
              setMaxReseller(e?.target?.value)
              onChangeText(e)
            }}
            className={classes.textField}
            InputProps={{
              endAdornment: renderClearIcon('max_reseller_total'),
              classes: { root: classes.textField },
              min: minReseller,
            }}
          />
        </div>
        <div className={classes.fieldWrapper}>
          <Typography color="textSecondary">
            Nama Affiliate
          </Typography>
          <TextField
            id="affiliate_name"
            value={affiliateName}
            name="affiliate_name"
            onChange={(e) => {
              setAffiliateName(e?.target?.value)
              onChangeText(e)
            }}
            className={classes.textField}
            InputProps={{
              endAdornment: renderClearIcon('affiliate_name'),
              classes: { root: classes.textField },
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onFilter}>
          Terapkan
        </Button>
        <Button variant="contained" color="secondary" onClick={clearAll}>
          Reset
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Batal
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default FilterDialog
