/** @jsxImportSource @emotion/react */

import { Fragment } from 'react'
import Dropdown from '@components/filter/Dropdown'

function Status(props) {
  const {
    onSelected: handleSelected,
    className,
    defaultValue,
  } = props
  return (
    <Dropdown
      onSelected={handleSelected}
      items={[
        [<Fragment>&nbsp;</Fragment>, ''],
        ['Aktif', 'true'],
        ['Tidak Aktif', 'false'],
      ]}
      defaultValue={defaultValue || ''}
      className={className}
    />
  )
}

export default Status
