/* eslint-disable radix */
import {
  Button,
  CircularProgress,
  Pagination,
  PaginationItem,
  Paper, Table, TableContainer, Typography,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import qs from 'qs'
import useStyles from './tableBilling-jss'
import TableHeader from './TableHeader'
import { TableProvider } from './TableProvider'
import TableToolbar from './TableToolbar'
import useTableState from './useTableState'
import TableBody from './TableBody'
import Form from './Form'
import DeleteDialog from './DeleteDialog'

const useQuery = () => new URLSearchParams(useLocation().search)

const TableBilling = () => {
  const classes = useStyles()
  const state = useTableState()
  const location = useLocation()
  const query = useQuery()

  useEffect(() => {
    state.getIndex()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const onClickPage = (item) => {
    const queryString = qs.parse(location.search, { ignoreQueryPrefix: true })
    const newQs = { ...queryString, page: item.page }
    const page1 = qs.stringify({ ...queryString, page: 1 }, { encode: false })

    return `${location.pathname}?${item.page === 1 ? page1 : qs.stringify(newQs, { encode: false })}`
  }

  return (
    <TableProvider value={state}>
      <TableContainer component={Paper} className={classes.root}>
        <div className={classes.header}>
          <Typography
            variant="h1"
            className={classes.txHeader}
            gutterBottom
          >
            Manajemen Bank
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 8 }}
            onClick={() => state.setOpenForm(true)}
          >
            Tambah Bank
          </Button>
        </div>
        <TableToolbar />
        <Table
          padding="checkbox"
          style={{ marginLeft: 20, marginRight: 20 }}
        >
          <TableHeader />
          <TableBody />
        </Table>
        {state.loading && <CircularProgress style={{ alignSelf: 'center', margin: 8 }} size={24} />}
        {
          !state.loading && state?.data?.length > 0 && (
            <div className={classes.tFooter}>
              <Pagination
                style={{ alignSelf: 'center', marginTop: 8 }}
                count={state.total}
                color="primary"
                page={query.get('page') === null ? 1 : parseInt(query.get('page'))}
                renderItem={(item) => (
                  <PaginationItem
                    component={Link}
                    to={() => onClickPage(item)}
                    {...item}
                  />
                )}
              />
            </div>
          )
        }
        {
            state?.data?.length === 0 && !state.loading && (
              <Typography style={{ alignSelf: 'center', margin: 8 }}>Data tidak ditemukan</Typography>
            )
          }

      </TableContainer>
      <Form
        open={state.openForm}
        onClose={() => {
          state.setOpenForm(false)
          state.setbank()
          state.setEdit(false)
        }}
      />
      <DeleteDialog
        open={state.deleteOpen}
        onClose={() => {
          state.setDeleteOpen(false)
          state.setId()
        }}
      />
    </TableProvider>
  )
}

export default TableBilling
