/* eslint-disable camelcase */

import { createCourierManagement } from '@lib/axios/courier'
import { useMutation, useQueryClient } from 'react-query'
import useSuccess from '@hooks/useSuccess'
import useCloseDialog from '@hooks/useCloseDialog'

const useCreateAdmin = () => {
  const showSuccess = useSuccess('Sukses', 'Sukses Menambah Kurir')
  const closeDialog = useCloseDialog()
  const queryClient = useQueryClient()
  const mutation = useMutation(
    (mdata) => createCourierManagement(
      mdata.name,
      mdata.code,
      mdata.icon
    ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('courier')
      },
    }
  )

  return (data) => mutation.mutate(data, {
    onError: () => closeDialog(),
    onSuccess: () => {
      showSuccess()
    },
  })
}

export default useCreateAdmin
