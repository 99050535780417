import React from 'react'
import Form from '@components/dialog/Form'
import { useSelector } from 'react-redux'
import packageSVG from '@assets/button/package.svg'

const PackageDetail = () => {
  const data = useSelector(({ ui: { dialog } }) => dialog.data)

  return (
    <div>
      {data?.packages && (
        <Form
          title="Daftar Paket"
          type="package-detail"
          dividers
          noSubmit
          cancelButtonText="Batal"
        >
          <div style={{ display: 'grid', gridTemplateColumns: '300px 300px', width: 800 }}>
            {data?.packages?.map((val) => (
              <div key={val.id + val.name} style={{ minHeight: 50, padding: '10px 0' }}>
                <img src={packageSVG} alt="" />
                <span style={{ marginLeft: 10 }}>{val.name}</span>
              </div>
            ))}
          </div>
        </Form>
      )}
    </div>
  )
}

export default PackageDetail
