/** @jsxImportSource @emotion/react */

import useIndex10 from '@lib/query/user/useIndex10'
import { useMemo } from 'react'
import DataTable from '@components/DataTable'
import Card from '@components/card/Base'
import { css } from '@emotion/react'
import { Link } from 'react-router-dom'
import chevronBlueSVG from './svg/chevron-blue.svg'

const cardCSS = css`
  margin: 0;
  background: #FFFFFF !important;
  border-radius: 10px !important;
  border: unset !important;
  & > .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  & > .card-header .left {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .card-header a {
    margin: 0;
    padding: 0;
    display: inline-block;
    background: none;
    border: none;
    margin-right: 33px;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins';
    color: #3630C9;
    position: relative;
    padding-right: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  & .card-header a img {
    position: absolute;
    width: 16px;
    height: 20px;
    right: 0;
  }
`

const tableCSS = css`
  td[role="cell"]:last-of-type {
    text-align: left;
    justify-content: flex-start;
  }
`

export default function Table10User() {
  const {
    tableData,
    tableColumns,
    hiddenColumns,
  } = useIndex10()
  return (
    <Card css={cardCSS}>
      <div className="card-header">
        <h3>User Baru</h3>
        <div className="left">
          <Link
            to="/s/user"
          >
            Lihat Semua
            <img alt="" src={chevronBlueSVG} />
          </Link>
        </div>
      </div>
      <DataTable
        css={tableCSS}
        data={tableData}
        columns={useMemo(() => tableColumns, [tableColumns])}
        hooks={(action) => action.visibleColumns.push((columns) => [
          ...columns,
          {
            id: 'activation_status',
            Header: () => (<div>Status</div>),
            Cell: ({ row: { original: data } }) => (
              <div style={{ color: data.activation_status === 'active' ? '#4BAEA0' : 'E66A57' }}>
                {data.activation_status === 'active' ? 'Aktif' : 'Tidak Aktif'}
              </div>
            ),
          },
        ])}
        hiddenColumns={hiddenColumns}
        pagination={false}
      />
    </Card>
  )
}
