/* eslint-disable camelcase */

import { useMutation, useQueryClient } from 'react-query'
import useSuccess from '@hooks/useSuccess'
import useCloseDialog from '@hooks/useCloseDialog'
import { syncAllXenPlatforms } from '@lib/axios/xenplatform'

const useSyncAll = () => {
  const showSuccess = useSuccess('Sukses', 'Sukses Menyinkronkan akun XenPlatform')
  const closeDialog = useCloseDialog()
  const queryClient = useQueryClient()
  const mutation = useMutation(
    () => syncAllXenPlatforms(
    ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('saas-xenplatforms')
      },
    }
  )

  return (data) => mutation.mutate(data, {
    onError: () => closeDialog(),
    onSuccess: () => {
      showSuccess()
    },
  })
}

export default useSyncAll
