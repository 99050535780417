/** @jsxImportSource @emotion/react */

import { ResponsiveBar } from '@nivo/bar'
import React from 'react'
import { css } from '@emotion/react'

const CSS = css`
  width: 100%;
  background: #ffffff;
  border-radius: 12.4092px;
  position: relative;
  padding: 32px;
  height: 700px;
  h3 {
    width: 100%;
    margin: 0px;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0em;
  }
  .subs-titles {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    div {
      background: #367de4;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      margin-right: 10px;
    }
    p {
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14.8911px;
      line-height: 20px;
      color: #7b7b7b;
    }
  }
`

const MyResponsiveBar = ({
  data, keys, indexBy, fills, fn,
}) => (
  <ResponsiveBar
    data={data}
    keys={keys}
    curve="natural"
    defs={[
      {
        id: 'lines',
        type: 'patternLines',
        background: 'inherit',
        color: '#367DE4',
        rotation: -45,
        lineWidth: 2,
        spacing: 10,
      },
    ]}
    margin={{
      top: 16,
      right: 0,
      bottom: 120,
      left: 100,
    }}
    fill={fills}
    indexBy={indexBy}
    padding={0.8}
    groupMode="grouped"
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    colors="#367DE4"
    borderColor={{
      from: 'color',
      modifiers: [['darker', 1.6]],
    }}
    axisTop={null}
    axisRight={null}
    borderRadius={8}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Tanggal',
      legendPosition: 'middle',
      legendOffset: 32,
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: '',
      format: fn,
      legendPosition: 'middle',
      legendOffset: -40,
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{
      from: 'color',
      modifiers: [['darker', 1.6]],
    }}
  />
)

export default ({
  data, title, subTitle, fn, keys, indexBy, fills,
}) => (
  <div css={CSS}>
    <h3>{title}</h3>
    {subTitle && (
      <div className="subs-titles">
        <div />
        <p>{subTitle}</p>
      </div>
    )}
    <MyResponsiveBar
      data={data}
      subTitle={subTitle}
      indexBy={indexBy}
      fn={fn}
      keys={keys}
      fills={fills}
    />
  </div>
)
