/** @jsxImportSource @emotion/react */

import { useForm } from 'react-hook-form'
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { loginSaasAdmins } from '@lib/axios/auth'
import * as yup from 'yup'
import { useSnackbar } from 'notistack'
import { Helmet } from 'react-helmet'
import { Fragment } from 'react'

import Logo from './common/Logo'
import Email from './common/Email'
import Password from './common/Password'
import Button from './common/Button'
import ForgetPasswordLink from './common/ForgetPasswordLink'
import Background from './common/Background'
import Box from './common/Box'

const logoCSS = css`
  position: absolute;
  width: 136.71px;
  height: 110px;
  left: 182px;
  top: 44px;
  @media (max-width: 500px) {
    left: calc(50vw - 80px);
  }
`

const emailCSS = css`
  position: absolute;
  left: 59px;
  top: 235px;
  @media (max-width: 500px) {
    width: calc(95vw - 60px);
    left: 30px;
  }
`

const passwordCSS = css`
  left: 59px;
  top: 301px;
  position: absolute;
  @media (max-width: 500px) {
    width: calc(95vw - 60px);
    left: 30px;
  }
`

const loginButtonCSS = css`
  position: absolute;
  width: 380px;
  height: 45px;
  left: 60px;
  top: 371px;
  @media (max-width: 500px) {
    width: calc(95vw - 60px);
    left: 30px;
  }
`

const versionCSS = css`
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 12px
`

const loginButtonText = 'Masuk'

const schema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
})

function Login() {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  })
  const { enqueueSnackbar } = useSnackbar()

  // eslint-disable-next-line no-console
  const onSubmit = (data) => loginSaasAdmins(data).catch((e) => {
    if (e?.response?.status === 400) {
      const type = e?.response?.data?.error
      if (type === 'User Not Found' || type === 'Password Not Valid') {
        enqueueSnackbar('Email Atau Password Salah', { variant: 'warning' })
      } else if (type === 'User was Locked') {
        enqueueSnackbar('Akun terkunci', { variant: 'warning' })
      } else {
        enqueueSnackbar('Akun Anda di suspend', { variant: 'warning' })
      }
    }
  })

  return (
    <Fragment>
      <Helmet>
        <title>
          Squids Admin
        </title>
      </Helmet>
      <Background>
        <Box width={506} height={516}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Logo css={logoCSS} />
            <Email
              name="username"
              ref={register}
              error={errors?.username?.message}
              placeholder="email"
              css={emailCSS}
            />
            <Password
              name="password"
              ref={register}
              error={errors?.password?.message}
              placeholder="password"
              toggle
              css={passwordCSS}
            />
            <Button css={loginButtonCSS}>
              { loginButtonText }
            </Button>
            <ForgetPasswordLink />
          </form>
          <div css={versionCSS}>v1.0.0-rc1</div>
        </Box>
      </Background>
    </Fragment>
  )
}

export default Login
