/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

const boxCSS = (width, height) => css`
  width: ${width}px;
  height: ${height}px;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: relative;
  max-width: 95%;
`

function Box(props) {
  const { children, width, height } = props
  return (
    <div css={() => boxCSS(width, height)}>
      {
        children
      }
    </div>
  )
}

export default Box
