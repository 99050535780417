import React, { useContext } from 'react'
import { TableBody as MUITableBody, TableCell, TableRow } from '@material-ui/core'
import * as dayjs from 'dayjs'
import 'dayjs/locale/id'
import { TableContext } from './TableProvider'
import TableAction from './TableAction'

const TableBody = () => {
  const { data, column } = useContext(TableContext)
  return (
    <MUITableBody>
      {
      data.map((row) => (
        <TableRow key={row.id}>
          {
            column.map((col) => (
              <TableCell variant="body" key={col.name} style={{ whiteSpace: col.nowrap ? 'nowrap' : 'normal' }}>
                {col.is_date ? dayjs(row[col.name]).locale('id').format('DD MMMM YYYY, HH:mm') : row[col.name]}
              </TableCell>
            ))
          }
          <TableCell>
            <TableAction rowData={row} />
          </TableCell>
        </TableRow>
      ))
    }
    </MUITableBody>
  )
}
export default TableBody
