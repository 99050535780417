import { suspendSaasAdmins } from '@lib/axios/admin'
import { useMutation, useQueryClient } from 'react-query'

const useAdminMutationSuspend = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (id) => suspendSaasAdmins(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('saas-admin')
      },
    }
  )
}

export default useAdminMutationSuspend
