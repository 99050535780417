/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import axios from 'axios'
import { setBearerToken, handleUnauthorized, normalizeResponse } from '@lib/axios/interceptor'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { adminApiBaseURL, authApiBaseURL } from '@lib/env'
import { TableContext } from './TableProvider'

const useStyles = makeStyles((theme) => ({
  fieldWrapper: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    backgroundColor: '#F6F9FB',
    '&.MuiInputBase-root': {
      '&.MuiOutlinedInput-root': {
        height: 45,
        outline: 'none !important',
        border: 'none',
      },
    },
  },
}))

const FilterDialog = ({ open, onClose }) => {
  const classes = useStyles()
  const { queryPush, queryRemove, queryString } = useContext(TableContext)
  const [selectedPackage, setSelectedPackage] = useState([])
  const [packages, setPackages] = useState([])
  const [filter, setFilter] = useState({
    start_date: queryString?.date_eq || new Date(),
    user_name: queryString?.user_cont || '',
    store_name: queryString?.store_cont || '',
    end_date: queryString?.end_date || new Date(),
    status: queryString?.status || '',
    package_id: queryString?.package_id || '',
  })

  const onChange = (e) => {
    const { name, value } = e.target
    setFilter({ ...filter, [name]: value })
  }

  const onPackageCheck = (e) => {
    const { value, checked } = e.target
    const newPackage = selectedPackage

    if (checked) {
      newPackage.push(value)
    } else {
      newPackage.splice(newPackage.indexOf(value), 1)
    }

    setSelectedPackage(newPackage)
  }

  const clearAll = () => {
    setFilter({
      start_date: '',
      end_date: '',
      user_name: '',
      store_name: '',
      package_id: '',
    })

    const remove = ['start_date', 'user_name', 'store_name', 'end_date', 'status', 'package_id']
    queryRemove(remove)
    onClose()
  }

  const onFilter = () => {
    queryPush(filter)
    onClose()
  }

  const clearFilter = (name) => {
    setFilter({ ...filter, [name]: '' })
  }

  const onChangePackage = (e, value, reason) => {
    if (reason === 'select-option') {
      setFilter({ ...filter, package_id: value.id })
    }
  }

  const onChangeStatus = (e, value, reason) => {
    if (reason === 'select-option') {
      setFilter({ ...filter, status: value })
    }
  }

  const handleDateChange = (fieldName, date) => {
    setFilter({ ...filter, [fieldName]: date })
  }

  const renderClearIcon = (name) => (
    filter[name] !== '' && (
      <Tooltip title="clear">
        <IconButton onClick={() => clearFilter(name)}>
          <CloseIcon />
        </IconButton>
      </Tooltip>
    )
  )

  const api = axios.create({
    baseURL: authApiBaseURL,
  })

  api.defaults.headers.common.Accept = 'application/json'
  api.interceptors.request.use(setBearerToken)
  api.interceptors.response.use(normalizeResponse, handleUnauthorized)

  const getPackages = async (e) => {
    try {
      const { status, data: { data } } = await api.get('/api/v1/packages', { params: { name: e?.target?.value || '', per_page: 100 } })
      if (status === 'ok') {
        setPackages(data)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  useEffect(() => {
    getPackages()
  }, [])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>Filter Data</DialogTitle>
      <DialogContent>
        asasdasd
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={onFilter}
        >
          Terapkan
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={clearAll}
        >
          Reset
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
        >
          Batal
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default FilterDialog
