import { Link } from '@material-ui/core'
import { Fragment } from 'react'
import dayjs from 'dayjs'
import MenuAction from './MenuAction'

const action = (hooks) => {
  hooks.visibleColumns.push((columns) => [
    ...columns,
    {
      id: 'domain',
      Header: () => (<div>Domain</div>),
      Cell: ({ row: { original: data } }) => (
        <Link href={`https://${data.domain}`} target="_blank">{data.domain}</Link>
      ),
    },
    {
      id: 'status_display',
      Header: () => (<div>Status</div>),
      Cell: ({ row: { original: data } }) => (
        <span style={{ color: !data.status ? '#E82A63' : '#4BAEA0' }}>
          { data.status ? 'Aktif' : 'Suspended' }
        </span>
      ),
    },
    {
      id: 'registration_date',
      Header: () => (<div>Tanggal Registrasi</div>),
      Cell: ({ row: { original: data } }) => (
        <span>
          {data?.registration_date ? dayjs(new Date(data.registration_date)).format('DD MMM YYYY') : '-'}
        </span>
      ),
    },
    {
      id: 'type_package_period',
      Header: () => (<div>Periode Paket</div>),
      Cell: ({ row: { original: data } }) => (
        <span key={data}>
          {data?.type_package_period === 'monthly' ? 'Bulanan' : 'Tahunan'}
        </span>
      ),
    },
    {
      id: 'total_reseller',
      Header: () => (<div>Jumlah Reseller</div>),
      Cell: ({ row: { original: data } }) => (
        <span>
          {(data?.total_reseller === null && '-') || data?.total_reseller}
        </span>
      ),
    },
    {
      id: 'is_distributor',
      Header: () => (<div>Memiliki Distributor</div>),
      Cell: ({ row: { original: data } }) => (
        <span style={{ color: data?.is_distributor !== null && (!data.is_distributor ? '#E82A63' : '#4BAEA0') }}>
          {(data?.is_distributor === null && '-') || (data?.is_distributor !== null && (data.is_distributor ? 'Ya' : 'Tidak'))}
        </span>
      ),
    },
    {
      id: 'last_invoice',
      Header: () => (<div>Invoice Terakhir</div>),
      Cell: ({ row: { original: data } }) => (
        <span>
          {data?.invoice_created_date ? dayjs(new Date(data.invoice_created_date)).format('DD MMM YYYY') : '-'}
        </span>
      ),
    },
    {
      id: 'invoice_status',
      Header: () => (<div>Status invoice</div>),
      Cell: ({ row: { original: data } }) => (
        <span>
          {data?.invoice_status ?? '-'}
        </span>
      ),
    },
    {
      id: 'aksi',
      Header: () => (<div>Aksi</div>),
      Cell: ({ row: { original: data } }) => (
        <Fragment>
          <MenuAction data={data} />
        </Fragment>
      ),
    },
  ])
}

export default action
