import { useQuery } from 'react-query'
import { useMemo } from 'react'
import { listStores } from '@lib/axios/store'

function useListStores() {
  const { status, data } = useQuery(
    ['saas-admin-list-stores'],
    () => listStores({
      page: 1,
      per_page: 100,
    }),
    {
      retry: false,
      staleTime: Infinity,
    }
  )
  return useMemo(() => (status === 'success' && Array.isArray(data?.data?.data) ? data?.data?.data : []), [status, data])
}

export default useListStores
