/** @jsxImportSource @emotion/react */

import { forwardRef } from 'react'
import { css } from '@emotion/react'
// import mailSVG from '@assets/auth/mail.svg'
import checkSVG from '@assets/auth/check.svg'
import EmailIcon from '@material-ui/icons/EmailOutlined'
import Input from './Input'

const emailCSS = css`
  position: absolute;
  left: 79px;
  top: 305px;
`

const before = () => <EmailIcon style={{ color: '#6a6e75' }} /> // <img src={mailSVG} alt="" />
const after = () => <img src={checkSVG} alt="" />

const Email = forwardRef((props, ref) => {
  const {
    name, error, placeholder, touched,
  } = props
  return (
    <Input
      name={name}
      error={error}
      ref={ref}
      before={before}
      after={touched && !error ? after : ''}
      css={emailCSS}
      placeholder={placeholder}
    />
  )
})

export default Email
