import { updateStore } from '@lib/axios/store'
import { useMutation, useQueryClient } from 'react-query'

const useStoreMutationSuspend = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, status }) => updateStore(id, status),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('saas-store')
      },
    }
  )
}

export default useStoreMutationSuspend
