/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/** @jsxImportSource @emotion/react */

import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import { closeDialog, openDialog } from '@lib/redux/slice/ui'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { css } from '@emotion/react'
import DialogContent from '@material-ui/core/DialogContent'

import {
  Table, TableBody, TableCell, TableHead, TableRow, Switch,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { listPackages } from '@lib/axios/store'
import closeSVG from './svg/close.svg'

const TYPE = 'form.dialog.show'

const closeCSS = css`
  &.MuiIconButton-root {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`

const CSS = css`
  .MuiDialog-paper {
    width: 829px;
    height: 514px;
    max-width: 829px;
  }
`

function PackageDialog(props) {
  const {
    open,
    type,
    data,
    onClose: handleClose,
    update,
  } = props

  const [pacakges, setPackages] = useState([])
  const [currentPackage, setCurrentPackage] = useState(data?.package_id)
  useEffect(() => {
    setCurrentPackage(data?.package_id)
  }, [data])
  const onChange = async (id, package_id) => {
    await update({ id, status: true, package_id })
    setCurrentPackage(package_id)
  }

  const getPackages = async () => {
    try {
      const { status, data: { data } } = await listPackages()

      if (status === 'ok') {
        setPackages(data)
      } else {
        setPackages([])
      }
    } catch (error) {
      setPackages([])
    }
  }

  useEffect(() => {
    getPackages()
  }, [])

  return (
    <Dialog css={CSS} open={open && type === TYPE}>
      <DialogTitle>
        Ubah Paket
        <IconButton
          size="small"
          onClick={handleClose}
          css={closeCSS}
        >
          <img src={closeSVG} alt="" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Table padding="checkbox">
          <TableHead>
            <TableRow>
              <TableCell>
                Nama Paket
              </TableCell>
              <TableCell>
                Paket Aktif
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
               pacakges.map((item) => (
                 <TableRow key={item.id} hover>
                   <TableCell>
                     {item.name}
                   </TableCell>
                   <TableCell>
                     <Switch
                       onChange={() => onChange(data.id, item.id)}
                       name="package_id"
                       value={item.id}
                       checked={item.id === currentPackage}
                     />
                   </TableCell>
                 </TableRow>
               ))
            }
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  )
}

const mapState = ({ ui: { dialog: { type, open, data } } }) => ({
  type,
  open,
  data,
})

const mapDispatch = {
  onClose: () => closeDialog(),
  onSuccess: () => openDialog({
    type: 'success.dialog',
    data: {
      title: 'Sukses',
      message: 'Sukses Mengubah Data',
    },
  }),
}

const connector = connect(mapState, mapDispatch)
const ConnectedPackcageDialog = connector(PackageDialog)
export default ConnectedPackcageDialog
