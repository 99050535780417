/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import Pagination from '@material-ui/core/Pagination'
import useQueryString from '@hooks/useQueryString'

const circleCSS = css`
  top: 0px;
  border: none;
  border-radius: 35px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 170%;

  /* identical to box height, or 24px */
  text-align: center;

  color: #556FB5;

  &:focus {
    outline: none;
  }
  &.active {
    background: #556FB5;
    color: #FFFFFF;
  }

  &:not(:first-of-type) {
    margin-left: 7px;
  }

  &.MuiPaginationItem-page.Mui-selected.Mui-disabled {
    box-shadow: none;
    background-color: #556FB5;
    color: #FFFFFF;
    opacity: 1;
  }
  &.MuiPaginationItem-page {
    box-shadow: none;
    background-color: #FFFFFF;
    color: #556FB5;
    opacity: 1;
  }

  &.MuiPaginationItem-outlined {
    border: 1px solid rgba(85, 111, 181, .75);
  }
  &.MuiPaginationItem-root {
    padding: 0;
  }
`

function Circle(props) {
  const {
    number,
    active,
    onClick: handleClick,
  } = props
  return (

    <Button
      onClick={handleClick}
      className={clsx(active && 'active')}
      css={circleCSS}
    >
      { number }
    </Button>
  )
}

const paginationCSS = css`
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  & > div {
    display: flex;
  }
  & .MuiPaginationItem-icon {
    fill: #556FB5;
  }
`

function PaginationWrapper(props) {
  const { pagination } = props
  const {
    page, setPage, setOrderBy, setSortBy,
  } = useQueryString()
  const handleChange = (event, value) => {
    setOrderBy(null)
    setSortBy(null)
    setPage(value)
  }
  return (
    <div css={paginationCSS}>
      <Pagination
        count={pagination.pageCount}
        page={parseInt(page)}
        onChange={handleChange}
        color="primary"
      />
    </div>
  )
}

export default PaginationWrapper
