import {
  Button,
  makeStyles, MenuItem, Select, Toolbar, Typography,
} from '@material-ui/core'
import React, { useContext, useState } from 'react'
import TuneIcon from '@material-ui/icons/Tune'
import qs from 'qs'
import { useHistory, useLocation } from 'react-router'
import FilterDialog from './FilterDialog'
import { TableContext } from './TableProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  showPageWrapper: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  selectPage: {
    marginLeft: theme.spacing(1),
    width: 100,
    height: 40,
    fontSize: 12,
    backgroundColor: '#F6F9FB',
    outline: 'none',
    '&.MuiSelect-outlined.MuiSelect-outlined': {
      border: 'none',
      outline: 'none',
    },
  },
}))

const TableToolbar = () => {
  const classes = useStyles()
  const pages = [10, 20, 50]
  const { perPage, setPerPage } = useContext(TableContext)
  const [openFilter, setOpenFilter] = useState(false)
  const location = useLocation()
  const history = useHistory()

  const onPageSelect = (e) => {
    const { value } = e.target
    const queryString = qs.parse(location.search, { ignoreQueryPrefix: true })
    const newQs = { ...queryString, per_page: value, page: 1 }
    setPerPage(value)

    history.push(`${location.pathname}?${qs.stringify(newQs)}`)
  }

  return (
    <Toolbar className={classes.root}>
      <div className={classes.showPageWrapper}>
        <Typography>Tampilkan : </Typography>
        <Select
          name="perPage"
          variant="outlined"
          value={perPage}
          onChange={onPageSelect}
          className={classes.selectPage}
        >
          {
            pages.map((page) => (
              <MenuItem
                value={page}
                key={page}
              >
                {page}
              </MenuItem>
            ))
          }
        </Select>
      </div>
      <Button
        variant="outlined"
        startIcon={<TuneIcon />}
        onClick={() => setOpenFilter(true)}
      >
        Filter
      </Button>
      <FilterDialog
        open={openFilter}
        onClose={() => setOpenFilter(false)}
      />
    </Toolbar>
  )
}
export default TableToolbar
