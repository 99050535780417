/** @jsxImportSource @emotion/react */
/* eslint-disable no-unused-vars */

import { Fragment, forwardRef, useMemo } from 'react'
import { Controller } from 'react-hook-form'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import { css } from '@emotion/react'
import Field from './Field'

const formControlCSS = css`
  &.MuiFormControl-root {
    padding-top: 18px;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #676767;
  }
  & .MuiFormLabel-root.Mui-focused {
    color: #676767;
  }
  & .MuiFormHelperText-root {
    margin: 5px 5px 0 5px;
    font-size: 10px;
    line-height: 1;
  }
  & .MuiInputAdornment-positionStart {
    margin-right: 0;
  }
  & .MuiInputAdornment-positionEnd {
    margin-left: 0;
    height: 45px;
    max-height: 45px;
    flex-direction: column;
    justify-content: center;
    button {
      padding: 0px;
      margin: 0 5px;
    }
  }
`

const Renderer = forwardRef((props, ref) => {
  const {
    value: inputValue, onChange: handleChange, step, error, label,
  } = props
  const value = useMemo(() => `${inputValue}`.replace(/\..*/, ''), [inputValue])
  return (
    <Fragment>
      <InputLabel disableAnimation shrink>
        {`${label} ` || (
        <Fragment>
          &nbsp;
        </Fragment>
        )}
        {
          error && (
            <span className="error-message">
              * { error }
            </span>
          )
        }
      </InputLabel>
      <Field
        onChange={handleChange}
        value={value}
        step={step}
        inputRef={ref}
      />
      &nbsp;
      {/* <FormHelperText error={!!error}>
        {error || (
        <Fragment>
        &nbsp;
        </Fragment>
        )}
      </FormHelperText> */}
    </Fragment>
  )
})

function TextNumber(props) {
  const {
    label, name, className, error, control, step,
  } = props

  return (
    <FormControl css={formControlCSS} className={className}>
      <Controller
        control={control}
        name={name}
        render={({ onChange: handleChange, value, ref }) => (
          <Renderer
            onChange={handleChange}
            value={value}
            ref={ref}
            label={label}
            error={error}
            step={step}
          />
        )}
      />
    </FormControl>
  )
}

export default TextNumber
