import { IconButton, makeStyles } from '@material-ui/core'
import React, { useContext } from 'react'
// eslint-disable-next-line no-unused-vars
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined'
import EditIcon from '@material-ui/icons/EditOutlined'
import DeleteIcon from '@material-ui/icons/Delete'
import { TableContext } from './TableProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  icon: {
    color: theme.palette.primary.main,
  },
}))

const TableAction = ({ rowData }) => {
  const classes = useStyles()
  const {
    getShow, setOpenForm, setEdit, setId, setDeleteOpen,
  } = useContext(TableContext)

  const onClickEdit = () => {
    setEdit(true)
    setOpenForm(true)
    getShow(rowData.id)
  }

  const onClickDelete = () => {
    setDeleteOpen(true)
    setId(rowData.id)
  }

  return (
    <div className={classes.root}>
      <IconButton onClick={onClickEdit}>
        <EditIcon className={classes.icon} />
      </IconButton>
      <IconButton onClick={onClickDelete}>
        <DeleteIcon className={classes.icon} />
      </IconButton>
    </div>
  )
}

export default TableAction
