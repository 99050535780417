import { Controller } from 'react-hook-form'
import Field from './Field'

function TextArea(props) {
  const { control, name } = props
  return (
    <Controller
      control={control}
      name={name}
      render={
        ({
          onChange: handleChange,
          value,
        }) => (
          <Field
            onChange={handleChange}
            value={value}
          />
        )
      }
    />
  )
}

export default TextArea
