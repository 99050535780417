/* eslint-disable max-len */
/** @jsxImportSource @emotion/react */

import IconButton from '@material-ui/core/IconButton'
import MenuList from '@material-ui/core/MenuList'
import Grow from '@material-ui/core/Grow'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import {
  Fragment, useEffect, useRef, useState,
} from 'react'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { css } from '@emotion/react'
import {
  ListItem, Typography, Divider, Badge,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { enqueue } from '@lib/redux/slice/notification'
import { store } from '@lib/redux'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import NotificationsIcon from '@material-ui/icons/Notifications'
import dayjs from 'dayjs'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import { useHistory } from 'react-router-dom'
import { securedRoutePath } from '@lib/constant'
import { indexApi, updateApi } from '@lib/axios/notification'

const listCSS = css`
  &.MuiList-root {
    max-width: 341px;
  }
  &.MuiList-padding {
    padding: 0;
    min-width: 341px;
    min-height: 100px;
  }
  & .MuiListItem-gutters {
    padding-right: 13px;
    padding-left: 13px;
  }
`

const testCSS = css`
  & > .MuiPaper-root {
    max-height: 200px;
    overflow-y: auto;
  }
`

function AccountButton() {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const history = useHistory()
  const notif = useSelector(({ notification }) => (notification.notifications))

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const getData = async () => {
    const res = await indexApi()
    const { status } = res
    if (status === 'ok') {
      store.dispatch(enqueue(res?.data?.data ?? []))
    }
  }

  const handleNotif = async (id, act, read) => {
    if (!read) {
      const res = await updateApi(id)
      const { status } = res
      if (status === 'ok') getData()
    }
    setOpen(false)
    history.push(act ?? '#')
  }

  useEffect(() => {
    getData()
  }, [])

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <Fragment>
      <IconButton
        className="notification-button"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge variant="dot" invisible={!notif.length > 0} color="primary">
          <NotificationsNoneIcon />
        </Badge>
      </IconButton>
      <Popper css={testCSS} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList css={listCSS} autoFocusItem={open} onKeyDown={handleListKeyDown}>
                  {notif.length > 0 && notif.map((val) => (
                    <Fragment>
                      <ListItem
                        alignItems="flex-start"
                        {...(!val?.has_read) && { style: { backgroundColor: '#e5e5e5', cursor: 'pointer' } }}
                        onClick={() => handleNotif(val?.id, val?.action, val?.has_read)}
                      >
                        <ListItemIcon>
                          {(val?.has_read) ? (<NotificationsIcon />) : (<NotificationsActiveIcon />)}
                        </ListItemIcon>
                        <ListItemText
                          primary={val?.event_type ?? 'Notifikasi'}
                          secondary={(
                            <Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {val?.message}
                              </Typography>
                              <Typography
                                sx={{ marginTop: 1 }}
                                component="div"
                                variant="caption"
                                color="text.primary"
                              >
                                {dayjs(val?.created_at).locale('id').format('DD MMMM YYYY - hh:mm')}
                              </Typography>
                            </Fragment>
                      )}
                        />
                      </ListItem>
                      <Divider />
                    </Fragment>
                  ))}
                  {notif.length > 0 && (
                    <ListItem button onClick={() => history.push(`${securedRoutePath}/notifikasi`)}>
                      <ListItemText style={{ textAlign: 'center' }} primary="LIHAT SEMUA" />
                    </ListItem>
                  )}
                  {notif.length === 0 && (
                    <ListItem disabled>
                      <ListItemText style={{ textAlign: 'center' }} primary="TIDAK ADA NOTIFIKASI" />
                    </ListItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  )
}

export default AccountButton
