/** @jsxImportSource @emotion/react */

import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { Controller } from 'react-hook-form'
import InputLabel from '@material-ui/core/InputLabel'
import { css } from '@emotion/react'
import { Fragment, useCallback } from 'react'
import Field from './Field'
import useSelect from './useSelect'

const CSS = css`
  &.MuiFormControl-root {
    padding-top: 18px;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #676767;
  }
  & .MuiFormHelperText-root {
    margin: 5px 5px 0 5px;
    font-size: 11px;
    line-height: 11px;
  }
`

function Select(props) {
  const {
    control, name, label, className, selects, query, map, error,
  } = props
  const { items, handleOpen } = useSelect(query, selects, map)

  const setDefaultValue = useCallback((value) => (items ? value : ''), [items])

  return (
    <FormControl className={className} css={CSS}>
      <InputLabel disableAnimation shrink>{ label }</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ onChange: handleChange, value }) => (
          <Fragment>
            <Field
              onChange={handleChange}
              onOpen={handleOpen}
              value={setDefaultValue(value) || value}
              items={items || query}
            />
            <FormHelperText error={!!error}>
              {
                error || (
                <Fragment>
                  &nbsp;
                </Fragment>
                )
              }
            </FormHelperText>
          </Fragment>
        )}
      />
    </FormControl>
  )
}

export default Select
