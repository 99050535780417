/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import IconButton from '@material-ui/core/IconButton'
import { connect } from 'react-redux'
import { toggleSidebarOpen } from '@lib/redux/slice/ui'
import { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import logoWhiteSVG from '@assets/sidebar/logoWhite.svg'
import chevronLeftWhite from '@assets/sidebar/chevronLeftWhite.svg'

const toggleSidebarButtonCSS = css`
  &.MuiIconButton-root {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`

const mapDispatch = { toggleSidebarOpen }
const mapState = ({ ui }) => ({ sidebarOpen: ui.sidebarOpen })
const connector = connect(mapState, mapDispatch)
const ToggleSidebarButton = connector(({ sidebarOpen, toggleSidebarOpen: handleToggle }) => (
  <Fragment>
    <Helmet>
      <body className={sidebarOpen ? 'sidebar-open' : 'sidebar-closed'} />
    </Helmet>
    <IconButton css={toggleSidebarButtonCSS} size="small" onClick={() => handleToggle()}>
      <img src={chevronLeftWhite} alt="" />
    </IconButton>
  </Fragment>
))

const logoCSS = css`
  width: 278px;
  height: 145px;
  background: #4BAEA0;
  background: linear-gradient(180deg, #4BAEA0 49.66%, #4E65A5 172.41%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

function Logo() {
  return (
    <div css={logoCSS}>
      <ToggleSidebarButton />
      <img src={logoWhiteSVG} alt="" />
    </div>
  )
}

export default Logo
