export default {
  name: '',
  id: '',
  thumbnail: {
    url: null,
    medium: {
      url: null,
    },
    small: {
      url: null,
    },
    thumbnail: {
      url: null,
    },
  },
  image: {
    url: null,
    medium: {
      url: null,
    },
    small: {
      url: null,
    },
    thumbnail: {
      url: null,
    },
  },
  status: '0',
}
