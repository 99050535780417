/** @jsxImportSource @emotion/react */

import Form from '@components/dialog/Form'
import { useForm } from 'react-hook-form'
import { css } from '@emotion/react'
import Text from '@components/field/Text'
import { useEffect } from 'react'

import useValue from './useValue'

const CSS = css`
  min-width: 829px;
  border-radius: 5px;
  .MuiDialogContent-root {
    padding: 32px;
  }
  .field-wrap {
    width: 381px;
    display: flex;
    flex-direction: column;
  }
  .field-wrap > div {
    flex: 1;
    margin-bottom: 10px;
  }
  .field-wrap .bank {
    display: flex;
    justify-content: space-between;
  }
  .field-wrap .bank > div {
    max-width: 48%;
  }
`

export default function Dialog(props) {
  const {
    type, title, onSave: save, defaultValues,
  } = props

  const { value, open } = useValue(defaultValues)

  const {
    handleSubmit, register, reset,
  } = useForm({
    defaultValues,
  })

  useEffect(() => {
    if (open) {
      reset(
        value,
        {
          isDirty: false,
          touched: false,
          dirtyFields: false,
          errors: false,
        }
      )
    }
  }, [value, reset, open])

  return (
    <Form
      title={title}
      type={type}
      onSubmit={handleSubmit((data) => save(data))}
      css={CSS}
    >
      <div className="field-wrap">
        {
          value?.id && (
            <input name="id" type="hidden" value={value.id} ref={register} />
          )
        }
        <Text label="Nama" name="name" ref={register} />
        <div className="bank">
          <Text label="Bank" name="bank_name" ref={register} />
          <Text label="No. Rekening" name="account_number" ref={register} />
        </div>
        <Text label="Personal Token" name="personal_token" ref={register} />
      </div>
    </Form>
  )
}
