/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import IconButton from '@material-ui/core/IconButton'
import showButtonSVG from './svg/showButton.svg'

const CSS = css`
  width: 30px;
  height: 30px;
`

function showButton({ onClick: handleClick }) {
  return (
    <IconButton css={CSS} size="small" onClick={handleClick}>
      <img src={showButtonSVG} alt="" />
    </IconButton>
  )
}

export default showButton
