/* eslint-disable no-shadow */
import {
  Button,
  makeStyles, MenuItem, Select, Toolbar, Typography,
  Menu, Divider,
} from '@material-ui/core'
import React, { useContext, useState } from 'react'
import TuneIcon from '@material-ui/icons/Tune'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'
import { useHistory, useLocation } from 'react-router'
import qs from 'qs'
import { TableContext } from './TableProvider'
import FilterDialog from './FilterDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  showPageWrapper: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  selectPage: {
    marginLeft: theme.spacing(1),
    width: 100,
    height: 40,
    fontSize: 12,
    backgroundColor: '#F6F9FB',
    outline: 'none',
    '&.MuiSelect-outlined.MuiSelect-outlined': {
      border: 'none',
      outline: 'none',
    },
  },
}))

const TableToolbar = () => {
  const classes = useStyles()
  const pages = [10, 20, 50]
  const { perPage, setPerPage } = useContext(TableContext)
  const [openFilter, setOpenFilter] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const openExport = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onPageSelect = (e) => {
    const { value } = e.target
    const queryString = qs.parse(location.search, { ignoreQueryPrefix: true })
    const newQs = { ...queryString, per_page: value, page: 1 }
    setPerPage(value)

    history.push(`${location.pathname}?${qs.stringify(newQs)}`)
  }

  const exportFile = (param) => {
    const queryString = qs.parse(location.search, { ignoreQueryPrefix: true })
    if (param === 'csv') {
      delete queryString.xlsx
      queryString.csv = true
    }
    if (param === 'xlsx') {
      delete queryString.csv
      queryString.xlsx = true
    }
    history.push(`${location.pathname}?${qs.stringify(queryString)}`)
  }

  return (
    <Toolbar className={classes.root}>
      <div className={classes.showPageWrapper}>
        <Typography>Tampilkan : </Typography>
        <Select
          name="perPage"
          variant="outlined"
          value={perPage}
          onChange={onPageSelect}
          className={classes.selectPage}
        >
          {
            pages.map((page) => (
              <MenuItem
                value={page}
                key={page}
              >
                {page}
              </MenuItem>
            ))
          }
        </Select>
      </div>
      <Button
        variant="outlined"
        startIcon={<TuneIcon />}
        onClick={() => setOpenFilter(true)}
      >
        Filter
      </Button>
      <Button
        variant="outlined"
        startIcon={<InsertDriveFileOutlinedIcon />}
        onClick={handleClick}
        sx={{
          ml: 1,
        }}
      >
        Export
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openExport}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        style={{
          width: 150,
        }}
      >
        <MenuItem
          onClick={() => exportFile('csv')}
          style={{
            width: 150,
          }}
        >CSV
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => exportFile('xlsx')}>Excel</MenuItem>
      </Menu>
      <FilterDialog
        open={openFilter}
        onClose={() => setOpenFilter(false)}
      />
    </Toolbar>
  )
}
export default TableToolbar
