/* eslint-disable no-console */
import { Fragment } from 'react'
import Ubah from '@components/button/Ubah'
import dayjs from 'dayjs'
import { useHistory, useLocation } from 'react-router-dom'
import packageSVG from '@assets/button/package.svg'
import Button from '@material-ui/core/Button'
import { openDialog } from '@lib/redux/slice/ui'
import { useDispatch } from 'react-redux'
import Hapus from './Hapus'

const Cell = ({ row: { original: data } }) => {
  const history = useHistory()

  return (
    <div style={{ textAlign: 'center' }}>
      <Ubah
        data={data}
        type="edit"
        onClick={() => history.push('/s/voucher/new')}
      />
      <Hapus id={data.id} />
    </div>
  )
}

const useOpenDialog = (type, data) => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  return () => dispatch(openDialog({ type: `${pathname}.${type}`, data }))
}

const Action = (hooks) => {
  hooks.visibleColumns.push((columns) => [
    ...columns,
    {
      id: 'remaining_usage',
      Header: () => <div>Sisa Pemakaian</div>,
      Cell: ({ row: { original: data } }) => (data.remaining_usage ?? 'Unlimited'),
    },
    {
      id: 'remaining_budget',
      Header: () => <div>Sisa Budget</div>,
      Cell: ({ row: { original: data } }) => (data.remaining_budget ?? 'Unlimited'),
    },
    {
      id: 'updated_at',
      Header: () => <div>Tgl. Update</div>,
      Cell: ({ row: { original: data } }) => (
        <span>
          {dayjs(new Date(data.updated_at)).format('DD MMM YYYY')}
        </span>
      ),
    },
    {
      id: 'period',
      Header: () => <div>Periode</div>,
      Cell: ({ row: { original: data } }) => (
        <span>
          {data.end_date && data.start_date && (
            <Fragment>
              {`${dayjs(new Date(data.start_date)).format(
                'DD MMM YYYY'
              )} - ${dayjs(new Date(data.end_date)).format('DD MMM YYYY')}`}
            </Fragment>
          )}
        </span>
      ),
    },
    {
      id: 'packages',
      Header: () => <div>Daftar Paket</div>,
      Cell: ({ row: { original: data } }) => {
        const handleClick = useOpenDialog('package-detail', data)
        return (
          <div>
            <Button css={CSS} startIcon={<img src={packageSVG} alt="" />} size="small" onClick={handleClick}>
              <span style={{ color: '#556FB5', marginLeft: 10, fontWeight: 'bold' }}>Lihat Paket</span>
            </Button>

          </div>
        )
      },
    },
    {
      id: 'action',
      Header: () => <div>Aksi</div>,
      Cell,
    },
  ])
}

export default Action
