/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
import { Fragment } from 'react'
import dayjs from 'dayjs'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline'
import {
  IconButton,
} from '@material-ui/core'
import { indexApi, updateApi } from '@lib/axios/notification'
import { enqueue } from '@lib/redux/slice/notification'
import { store } from '@lib/redux'
import { useQueryClient } from 'react-query'

const Cell = ({ row: { original: data } }) => {
  const queryClient = useQueryClient()

  const getData = async () => {
    const res = await indexApi()
    const { status } = res
    if (status === 'ok') {
      store.dispatch(enqueue(res?.data?.data ?? []))
    }
  }
  const handleNotif = async (id) => {
    const res = await updateApi(id)
    const { status } = res
    if (status === 'ok') getData()
    queryClient.invalidateQueries('saas-notif')
  }

  return (
    <Fragment>
      {!data?.has_read && (
      <IconButton style={{ color: '#556FB5' }} onClick={() => handleNotif(data?.id)}>
        <DoneOutlineIcon />
      </IconButton>
      )}
    </Fragment>
  )
}

export default (hooks) => {
  hooks.visibleColumns.push((columns) => {
    return [
      {
        id: 'created_at_display',
        Header: () => (<div>Tanggal</div>),
        Cell: ({ row: { original: data } }) => (
          <div {...!data?.has_read && { style: { color: '#556FB5', fontWeight: 'bold' } }}>
            {`${dayjs(data?.created_at).format('DD-MM-YYYY HH:mm')} WIB`}
          </div>
        ),
      },
      {
        id: 'message_display',
        Header: () => (<div>Notifikasi</div>),
        Cell: ({ row: { original: data } }) => (<div {...!data?.has_read && { style: { color: '#556FB5', fontWeight: 'bold' } }}>{data?.message}</div>),
      },
      {
        id: 'action_display',
        Header: () => (<div>Aksi</div>),
        Cell,
      },
    ]
  })
}
