/** @jsxImportSource @emotion/react */

import { Fragment, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import PageTitle from '@components/PageTitle'
import Layout from '@layout/Main'
import useIndex from '@lib/query/notification/useIndex'
import DataTable from '@components/DataTable'
import Card from '@components/card/Base'
import { css } from '@emotion/react'
import hooks from './hooks'

const titleCSS = css`
  display: flex;
`

function TicketManagement() {
  const {
    tableData,
    tableColumns,
    hiddenColumns,
    tablePagination,
  } = useIndex()

  return (
    <Fragment>
      <Helmet>
        <title>
          SQUIDS :: Notifikasi
        </title>
      </Helmet>
      <PageTitle>
        Lihat Semua Notifikasi
      </PageTitle>
      <Layout>
        <Card>
          <div css={titleCSS}>
            <h3>Notifikasi</h3>
          </div>
          <DataTable
            css={CSS}
            data={tableData}
            columns={useMemo(() => tableColumns, [tableColumns])}
            hooks={hooks}
            hiddenColumns={hiddenColumns}
            pagination={tablePagination}
            onChange={() => null}
          />
        </Card>
      </Layout>
    </Fragment>
  )
}

export default TicketManagement
