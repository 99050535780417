/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */

import useQueryString from '@hooks/useQueryString'
import { css } from '@emotion/react'
import Dropdown from './Dropdown'

const pageSizeCSS = css`
  flex: 1;
  display: flex;
  & > span {
    margin: 0;
    line-height: 30px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-right: 5px;
  }
`

function PageSize() {
  const { perPage, setPerPage } = useQueryString()

  function handleChangePageSize(size) {
    //
    if (size === 10) setPerPage(undefined)
    else setPerPage(size)
  }

  return (
    <div css={pageSizeCSS}>
      <span>Tampilkan: </span>
      <Dropdown
        items={[
          ['10 data', 10],
          ['20 data', 20],
          ['50 data', 50],
          ['100 data', 100],
        ]}
        onSelected={(handleChangePageSize)}
        defaultValue={perPage}
      />
    </div>
  )
}

export default PageSize
